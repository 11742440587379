import { IconOptionType } from 'lib/countries-with-icons';
import { components } from 'react-select';

export function CountryOption({
  data,
  ...rest
}: React.ComponentProps<typeof components.Option>) {
  const { label, icon } = data as IconOptionType;

  return (
    <components.Option
      className="d-flex align-items-center"
      {...{ data, ...rest }}
    >
      <img alt="" src={icon} />
      <div className="ml-2 mr-2">{label}</div>
    </components.Option>
  );
}
