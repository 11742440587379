import { ReactComponent as LockSvg } from 'assets/lock.svg';
import { ReactComponent as ProfileSvg } from 'assets/profile.svg';
import axios, { AxiosResponse } from 'axios';
import Button from 'components/button/Button';
import IconInput from 'components/icon-input/IconInput';
import { useAuth } from 'components/routes/AuthRouter';
import { t } from 'i18next';
import { ChangeEvent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ChangeUsernameDto } from '../../../../shared/dtos/settings.dto';
import {
  ChangePasswordWithConfirmationDto,
  ExistsCheckType,
} from '../../../../shared/types';

function ChangePasswordPart() {
  const auth = useAuth();
  const history = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const {
    control,
    handleSubmit,
    reset,
    getValues,
  } = useForm<ChangePasswordWithConfirmationDto>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });
  const [passwordIdentical, setPasswordIdentical] = useState<
    boolean | undefined
  >();
  const checkConfirmation = ({
    newPassword,
    confirmNewPassword,
  }: ChangePasswordWithConfirmationDto) => {
    if (
      newPassword!.trim().length === 0 ||
      confirmNewPassword!.trim().length === 0
    ) {
      setPasswordIdentical(undefined);
    } else {
      setPasswordIdentical(newPassword!.trim() === confirmNewPassword!.trim());
    }
  };
  const submit = async ({
    currentPassword,
    newPassword,
  }: ChangePasswordWithConfirmationDto) => {
    if (passwordIdentical !== true) return;
    try {
      await axios.patch('users/password/update', {
        currentPassword,
        newPassword,
      });

      reset();
      toast.success(t('notifications.password-changed-successfully'));
      auth.logout();
      history.push('/');
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        toast.error(t(error.response?.data?.message || error.message));
      }
    }
  };

  const bottomBtns = (
    <Button
      btnRole="primary"
      type="submit"
      disabled={passwordIdentical !== true}
      form="change-password-form"
      style={{ width: '300px' }}
    >
      {t('settings.save-new-password')}
    </Button>
  );

  return (
    <>
      <h4 className="mt-4">{t('settings.headers.change-password')}</h4>
      <hr />
      <form id="change-password-form" onSubmit={handleSubmit(submit)}>
        <div>
          <div
            className="form-group d-flex justify-content-start align-items-center"
            style={{ minWidth: '200px' }}
          >
            <label
              className="m-0 app-emphasis required"
              htmlFor="current-password"
            >
              {t('forms-labels.current-password')}
            </label>
          </div>
          <div className="form-group ltr" style={{ width: '300px' }}>
            <Controller
              control={control}
              name="currentPassword"
              render={({ field: { value, onChange } }) => (
                <IconInput
                  type="password"
                  icon={<LockSvg />}
                  direction="ltr"
                  iconPosition="start"
                  value={value}
                  required
                  id="current-password"
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap">
          <div className={`${isRtl ? 'ml-5' : 'mr-5'}`}>
            <div
              className="form-group d-flex justify-content-start align-items-center"
              style={{ minWidth: '200px' }}
            >
              <label
                className="m-0 app-emphasis required"
                htmlFor="new-password"
              >
                {t('forms-labels.new-password')}
              </label>
            </div>
            <div className="form-group ltr" style={{ width: '300px' }}>
              <Controller
                control={control}
                name="newPassword"
                render={({ field: { value, onChange } }) => (
                  <IconInput
                    type="password"
                    icon={<LockSvg />}
                    direction="ltr"
                    iconPosition="start"
                    value={value}
                    required
                    id="new-password"
                    onChange={(event) => {
                      onChange(event);
                      checkConfirmation(getValues());
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div>
            <div
              className="form-group d-flex justify-content-start align-items-center"
              style={{ minWidth: '200px' }}
            >
              <label
                className="m-0 app-emphasis required"
                htmlFor="confirm-new-password"
              >
                {t('forms-labels.confirm-new-password')}
              </label>
            </div>
            <div className="d-flex">
              <div
                className={`form-group ltr ${isRtl ? 'ml-4' : 'mr-4'}`}
                style={{ width: '300px' }}
              >
                <Controller
                  control={control}
                  name="confirmNewPassword"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      type="password"
                      icon={<LockSvg />}
                      direction="ltr"
                      iconPosition="start"
                      value={value}
                      required
                      id="confirm-new-password"
                      onChange={(event) => {
                        onChange(event);
                        checkConfirmation(getValues());
                      }}
                    />
                  )}
                />
              </div>
              <div
                className={`mb-3 d-flex align-items-center ${
                  passwordIdentical === true
                    ? 'primary-txt'
                    : passwordIdentical === false
                    ? 'danger-txt'
                    : ''
                }`}
                title={
                  passwordIdentical === true
                    ? t('settings.password-identical')
                    : passwordIdentical === false
                    ? t('settings.password-unidentical')
                    : undefined
                }
              >
                {passwordIdentical === true ? (
                  <span className="icon" data-icon="&#x43;" />
                ) : passwordIdentical === false ? (
                  <span className="icon" data-icon="&#x42;" />
                ) : (
                  <div style={{ minWidth: '23px' }} />
                )}
              </div>
            </div>
          </div>
        </div>
        {bottomBtns}
      </form>
    </>
  );
}

function ChangeUsernamePart() {
  const {
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const history = useHistory();
  const auth = useAuth();
  const { control, handleSubmit } = useForm<ChangeUsernameDto>({
    defaultValues: { newUsername: auth.user?.username || '' },
  });
  const [usernameState, setUsernameState] = useState<
    'exists' | 'new' | 'empty' | ''
  >('');

  const submit = async ({ newUsername }: ChangeUsernameDto) => {
    try {
      await axios.patch('users/username/update', { newUsername });

      toast.success(t('notifications.username-changed-successfully'));
      auth.logout();
      history.push('/');
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        toast.error(t(error.response?.data?.message || error.message));
      }
    }
  };

  const bottomBtns = (
    <Button
      btnRole="primary"
      type="submit"
      disabled={usernameState !== 'new'}
      form="change-username-form"
      style={{ width: '300px' }}
    >
      {t('settings.save-new-username')}
    </Button>
  );

  return (
    <>
      <h4 className="mt-4">{t('settings.headers.change-username')}</h4>
      <hr />
      <form id="change-username-form" onSubmit={handleSubmit(submit)}>
        <div>
          <div
            className="form-group d-flex justify-content-start align-items-center"
            style={{ minWidth: '200px' }}
          >
            <label className="m-0 app-emphasis required" htmlFor="username">
              {t('forms-labels.username')}
            </label>
          </div>
          <div className="d-flex">
            <div
              className={`form-group ltr ${isRtl ? 'ml-4' : 'mr-4'}`}
              style={{ width: '300px' }}
            >
              <Controller
                control={control}
                name="newUsername"
                render={({ field: { value, onChange } }) => (
                  <IconInput
                    icon={<ProfileSvg />}
                    direction="ltr"
                    iconPosition="start"
                    value={value}
                    required
                    id="username"
                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                      const value = event.target.value.trim().toLowerCase();

                      onChange(event);

                      if (value?.trim().length === 0) {
                        setUsernameState('empty');
                      } else if (auth.user?.username === value) {
                        setUsernameState('');
                      } else {
                        try {
                          const {
                            data: { exists },
                          } = await axios.get<
                            never,
                            AxiosResponse<ExistsCheckType>
                          >(`users/check/${value}`);

                          setUsernameState(exists ? 'exists' : 'new');
                        } catch (error) {
                          setUsernameState('');
                        }
                      }
                    }}
                  />
                )}
              />
            </div>
            <div
              className={`mb-3 d-flex align-items-center ${
                usernameState === 'new'
                  ? 'primary-txt'
                  : usernameState === 'exists'
                  ? 'danger-txt'
                  : ''
              }`}
              title={
                usernameState === 'new'
                  ? t('settings.new-username')
                  : usernameState === 'exists'
                  ? t('settings.username-exists')
                  : undefined
              }
            >
              {usernameState === 'new' ? (
                <div className="d-flex">
                  <span className="icon" data-icon="&#x43;" />
                </div>
              ) : usernameState === 'exists' ? (
                <span className="icon" data-icon="&#x42;" />
              ) : undefined}
            </div>
          </div>
        </div>
        {bottomBtns}
      </form>
    </>
  );
}

export default function Settings() {
  const {
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  return (
    <div className={`page container-fluid pt-5 pb-5 ${isRtl ? 'rtl' : 'ltr'}`}>
      <ChangeUsernamePart />
      <ChangePasswordPart />
    </div>
  );
}
