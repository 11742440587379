import { ReactComponent as ArrowLeftSvg } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as PhoneSvg } from 'assets/phone.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import Badge, {
  BadgeBanner,
  BadgeContainer,
  BadgeZone,
} from 'components/badge/Badge';
import Button, { FileButton, ModalCloseButton } from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import { CountryFlag } from 'components/country/Flag';
import { CountryOption } from 'components/country/Option';
import DatePicker from 'components/date-picker/DatePicker';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput, { DialIconInput } from 'components/icon-input/IconInput';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import Picture from 'components/picture/Picture';
import { AsyncPaginate } from 'components/select/Select';
import localAr from 'date-fns/locale/ar-DZ';
import localFr from 'date-fns/locale/fr';
import { loadCountriesOptions } from 'lib/countries-with-icons';
import { useEncryption } from 'lib/encryption';
import moment from 'moment';
import {
  ChangeEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';
import { ValueType } from 'react-select';
import { OptionsList } from 'react-select-async-paginate';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { CountryDto, countries } from 'utils/countries';
import {
  OptionType,
  OptionsData,
  PageModalUrlParams,
  PathLocationDto,
  StandAloneFormModalProps,
  StandAloneViewModalProps,
  alpha2ToOptionType,
  chunk,
  dataArrayToOptions,
  dataToOption,
  formatDzPhoneNumber,
  getContrastYIQ,
  getPage,
  objectIsEmpty,
  personToFullname,
} from 'utils/utils';
import { BadgeDto } from '../../../../shared/dtos/badge.dto';
import { CategoryDto } from '../../../../shared/dtos/category.dto';
import { EventDto } from '../../../../shared/dtos/event.dto';
import { GetPersonDto } from '../../../../shared/dtos/person.dto';
import { PhoneBookDto } from '../../../../shared/dtos/phone-book.dto';
import { Paginate, SearchPaginate } from '../../../../shared/types';
import { ReactComponent as ResetSvg } from 'assets/reset.svg';
import './Person.css';

export default function Person() {
  const [data, setData] = useState<GetPersonDto[]>();
  const history = useHistory<PathLocationDto>();
  const { encrypt, decrypt } = useEncryption();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [perPage, setPerPage] = useState<number>(24);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<never, AxiosResponse<SearchPaginate<GetPersonDto>>>(
        `/person/${newPage - 1}/${newPerPage}/${searchValue}`,
      );
      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
      setPage(newPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page container-fluid ${isRtl ? 'rtl' : 'ltr'}`}>
      <Route
        path="/person/view/:encryptedId"
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedPerson = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedPerson) {
            return null;
          }

          return <ViewPerson {...selectedPerson} />;
        }}
      />
      <Route path="/person/add">
        <FormPerson onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <Route
        path={'/person/edit/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedPerson = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedPerson) {
            return null;
          }

          return (
            <FormPerson
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedPerson}
            />
          );
        }}
      />
      <Route
        path={'/person/badge/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedPerson = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedPerson) {
            return null;
          }

          return <PersonBadge {...selectedPerson} />;
        }}
      />
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <UploadButton
            className={isRtl ? 'mr-3' : 'ml-3'}
            accept=".csv"
            enableUpload
            endpoint="person/csv"
            onUploadFinished={() => updateCards(page, perPage, searchValue)}
          >
            {t('upload-csv-button')}
          </UploadButton> */}
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: PersonLabelDto = {
                sheet: t('sidebar.person'),
                title: t('pages-titles.person'),
                number: t('forms-labels.number'),
                fullNameFr: t('forms-labels.full-name'),
                fullNameAr: t('forms-labels.full-name'),
                function: {
                  nameAr: t('forms-labels.function'),
                  nameFr: t('forms-labels.function'),
                  category: {
                    nameAr: t('forms-labels.category'),
                    nameFr: t('forms-labels.category'),
                  },
                },
                email: t('forms-labels.email'),
                birthPlace: t('forms-labels.birthplace'),
                birthday: t('forms-labels.birthday'),
                issueDate: t('forms-labels.card-edition-date'),
                nationality: t('forms-labels.nationality'),
                phones: {
                  mobile: t('forms-labels.phone-number'),
                  mobile2: t('forms-labels.phone-number2'),
                  fax: t('forms-labels.fax-number'),
                },
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'person/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          <Button
            btnRole="primary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={() => history.push('/person/add')}
          >
            {t('person.add-new-button')}
          </Button>
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <CardsContainer
            direction={isRtl ? 'rtl' : 'ltr'}
            cardsDirection="horizontal"
          >
            {data.map(
              (
                {
                  id,
                  photo,
                  firstNameFr,
                  lastNameFr,
                  firstNameAr,
                  lastNameAr,
                  function: functionality,
                },
                index,
              ) => {
                const viewDetailsBtn = (
                  <Button
                    className={`slidable ${
                      isRtl ? 'slidable-left' : 'slidable-right'
                    } app-small-text d-flex`}
                    onClick={() => {
                      history.push(
                        `/person/view/${encrypt(id?.toString() || '')}`,
                      );
                    }}
                  >
                    <span className="view-details-button-text">
                      {t('details-button')}
                    </span>
                    <ArrowRightSvg
                      className={`card-link-icon svg-icon ${
                        isRtl ? 'rtl' : 'ltr'
                      }`}
                    />
                  </Button>
                );
                const fullname = isRtl
                  ? personToFullname(firstNameAr, lastNameAr)
                  : personToFullname(firstNameFr, lastNameFr);

                const card = (
                  <Card
                    key={index}
                    imageName={photo}
                    cardTitle={fullname}
                    subtitle={
                      isRtl ? functionality?.nameAr : functionality?.nameFr
                    }
                    comment={
                      isRtl
                        ? functionality?.category?.nameAr
                        : functionality?.category?.nameFr
                    }
                    detailsToggle={viewDetailsBtn}
                  />
                );
                return card;
              },
            )}
          </CardsContainer>
          <Paginator
            forcePage={page - 1}
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

export function PersonBadge({
  id,
  photo,
  firstNameFr,
  lastNameFr,
  firstNameAr,
  lastNameAr,
  function: functionality,
  nationality,
  onClose,
}: GetPersonDto & { onClose?: Function }) {
  const badgeRef: MutableRefObject<any> = useRef();
  const handlePrint = useReactToPrint({
    content: () => badgeRef.current,
    removeAfterPrint: true,
  });
  const [badge, setBadge] = useState<BadgeDto>();
  const [selectedEvent, setSelectedEvent] = useState<EventDto>();
  const [selectedZones, setSelectedZones] = useState(new Set<number>());
  const history = useHistory<PathLocationDto>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const getBadge = async (eventId: number) => {
    try {
      const { data } = await axios.get<never, AxiosResponse<BadgeDto>>(
        `/badge/person/${id}/event/${eventId}`,
      );
      const zones = data.zones?.map((v: any) => parseInt(v)) || [];

      setBadge(data);
      setSelectedZones(new Set(zones));
    } catch (error) {
      setBadge(undefined);
      setSelectedZones(new Set());
    }
  };
  const registerBadge = async () => {
    if (selectedEvent?.id === undefined) {
      toast.error(t('person.select-event-message'));
    }

    try {
      const { data } = await axios.post<never, AxiosResponse<BadgeDto>>(
        '/badge/upsert',
        {
          eventId: selectedEvent!.id!,
          ownerId: id,
          ownerType: 'person',
          zones: Array.from(selectedZones),
        },
      );
      const zones = data.zones?.map((v: any) => parseInt(v)) || [];

      setBadge(data);
      setSelectedZones(new Set(zones));

      getBadge(selectedEvent!.id!);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const resetBadge = async () => {
    try {
      const badgeId = badge?.badgeId;
      if (!badgeId) throw Error();

      await axios.delete(`/badge/${badgeId}`);

      setBadge(undefined);
      setSelectedZones(new Set());
    } catch (error) {
      console.error(error);
      toast(t('errors.unable-reseting-badge'));
    }
  };

  let titleFr = '';
  let titleAr = '';
  let leftImageName = '';
  let rightImageName = '';
  let subtitle = '';
  let leftCountry: CountryDto | undefined;
  let rightCountry: CountryDto | undefined;
  if (selectedEvent?.match) {
    // event type is match
    titleFr = selectedEvent.match.competition?.nameFr || '';
    titleAr = selectedEvent.match.competition?.nameAr || '';
    leftImageName = selectedEvent.match.competition?.competitionLogo || '';
    rightImageName = selectedEvent.match.competition?.unitedLogo || '';
    const receiverCountry = countries.find(
      ({ alpha2 }) => alpha2 === selectedEvent.match?.receiverTeam?.trim(),
    );
    const visitorCountry = countries.find(
      ({ alpha2 }) => alpha2 === selectedEvent.match?.visitorTeam?.trim(),
    );
    leftCountry = receiverCountry;
    rightCountry = visitorCountry;
    subtitle = `${receiverCountry?.nameFr} vs ${visitorCountry?.nameFr}`;
  } else if (selectedEvent) {
    // event type is union
    titleFr = selectedEvent.nameFr || '';
    titleAr = selectedEvent.nameAr || '';
    leftImageName = '';
    rightImageName = '';
    subtitle = 'Réunion Générale';
  }

  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/person');
        }
      }}
    />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      className="person-badge-modal-container"
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div className="person-badge-modal">
        <BadgeContainer className="ltr">
          <div ref={badgeRef}>
            {!objectIsEmpty(badge) ? (
              <Badge
                badgeId={badge?.badgeId}
                titleFr={titleFr}
                titleAr={titleAr}
                leftImageName={leftImageName}
                rightImageName={rightImageName}
                personImageName={photo}
                subtitle={subtitle}
                personFirstNameFr={firstNameFr}
                personLastNameFr={lastNameFr}
                personFirstNameAr={firstNameAr}
                personLastNameAr={lastNameAr}
                category={functionality?.category}
                location={selectedEvent?.location?.nameFr}
                functionality={functionality}
                variantColor={functionality?.category?.color}
                alpha2={nationality}
                categoryTextColor={getContrastYIQ(
                  functionality?.category?.color || '#ffffff',
                )}
                zones={Array.from(selectedZones) as number[]}
                leftCountry={leftCountry}
                rightCountry={rightCountry}
                created={new Date()}
                eventDate={
                  selectedEvent?.eventDate
                    ? new Date(selectedEvent.eventDate)
                    : undefined
                }
                templateUrl={selectedEvent?.badgeTemplate}
              />
            ) : (
              <BadgeBanner />
            )}
          </div>
        </BadgeContainer>
        <div className={`badge-config-panel mt-5 ${isRtl ? 'mr-5' : 'ml-5'}`}>
          <label className="app-emphasis" htmlFor="event">
            {t('forms-labels.event')}
          </label>
          <AsyncPaginate
            className="w-100"
            menuPosition="fixed"
            placeholder={t('select-field-placeholder')}
            isSearchable
            required
            id="event"
            value={
              selectedEvent
                ? dataToOption(selectedEvent as OptionsData, isRtl)
                : undefined
            }
            isOptionSelected={(option) => option.value.id === selectedEvent?.id}
            defaultOptions
            cacheOptions
            loadOptions={async (searchValue, loadedOptions) => {
              searchValue = searchValue.trim().toLowerCase();
              const offset = 50;
              const page = getPage(loadedOptions.length, offset);
              const { data } = await axios.get<
                never,
                AxiosResponse<Paginate<EventDto>>
              >(`/event/${page}/${offset}/${searchValue}`);

              const options = dataArrayToOptions(data.results, isRtl);

              return {
                options,
                hasMore: data.total > options.length + loadedOptions.length,
              };
            }}
            onChange={(option: ValueType<OptionType, false>) => {
              const selectedEvent = option?.value;
              setSelectedEvent(selectedEvent);
              getBadge(selectedEvent.id!);
            }}
          />
          <label className="app-emphasis mt-4" htmlFor="event">
            {t('forms-labels.accessible-zones')}
          </label>
          <div className="badge-zones">
            {chunk([1, 2, 3, 4, 5, 6, 7, 8, 9], 3).map((numChunk, index) => (
              <div key={index}>
                {numChunk.map((num, index) => (
                  <BadgeZone
                    key={index}
                    zone={num}
                    active={selectedZones.has(num)}
                    variantColor={functionality?.category?.color}
                    onClick={async () => {
                      if (!objectIsEmpty(badge)) return;

                      if (selectedZones.has(num)) {
                        selectedZones.delete(num);
                        setSelectedZones(new Set(selectedZones));
                      } else {
                        selectedZones.add(num);
                        setSelectedZones(new Set(selectedZones));
                      }

                      // const { data } = await axios.post<
                      //   never,
                      //   AxiosResponse<BadgeDto>
                      // >('badge/upsert', badge);
                      // setBadge(data);
                    }}
                  />
                ))}
              </div>
            ))}
          </div>
          <div className="">
            <Button
              btnRole="secondary"
              btnSize="sm"
              className="pl-2 pr-2 d-flex"
              style={{ minWidth: '120px' }}
              disabled={!badge}
              onClick={badge ? resetBadge : undefined}
              title={t('reset-badge-button')}
            >
              <ResetSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
              <span className="mx-2">{t('reset-badge-button')}</span>
            </Button>
          </div>
          <div className="w-100 action-buttons d-flex justify-content-end">
            <Button
              className="print-btn"
              btnRole="secondary"
              type="button"
              disabled={objectIsEmpty(badge)}
              onClick={objectIsEmpty(badge) ? undefined : handlePrint}
            >
              {t('print-button')}
            </Button>
            <Button
              className={`participate-btn ${isRtl ? 'mr-2' : 'ml-2'}`}
              btnRole="primary"
              type="button"
              disabled={!objectIsEmpty(badge)}
              onClick={!objectIsEmpty(badge) ? undefined : registerBadge}
            >
              {t('save-button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

type ViewPersonTabs = 'info' | 'contact';

export function ViewPerson({
  photo,
  firstNameFr,
  lastNameFr,
  firstNameAr,
  lastNameAr,
  function: functionality,
  birthPlace,
  birthday,
  email,
  idCard,
  issueDate,
  nationality,
  phones,
  onClose,
  onEditClick,
  onGenerateBadgeClick,
}: GetPersonDto & StandAloneViewModalProps) {
  const [tab, setTab] = useState<ViewPersonTabs>('info');
  const history = useHistory<PathLocationDto>();
  const { encryptedId } = useParams<PageModalUrlParams>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const phoneNumber = phones?.find(({ type }) => type === 'mobile')
    ?.phoneNumber;
  const phoneNumber2 = phones?.find(({ type }) => type === 'mobile2')
    ?.phoneNumber;

  const bottomBtns = (
    <>
      <Button
        btnRole="secondary"
        className="ml-4"
        onClick={() => {
          if (onGenerateBadgeClick) {
            onGenerateBadgeClick();
          } else {
            history.push(`/person/badge/${encryptedId}`);
          }
        }}
      >
        {t('generate-badge')}
      </Button>
      <Button
        btnRole="primary"
        className="ml-4"
        onClick={() => {
          if (onEditClick) {
            onEditClick();
          } else {
            history.push(`/person/edit/${encryptedId}`);
          }
        }}
      >
        {t('modify-info-button')}
      </Button>
    </>
  );

  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/person');
        }
      }}
    />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      bottomButtons={bottomBtns}
      onRequestClose={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/person');
        }
      }}
      onAfterClose={() => setTab('info')}
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div
        className={`person-info collapsible ${
          tab === 'info' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="person-info-left">
          <Picture defaultImageName={photo} />
          <div className="app-title mt-3">
            {isRtl
              ? personToFullname(firstNameAr, lastNameAr)
              : personToFullname(firstNameFr, lastNameFr)}
          </div>
          <div className="app-text mt-3">
            {isRtl ? functionality?.nameAr : functionality?.nameFr}
          </div>
          <div className="mt-2 app-text d-flex align-items-center">
            <CountryFlag alpha2={nationality?.trim()} />
            <div className={isRtl ? 'mr-2' : 'ml-2'}>
              {(isRtl
                ? countries.find(
                    ({ alpha2 }) => alpha2 === nationality?.trim() || '',
                  )?.nameAr
                : countries.find(
                    ({ alpha2 }) => alpha2 === nationality?.trim() || '',
                  )?.nameFr) || t('non-specified')}
              &nbsp;({t('forms-labels.nationality')})
            </div>
          </div>
          <Button
            className={`contact-info mt-2 slidable ${
              isRtl ? 'slidable-left' : 'slidable-right'
            } app-small-text`}
            onClick={() => setTab('contact')}
          >
            <PhoneSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('contact-info')}</span>
          </Button>
        </div>
        <div
          className={`person-info-right d-flex flex-column justify-content-center ${
            isRtl ? 'mr-5' : 'ml-5'
          }`}
        >
          <div className="general-info-title app-title mb-3">
            {t('general-info')}
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.last-name')}
            </div>
            <div className="col-7 app-text">
              {isRtl ? lastNameAr : lastNameFr}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.first-name')}
            </div>
            <div className="col-7 app-text">
              {isRtl ? firstNameAr : firstNameFr}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.category')}
            </div>
            <div className="col-7 app-text">
              {(isRtl
                ? functionality?.category?.nameAr
                : functionality?.category?.nameFr) || t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.function')}
            </div>
            <div className="col-7 app-text">
              {(isRtl ? functionality?.nameAr : functionality?.nameFr) ||
                t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.birthday')}
            </div>
            <div className="col-7 app-text">
              {(birthday ? new Date(birthday) : null)?.toLocaleDateString(
                'fr-DZ',
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.birthplace')}
            </div>
            <div className="col-7 app-text">{birthPlace}</div>
          </div>
          <div className="row mt-2">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.id-card')}
            </div>
            <div className="col-7 app-text">
              <FileButton id="id-card" defaultFileName={idCard} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.card-edition-date')}
            </div>
            <div className="col-7 app-text">
              {(issueDate ? new Date(issueDate) : null)?.toLocaleDateString(
                'fr-DZ',
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`person-contact collapsible mb-5 ${
          tab === 'contact' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="contact-info-title app-title">
            {t('contact-info')}
          </div>
          <Button
            className={`info-back mt-2 slidable ${
              isRtl ? 'slidable-right' : 'slidable-left'
            } app-small-text`}
            onClick={() => setTab('info')}
          >
            <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('return')}</span>
          </Button>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">{t('forms-labels.email')}</div>
          <div className="col-7 app-text">{email}</div>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">
            {t('forms-labels.phone-number')}
          </div>
          <div className="col-7 ltr-text app-text">
            {formatDzPhoneNumber(phoneNumber || t('non-specified'), true, true)}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-5 ltr-text app-emphasis">
            {t('forms-labels.phone-number2')}
          </div>
          <div className="col-7 app-text">
            {formatDzPhoneNumber(
              phoneNumber2 || t('non-specified'),
              true,
              true,
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

type FormPersonTabs = 'basics' | 'details&contact' | 'files&access';

export function FormPerson({
  id,
  photo,
  lastNameFr,
  lastNameAr,
  firstNameFr,
  firstNameAr,
  birthPlace,
  birthday,
  email,
  function: functionality,
  idCard,
  issueDate,
  nationality,
  phones,
  formType: defaultFormType,
  closePath,
  onClose,
  onAfterSubmission: afterSubmission,
}: GetPersonDto & StandAloneFormModalProps) {
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [selectedPhone, setSelectedPhone] = useState(
    phones?.find(({ type }) => type === 'mobile'),
  );
  const [selectedPhone2, setSelectedPhone2] = useState(
    phones?.find(({ type }) => type === 'mobile2'),
  );
  const [selectedCountry, setSelectedCountry] = useState(nationality);
  const [tab, setTab] = useState<FormPersonTabs>('basics');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/person');
  };
  const {
    control: basicsControl,
    handleSubmit: submitBasics,
    reset: resetBasics,
    getValues: getBasicsValues,
  } = useForm({
    defaultValues: {
      lastNameFr,
      lastNameAr,
      firstNameFr,
      firstNameAr,
      function: functionality,
    },
  });
  const {
    control: detailsControl,
    handleSubmit: submitDetails,
    reset: resetDetails,
    getValues: getDetailsValues,
  } = useForm({
    defaultValues: {
      nationality,
      birthday: new Date(birthday ?? '2005-01-01T00:00:00.000Z'),
      birthPlace,
      email,
      phones,
    },
  });
  const {
    control: docsControl,
    handleSubmit: submitDocs,
    reset: resetDocs,
    getValues: getDocsValues,
  } = useForm({
    defaultValues: {
      photo,
      idCard,
      issueDate: new Date(
        issueDate ??
          moment.utc(new Date().toISOString().substring(0, 10)).format(),
      ),
    },
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const formType =
    defaultFormType || pathname.startsWith('/person/edit/') ? 'edit' : 'add';
  const formMethod = formType === 'add' ? 'post' : 'put';
  const formAction = formType === 'add' ? '/person/add' : `/person/${id}/edit`;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const submit = async () => {
    if (tab === 'basics') {
      setTab('details&contact');
      return;
    }
    if (tab === 'details&contact') {
      setTab('files&access');
      return;
    }

    setModalRole('processing');

    const data = {
      ...getBasicsValues(),
      ...getDetailsValues(),
      ...getDocsValues(),
    };

    data.phones = [selectedPhone!, selectedPhone2!];

    data.phones = data.phones?.filter((phone) => phone);

    // normalize phone number
    data.phones?.forEach(
      (phone) =>
        (phone.phoneNumber = formatDzPhoneNumber(phone?.phoneNumber || '')),
    );

    if (!data.email) {
      delete data.email;
    }

    try {
      await axios[formMethod](formAction || pathname, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error: any) {
      setModalRole('content');
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/person');
      }}
    />
  );
  const formTitle = t(`modals-titles.${tab}`);

  let bottomBtns: ReactNode;
  if (tab === 'basics') {
    bottomBtns = (
      <Button
        btnRole="primary"
        type="submit"
        form="basics"
        style={{ width: '145px' }}
      >
        <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
        <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
      </Button>
    );
  } else if (tab === 'details&contact') {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('basics')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form="details&contact"
          style={{ width: '145px' }}
        >
          <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
          <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
        </Button>
      </>
    );
  } else {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('details&contact')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form="files&access"
          style={{ width: '145px' }}
        >
          {t('save-button')}
        </Button>
      </>
    );
  }

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      successMessage={
        formType === 'add'
          ? t('person.add-success-message')
          : t('person.modify-success-message')
      }
      successButtons={successButtons}
      pointsOptions={{
        count: 3,
        selectedPoint: [
          tab === 'basics',
          tab === 'details&contact',
          tab === 'files&access',
        ],
      }}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={() => {
        resetBasics();
        resetDetails();
        resetDocs();
      }}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="form-title app-heading-3 mb-5">{formTitle}</div>
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form
            id={'basics' as FormPersonTabs}
            className={`collapsible ${
              tab === 'basics' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitBasics(submit)}
          >
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="lname-fr">
                  {t('forms-labels.last-name')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="lastNameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Nom en français"
                      required
                      id="lname-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={basicsControl}
                  name="lastNameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="اللقب بالعربية"
                      required
                      id="lname-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="fname-fr">
                  {t('forms-labels.first-name')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="firstNameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Prénom en français"
                      required
                      id="fname-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={basicsControl}
                  name="firstNameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الإسم بالعربية"
                      required
                      id="fname-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="functionality"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                >
                  {t('forms-labels.function')}
                </label>
              </div>
              <div className="form-group col-md-5">
                <div className="input-group">
                  <Controller
                    control={basicsControl}
                    name="function"
                    render={({ field: { value, onChange } }) => (
                      <AsyncPaginate
                        className="w-100"
                        menuPosition="fixed"
                        placeholder={t('select-field-placeholder')}
                        isSearchable
                        isOptionSelected={(option) =>
                          option.value.id === value?.id
                        }
                        value={value ? dataToOption(value, isRtl) : undefined}
                        required
                        id="functionality"
                        defaultOptions
                        cacheOptions
                        loadOptions={async (searchValue, loadedOptions) => {
                          searchValue = searchValue.trim().toLowerCase();
                          const offset = 50;
                          const page = getPage(loadedOptions.length, offset);
                          const { data } = await axios.get<
                            never,
                            AxiosResponse<Paginate<CategoryDto>>
                          >(`/category/${page}/${offset}/${searchValue}`);

                          const options = dataArrayToOptions(
                            data.results,
                            isRtl,
                            'functionalities',
                          );

                          return {
                            options,
                            hasMore:
                              data.total >
                              options.length + loadedOptions.length,
                          };
                        }}
                        onChange={(option: ValueType<OptionType, false>) =>
                          onChange(option?.value)
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
          <form
            id={'details&contact' as FormPersonTabs}
            className={`collapsible ${
              tab === 'details&contact' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitDetails(submit)}
          >
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="nationality"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '120px' }}
                >
                  {t('forms-labels.nationality')}
                </label>
              </div>
              <div className="form-group col-md-5">
                <div className="input-group">
                  <Controller
                    control={detailsControl}
                    name="nationality"
                    render={({ field: { onChange } }) => (
                      <AsyncPaginate
                        className="w-100"
                        menuPosition="fixed"
                        placeholder={t('select-field-placeholder')}
                        required
                        isSearchable
                        id="nationality"
                        defaultOptions
                        cacheOptions
                        value={alpha2ToOptionType(selectedCountry || '', isRtl)}
                        loadOptions={async (
                          inputValue: string,
                          loadedOptions: OptionsList<OptionType>,
                        ) => {
                          const countriesOptions = await loadCountriesOptions(
                            isRtl,
                          );

                          const filteredList = countriesOptions.filter(
                            ({ label }) =>
                              label.toLowerCase().includes(inputValue),
                          );

                          const offset = loadedOptions.length;

                          const truncatedList = filteredList.slice(
                            offset,
                            offset + 20,
                          );

                          return {
                            options: truncatedList,
                            hasMore: truncatedList.length < filteredList.length,
                          };
                        }}
                        components={{ Option: CountryOption }}
                        onChange={(option) => {
                          const { value } = option as OptionType;

                          setSelectedCountry(value);
                          onChange(option ? value : undefined);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="birthday"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '180px' }}
                >
                  {t('forms-labels.birthday')}
                </label>
              </div>
              <div className="form-group col-md-3">
                <Controller
                  control={detailsControl}
                  name="birthday"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      id="birthday"
                      required
                      locale={isRtl ? localAr : localFr}
                      selected={value}
                      placeholderText={t('date-field-placeholder')}
                      dateFormat="dd/MM/yyyy"
                      onChange={onChange}
                      calendarStartDay={6}
                    />
                  )}
                />
              </div>
              <div className="form-group col-md-5">
                <Controller
                  control={detailsControl}
                  name="birthPlace"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      required
                      id="birth-place"
                      placeholder={t('forms-labels.birthplace')}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                  htmlFor="email"
                >
                  {t('forms-labels.email')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={detailsControl}
                  name="email"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      id="email"
                      type="email"
                      placeholder="example@email.com"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                  htmlFor="phones-0"
                >
                  {t('forms-labels.phone-number')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={detailsControl}
                  name="phones.0"
                  render={({ field: { onChange } }) => (
                    <DialIconInput
                      defaultValue={
                        selectedPhone?.phoneNumber
                          ? formatDzPhoneNumber(
                              selectedPhone?.phoneNumber,
                              true,
                            )
                          : ''
                      }
                      id="phones-0"
                      required
                      placeholder="06xxxxxxxx/+213xxxxxxx"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newNumber = event.target.value;

                        if (!selectedPhone) {
                          setSelectedPhone({
                            phoneNumber: newNumber,
                            ownerType: 'person',
                            type: 'mobile',
                          } as PhoneBookDto);
                        } else {
                          selectedPhone.phoneNumber = newNumber;
                          setSelectedPhone(selectedPhone);
                        }

                        onChange(selectedPhone);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '120px' }}
                  htmlFor="phones-1"
                >
                  {t('forms-labels.phone-number2')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={detailsControl}
                  name="phones.1"
                  render={({ field: { onChange } }) => (
                    <DialIconInput
                      defaultValue={
                        selectedPhone2?.phoneNumber
                          ? formatDzPhoneNumber(
                              selectedPhone2?.phoneNumber,
                              true,
                            )
                          : ''
                      }
                      id="phones-1"
                      placeholder="06xxxxxxxx/+213xxxxxxx"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newNumber = event.target.value;

                        if (!selectedPhone2) {
                          setSelectedPhone2({
                            phoneNumber: newNumber,
                            ownerType: 'person',
                            type: 'mobile2',
                          } as PhoneBookDto);
                        } else {
                          selectedPhone2.phoneNumber = newNumber;
                          setSelectedPhone2(selectedPhone2);
                        }

                        onChange(selectedPhone2);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <form
            id={'files&access' as FormPersonTabs}
            className={`d-flex flex-column collapsible ${
              tab === 'files&access' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitDocs(submit)}
          >
            <div className="files-access mb-3">
              <Controller
                control={docsControl}
                name="photo"
                render={({ field: { value, onChange } }) => (
                  <Picture
                    required
                    enableUpload
                    defaultImageName={value}
                    onNameChange={onChange}
                  />
                )}
              />
            </div>
            <div className="row">
              <div className="form-group col-md-3 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="id-card"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '160px' }}
                >
                  {t('forms-labels.id-card')}
                </label>
              </div>
              <div className="form-group col-md-9">
                <Controller
                  control={docsControl}
                  name="idCard"
                  render={({ field: { value, onChange } }) => (
                    <FileButton
                      id="id-card"
                      required
                      enableUpload
                      defaultFileName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-3 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="issue-date"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '180px' }}
                >
                  {t('forms-labels.card-edition-date')}
                </label>
              </div>
              <div className="form-group col-md-auto">
                <Controller
                  control={docsControl}
                  name="issueDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      id="issue-date"
                      required
                      locale={isRtl ? localAr : localFr}
                      selected={value}
                      placeholderText={t('date-field-placeholder')}
                      dateFormat="dd/MM/yyyy"
                      onChange={onChange}
                      maxDate={new Date()}
                      calendarStartDay={6}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
