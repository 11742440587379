import './Navbar.css';
import Button from 'components/button/Button';
import { useAuth } from 'components/routes/AuthRouter';
import { PathLocationDto } from 'utils/utils';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as LogoutSvg } from 'assets/logout.svg';
import { MouseEventHandler } from 'react';
import { LanguageSelect } from 'components/select/Select';
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  logo?: boolean;
  leading?: React.ReactNode;
  enableLanguage?: boolean;
  logout?: boolean;
  onLogoutClick?: MouseEventHandler<HTMLButtonElement>;
}

export default function Navbar({
  logo = true,
  leading,
  enableLanguage = true,
  logout = true,
  onLogoutClick,
}: NavbarProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  return (
    <nav className={`app-navbar navbar navbar-expand ${isRtl ? 'rtl' : ''}`}>
      {logo ? (
        <Link to="/home" className={`navbar-logo ${isRtl ? 'ml-5' : 'mr-5'}`}>
          <LogoSvg />
        </Link>
      ) : null}
      <div className="navbar-brand font-weight-bold leading">{leading}</div>
      <div className="flex-grow-1 d-flex justify-content-end">
        {enableLanguage ? (
          <LanguageSelect
            className={isRtl ? 'ml-4' : 'mr-4'}
            isRtl={isRtl}
            styles={{ container: (base) => ({ width: 'unset', ...base }) }}
          />
        ) : undefined}
        {logout ? (
          <Button
            className={`logout-btn slidable ${
              isRtl ? 'slidable-left' : 'slidable-right'
            }`}
            onClick={onLogoutClick}
            direction={isRtl ? 'rtl' : 'ltr'}
          >
            <LogoutSvg
              className={`svg-icon ${isRtl ? 'rtl ml-1' : 'ltr mr-1'}`}
            />
            {t('disconnect-button')}
          </Button>
        ) : null}
      </div>
    </nav>
  );
}

export function PrivateNavbar(args: NavbarProps) {
  const auth = useAuth();
  const history = useHistory<PathLocationDto>();

  if (auth.status === 'authorized') {
    const handleLogout = async () => {
      await auth.logout();
      history.push('/login');
    };

    return <Navbar {...args} onLogoutClick={handleLogout} />;
  }

  return null;
}
