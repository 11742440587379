import ReactCookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import './CookieConsent.css';

export default function CookieConsent() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  return (
    <ReactCookieConsent
      disableStyles={true}
      buttonText={t('cookie-button')}
      buttonClasses="button btn btn-primary btn-normal btn-md m-3"
      containerClasses={`app-cookie-consent ${isRtl ? 'rtl' : ''}`}
      contentClasses="m-3"
    >
      {t('cookie-message')}.
    </ReactCookieConsent>
  );
}
