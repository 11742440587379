import { HTMLProps, useEffect, useState } from 'react';

export function CountryFlag({
  className,
  alpha2,
  ...rest
}: HTMLProps<HTMLDivElement> & { alpha2: string | undefined }) {
  const [flagData, setFlagData] = useState<'no-data' | string>('');

  useEffect(() => {
    if (!alpha2) {
      setFlagData('no-data');
    } else {
      setFlagData(`/flags/32x32/${alpha2}.png`)
      // import(`${window.location.origin}`)
      //   .then(({ default: png }) => {
      //     setFlagData(png);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     setFlagData('no-data');
      //   });
    }
  }, [alpha2]);

  return (
    <div className={`country-flag-icon ${className}`} {...rest}>
      <img
        src={flagData && flagData !== 'no-data' ? flagData : '/flags/32x32/te.png'}
        alt=""
      />
    </div>
  );
}
