import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { UserDto } from '../../../shared/dtos/user.dto';
import { isDevelopment } from 'utils/utils';
import { toast } from 'react-toastify';

export function initConnection() {
  if (isDevelopment()) {
    axios.defaults.withCredentials = true;

    if (window.location.origin.includes('localhost')) {
      axios.defaults.baseURL = 'http://localhost:5005/api';
      return;
    }
  }

  axios.defaults.baseURL = `${window.location.origin}/api`;
}

export type LoginStatus =
  | 'authorized' // checked login and is authorized to proceed
  | 'unauthorized' // needs to login before proceeding
  | 'unchecked'; // did not check login state yet

export function useClientAuth() {
  const [user, setUser] = useState<UserDto>();
  const [status, setStatus] = useState<LoginStatus>('unchecked');

  const login = async (data: { [x: string]: any }): Promise<LoginStatus> => {
    try {
      const res = await axios.post<never, AxiosResponse<UserDto>>(
        '/login',
        data,
      );
      setUser(res.data as UserDto);
      setStatus('authorized');

      if (res.data?.timeout) {
        setTimeout(function () {
          logout(true);
        }, res.data?.timeout);
      }

      return 'authorized';
    } catch (error) {
      console.error(error);

      setUser(undefined);
      setStatus('unauthorized');

      return 'unauthorized';
    }
  };

  const checkLogin = () => {
    axios
      .get<never, AxiosResponse<UserDto>>('/checkLogin')
      .then((res) => {
        setUser(res.data as UserDto);
        setStatus('authorized');

        if (res.data?.timeout) {
          setTimeout(function () {
            logout(true);
          }, res.data?.timeout);
        }
      })
      .catch(() => {
        setUser(undefined);
        setStatus('unauthorized');
      });
  };

  const logout = async (isTimeout?: boolean) => {
    await axios.delete('/logout');
    if (isTimeout) {
      toast('Votre session est finie, reconnectez pour acceder le système.');
    }
    setUser(undefined);
    setStatus('unauthorized');
  };

  /**
   * checkLogin called below only once, when:
   * user refresh page.
   * open the website on a new tab.
   */
  useEffect(checkLogin, []);

  return { status, user, checkLogin, login, logout };
}
