import axios from 'axios';
import { getType } from 'mime';
import { join } from 'path';
import { ExistsCheckType } from '../../../shared/types';

export const supportedFiles = ['image/png', 'image/jpeg', 'application/pdf'];

export async function downloadFile(name: string) {
  if (!supportedFiles.includes(getType(name) || '')) {
    throw new Error(`${name} is not supported.`);
  }
  const res = await axios.get(join('file', name), {
    responseType: 'arraybuffer',
  });

  return Buffer.from(res.data, 'binary');
}

export async function checkFileExists(name: string) {
  if (!supportedFiles.includes(getType(name) || '')) {
    throw new Error(`${name} is not supported.`);
  }

  const res = await axios.get(join('file/check', name));
  const { exists } = res.data as ExistsCheckType;

  return exists;
}

export async function uploadFile(file: File) {
  const data = new FormData();
  data.append('file', file);

  const res = await axios.post('file', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  // res.data holds the file name on server
  return res.data as string;
}

export function openFile(fileName: string) {
  const link = new URL(join(axios.defaults.baseURL!, 'file', fileName)).href;
  window.open(link, '_blank');
}
