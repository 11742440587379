import './Card.css';
import React, { HTMLProps } from 'react';
import { chunk } from 'utils/utils';
import Picture from 'components/picture/Picture';
import { ReactComponent as CalendarSvg } from 'assets/calendar.svg';
import { ReactComponent as LocationsSvg } from 'assets/locations.svg';

export interface CardsContainerProps extends HTMLProps<HTMLDivElement> {
  direction?: 'ltr' | 'rtl';
  cardsDirection?: 'vertical' | 'horizontal';
}

export function CardsContainer({
  children,
  cardsDirection = 'vertical',
  direction = 'ltr',
  className = '',
}: CardsContainerProps) {
  let cards = Array.isArray(children) ? children : [children];
  cards = cards.map((card, index) => {
    className =
      className +
      (cardsDirection === 'vertical'
        ? ' col-xl-3 col-lg-4 col-md-6 col-sm-12'
        : ' col-xl-4 col-lg-6 col-md-12 col-sm-12');

    return (
      <div
        key={index}
        className={`app-card mb-5 ${cardsDirection} ${direction} ${className}`}
      >
        {card}
      </div>
    );
  });
  const rows = chunk(cards, 12).map((cardsChunk, index) => {
    return (
      <div key={index} className="row">
        {cardsChunk}
      </div>
    );
  });

  return <div className={`cards-container ${cardsDirection}`}>{rows}</div>;
}

export interface CardProps extends HTMLProps<HTMLDivElement> {
  imageName?: string;
  cardTitle: React.ReactNode;
  subtitle?: React.ReactNode;
  comment?: React.ReactNode;
  commentType?: 'date' | 'place' | 'unset';
  commentRole?: 'warning' | 'info' | 'danger' | 'unset';
  detailsToggle?: React.ReactNode;
}

export default function Card({
  className = '',
  imageName,
  cardTitle,
  subtitle,
  comment,
  commentType = 'unset',
  commentRole = 'unset',
  detailsToggle,
}: CardProps) {
  const commentClassName = `card-comment app-medium-text mt-2 text-${commentRole}`;

  const commentPrepand: React.ReactNode =
    commentType === 'date' ? (
      <CalendarSvg className="comment-prepand-svg" />
    ) : commentType === 'place' ? (
      <LocationsSvg className="comment-prepand-svg" />
    ) : null;

  return (
    <div className={`card ${className}`}>
      <div className="card-img-top">
        <Picture imageName={imageName} />
      </div>
      <div className="card-body">
        <div className="card-title app-emphasis">{cardTitle}</div>
        <div className="card-details">
          <div className="card-subtitle app-text">{subtitle}</div>
          <div className={commentClassName}>
            {commentPrepand}
            <div className="card-comment-text">{comment}</div>
          </div>
          <div className="card-link app-medium-text mt-1 d-flex justify-content-end">
            {detailsToggle || <div className="pt-4" />}
          </div>
        </div>
      </div>
    </div>
  );
}
