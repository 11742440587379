export function TermsEnTitle() {
  return <span id="g230-1">General Accreditation Terms and Conditions</span>;
}
export function TermsEnBody() {
  return (
    <span id="g230-2">
      The Accreditation Terms and Conditions (T&C) outline the rights and
      responsibilities of individuals holding accreditation for matches
      organized by the Algerian Football Federation. They are legally binding,
      and every individual applying for accreditation must accept the
      Accreditation Terms and Conditions as part of the accreditation process.
      <br />
      <br />
      This accreditation card and the associated rights are granted personally
      to the individual named herein (the "accredited person"); they are
      non-transferable, and the card must be visibly displayed at all times. The
      holder may only access designated areas or facilities (the "authorized
      zones") as designated by the Algerian Football Federation and as stated
      herein.
      <br />
      <br />
      This accreditation card does not grant the accredited party or any other
      individual the right to obtain a seating place. The accreditation right
      granted to the accredited person is fully subject to the Accreditation
      Terms and Conditions ("Accreditation T&C"); this right has been granted to
      the accredited party through this card. By using this accreditation card
      to access authorized zones, the accredited party declares that he/she has
      read and understood the Accreditation T&C and agrees to fully adhere to
      and comply with the Terms.
      <br />
      <br />
      In particular, but not limited to, the accredited party acknowledges the
      following
      <br />
      1- Restrictions regarding objects that may be brought into authorized
      zones;
      <br />
      2- Restrictions regarding recordings and the use of sound and still or
      moving images;
      <br />
      3- Especially if the accredited person is a member of the press or a
      photographer, provisions related to the commercial exploitation of still
      photographic images.
      <br />
      <br />
      The accredited party acknowledges that this accreditation card and its
      associated rights may be revoked and/or the accredited party may be
      prevented from accessing authorized zones for violations of the
      Accreditation T&C or for any other reasons in accordance with the
      Accreditation T&C.
      <br />
      <br />
      Hereby, the accredited party indemniﬁes and holds harmless the FAF and the
      COL from all damages and liabilities suffered or incurred; in connection
      with, or arising from, or resulting from a violation of the Accreditation
      T&C. The FAF is not liable for accidents and bodily injuries suffered by
      the accredited party, or for any loss or damage to the accredited party's
      property; unless expressly stated otherwise in the Accreditation T&C.
      <br />
      <br />
      • The holder must adhere to all safety and security policies and rules.
      <br />
      • The holder must not engage in any commercial activities without formal
      approval from the FAF.
      <br />• For lost or stolen accreditations, a formal report must be sent to
      the FAF International Competitions Department at
      Accreditationfaf@gmail.com.
    </span>
  );
}

export function TermsFrTitle() {
  return <span id="g232-1">Conditions Générales d'Accréditation</span>;
}

export function TermsFrBody() {
  return (
    <span id="g232-2">
      Les Termes et conditions d'accréditation (T et C) décrîvent les droits et
      obligations des personnes en possession d'une accréditation pour les
      matchs organisés par la Fédération Algérienne de Football. Ils ont un
      caractère juridiquement contraignant et chaque personne déposant une
      demande pour l'accréditation doit accepter les Termes et conditions
      d'accréditation comme faisant partie du processus d'accréditation.
      <br />
      <br />
      Cette carte d'accréditation et les droits découlant sont accordés à titre
      personnel à la personne nommée par la présente (la "personne accréditée");
      ils sont non transférables et la carte doit être clairement afﬁchée à tout
      moment. Le porteur ne peut accéder qu'aux zones ou installations (les
      "zones autorisées") désignées par la Fédération Algérienne de Football et
      afﬁchées par la présente.
      <br />
      <br />
      Cette carte d'accréditation ne donne pas à la partie accréditée ou à toute
      autre personne le droit d'obtenir une place assise. Le droit de
      l'accréditation accordé à la personne accréditée est complètement sous
      réserve des Termes et conditions d'accréditation ("T et C
      d'accréditation") ; ce droit a été accordé à la partie accréditée par
      cette carte. En utilisant cette carte d'accréditation pour accéder aux
      zones autorisées, la partie accréditée déclare qu'il/elle a lu et compris
      les (Tet C) d'accréditation et accepte de se soumettre et de respecter les
      Termes pleinement.
      <br />
      <br />
      En particulier, mais sans s'y limiter, la partie accréditée reconnaît ce
      qui suit:
      <br />
      1) Les restrictions relatives aux objets qu'on pourrait amener dans les
      zones autorisées;
      <br />
      2) Les restrictions relatives aux enregistrements et à l'utilisation des
      sons et des images ﬁxes ou en mouvement;
      <br />
      3) Particulièrement si la personne accréditée est un(e) membre de la
      presse ou un(e) photographe, les dispositions relatives à l'exploitation
      commerciale des images photographiques ﬁxes.
      <br />
      <br />
      La partie accréditée reconnait que cette carte d'accréditation et tes
      droits découlant pourraient être retirés et/ou que la partie accréditée
      pourrait être empêchée d'accéder aux zones autorisées en cas de violation
      des (Tet C) d'accréditation, ou pour toutes autres raisons conformément
      aux (T et C) d'accréditation. Par la présente, la partie accréditée
      indemnise et tient à couvert la FAF et le COL à l'égard de tous dommages
      et responsabilités soufferts ou encourus; en relation avec, ou résultant
      de, ou découlant d'une violation des (T et C) d'accréditation.
      <br />
      <br />
      La FAF n'est aucunement tenue responsable des accidents et des lésions
      corporelles sourferts par la partie accréditée, ou pour toute perte ou
      dommage aux biens de la partie accréditée; sauf disposition expresse
      contraire dans les (T et C) d'accréditation.
      <br />
      <br />
      • Le titulaire doit adhérer à toutes les politiques et règles de sécurité
      et de sûreté.
      <br />
      • Le titulaire ne doit exercer aucune activité commerciale sans
      l'approbation formelle de la FAF.
      <br />• Pour les accréditations perdues ou volées. Un rapport formel doit
      être envoyé à Département des compétitions Internationales de la FAF
      accréditationfaf@gmail.com.
    </span>
  );
}

export function AgreeTerms() {
  return (
    <span id="g235">
      By using this Accreditation Card and/or other access passes, you agree to
      its terms & conditions and other FAF competitions regulations and
      decisions.
    </span>
  );
}
