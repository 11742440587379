import { ReactComponent as ArrowLeftSvg } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import Badge, { BadgeContainer } from 'components/badge/Badge';
import Button, { FileButton, ModalCloseButton } from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import { CountryFlag } from 'components/country/Flag';
import { CountryOption } from 'components/country/Option';
import DatePicker from 'components/date-picker/DatePicker';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput from 'components/icon-input/IconInput';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import Picture from 'components/picture/Picture';
import { useAuth } from 'components/routes/AuthRouter';
import { AsyncPaginate } from 'components/select/Select';
import { format } from 'date-fns';
import localAr from 'date-fns/locale/ar-DZ';
import localEn from 'date-fns/locale/en-GB';
import localFr from 'date-fns/locale/fr';
import { loadCountriesOptions } from 'lib/countries-with-icons';
import { useEncryption } from 'lib/encryption';
import { downloadFile } from 'lib/file-transfer';
import { getType } from 'mime';
import moment from 'moment';
import {
  ChangeEvent,
  Fragment,
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';
import { ValueType, createFilter } from 'react-select';
import { OptionsList } from 'react-select-async-paginate';
import TimePicker from 'react-time-picker';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { CountryDto, countries } from 'utils/countries';
import {
  OptionType,
  OptionsData,
  PageModalUrlParams,
  PathLocationDto,
  StandAloneFormModalProps,
  StandAloneViewModalProps,
  alpha2ToOptionType,
  dataArrayToOptions,
  dataToOption,
  demoFunctionality,
  getContrastYIQ,
  getPage,
  getTommorrow,
} from 'utils/utils';
import { CompetitionDto } from '../../../../shared/dtos/competition.dto';
import { EventTypeDto } from '../../../../shared/dtos/event-type.dto';
import { EventDto } from '../../../../shared/dtos/event.dto';
import { LocationDto } from '../../../../shared/dtos/location.dto';
import { Paginate, SearchPaginate } from '../../../../shared/types';
import './Event.css';

export default function Event() {
  const auth = useAuth();
  const [data, setData] = useState<EventDto[]>();
  const [perPage, setPerPage] = useState<number>(24);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const history = useHistory<PathLocationDto>();
  const { encrypt, decrypt } = useEncryption();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<never, AxiosResponse<SearchPaginate<EventDto>>>(
        `/event/${newPage - 1}/${newPerPage}/${searchValue}`,
      );
      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
      setPage(newPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page container-fluid ${isRtl ? 'rtl' : 'ltr'}`}>
      <Route
        path="/event/view/:encryptedId"
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedEvent = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedEvent) {
            return null;
          }

          return <ViewEvent {...selectedEvent} />;
        }}
      />
      <Route path="/event/add">
        <FormEvent onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <Route
        path={'/event/edit/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedEvent = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedEvent) {
            return null;
          }

          return (
            <FormEvent
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedEvent}
            />
          );
        }}
      />
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: EventLabelDto = {
                sheet: t('sidebar.event'),
                title: t('pages-titles.event'),
                number: t('forms-labels.number'),
                nameFr: t('forms-labels.event'),
                nameAr: t('forms-labels.event'),
                eventDate: t('forms-labels.event-date'),
                accreditationDeadline: t('forms-labels.event-last-date'),
                type: {
                  sheet: t('sidebar.event-type'),
                  title: t('pages-titles.event-type'),
                  number: t('forms-labels.number'),
                  nameFr: t('forms-labels.type'),
                  nameAr: t('forms-labels.type'),
                },
                location: {
                  sheet: t('sidebar.location'),
                  title: t('pages-titles.location'),
                  number: t('forms-labels.number'),
                  nameFr: t('forms-labels.location'),
                  nameAr: t('forms-labels.location'),
                  addressFr: t('forms-labels.address'),
                  addressAr: t('forms-labels.address'),
                },
                match: {
                  sheet: t('sidebar.match'),
                  title: t('pages-titles.match'),
                  number: t('forms-labels.number'),
                  receiverTeam: t('forms-labels.teams-types.receiver'),
                  visitorTeam: t('forms-labels.teams-types.visitor'),
                  competition: {
                    sheet: t('sidebar.competition'),
                    title: t('pages-titles.competition'),
                    number: t('forms-labels.number'),
                    nameFr: t('forms-labels.competition'),
                    nameAr: t('forms-labels.competition'),
                  },
                },
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'event/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          {auth.user?.userType === 'faf' ? (
            <Button
              btnRole="primary"
              className={isRtl ? 'mr-3' : 'ml-3'}
              onClick={() => history.push('/event/add')}
            >
              {t('event.add-new-button')}
            </Button>
          ) : undefined}
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <CardsContainer direction={isRtl ? 'rtl' : 'ltr'}>
            {data.map(
              ({ id, nameFr, nameAr, location, eventDate, type }, index) => {
                const viewDetailsBtn = (
                  <Button
                    className={`slidable ${
                      isRtl ? 'slidable-left' : 'slidable-right'
                    } app-small-text d-flex`}
                    onClick={() => {
                      history.push(
                        `/event/view/${encrypt(id?.toString() || '')}`,
                      );
                    }}
                  >
                    <span className="view-details-button-text">
                      {t('details-button')}
                    </span>
                    <ArrowRightSvg
                      className={`card-link-icon svg-icon ${
                        isRtl ? 'rtl' : 'ltr'
                      }`}
                    />
                  </Button>
                );
                const card = (
                  <Card
                    key={index}
                    imageName={location?.image}
                    cardTitle={isRtl ? nameAr : nameFr}
                    subtitle={isRtl ? type?.nameAr : type?.nameFr}
                    comment={
                      eventDate
                        ? format(new Date(eventDate), 'dd/MM/yyyy - HH:mm', {
                            locale: localFr,
                          })
                        : ''
                    }
                    commentType="date"
                    detailsToggle={viewDetailsBtn}
                  />
                );
                return card;
              },
            )}
          </CardsContainer>
          <Paginator
            forcePage={page - 1}
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

export function ViewEvent({
  type,
  nameFr,
  nameAr,
  eventDate,
  accreditationDeadline: accDeadline,
  location,
  match,
  poster,
  onClose,
  onEditClick,
  badgeTemplate,
}: EventDto & StandAloneViewModalProps) {
  const auth = useAuth();
  const history = useHistory<PathLocationDto>();
  const { encryptedId } = useParams<PageModalUrlParams>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const receiverCountry = countries.find(
    ({ alpha2 }) => alpha2 === match?.receiverTeam?.trim(),
  );
  const visitorCountry = countries.find(
    ({ alpha2 }) => alpha2 === match?.visitorTeam?.trim(),
  );
  const [imageSrc, setImageSrc] = useState('');

  const bottomBtns =
    auth.user?.userType === 'faf' ? (
      <Button
        btnRole="primary"
        className="ml-4"
        onClick={() => {
          if (onEditClick) {
            onEditClick();
          } else {
            history.push(`/event/edit/${encryptedId}`);
          }
        }}
      >
        {t('modify-info-button')}
      </Button>
    ) : undefined;

  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/event');
        }
      }}
    />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      bottomButtons={bottomBtns}
      onRequestClose={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/event');
        }
      }}
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div className="event-info">
        <div className="event-info-left">
          <Picture defaultImageName={poster ?? location?.image} />
          {!poster ? (
            <div className="app-small-text text-center mt-2">
              {t('no-poster')}
            </div>
          ) : undefined}
          <div className="app-title mt-3">{isRtl ? nameAr : nameFr}</div>
          <div className="app-text mt-3">
            {isRtl ? type?.nameAr : type?.nameFr} ({t('forms-labels.type')})
          </div>
        </div>
        <div
          className={`event-info-right d-flex flex-column justify-content-center ${
            isRtl ? 'mr-5' : 'ml-5'
          }`}
        >
          <div className="mb-3 d-flex align-items-center justify-content-between">
            <div className="general-info-title app-title">
              {t('general-info')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">{t('forms-labels.name')}</div>
            <div className="col-7 app-text">{isRtl ? nameAr : nameFr}</div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">{t('forms-labels.type')}</div>
            <div className="col-7 app-text">
              {(isRtl ? type?.nameAr : type?.nameFr) || t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.location')}
            </div>
            <div className="col-7 app-text">
              {(isRtl ? location?.nameAr : location?.nameFr) ||
                t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.event-date')}
            </div>
            <div className="col-7 app-text">
              {eventDate
                ? format(new Date(eventDate), 'dd/MM/yyyy - HH:mm', {
                    locale: localFr,
                  })
                : ''}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.event-last-date')}
            </div>
            <div className="col-7 app-text">
              {accDeadline
                ? moment(accDeadline)
                    .subtract(1, 'd')
                    .format('DD/MM/yyyy - 23:59')
                : ''}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.badge-template')}
            </div>
            <div className="col-7 app-text">
              <PreviewDemoBadge
                titleFr={nameFr ?? ''}
                titleAr={nameAr ?? ''}
                badgeTemplate={imageSrc}
                leftImageName={match?.competition?.competitionLogo ?? ''}
                rightImageName={match?.competition?.unitedLogo ?? ''}
                subtitle={
                  match
                    ? `${receiverCountry?.nameFr} vs ${visitorCountry?.nameFr}`
                    : 'Réunion Générale'
                }
                location={location}
                leftCountry={countries.find(
                  ({ alpha2 }) => alpha2 === match?.receiverTeam?.trim(),
                )}
                rightCountry={countries.find(
                  ({ alpha2 }) => alpha2 === match?.visitorTeam?.trim(),
                )}
                eventDate={eventDate ? new Date(eventDate) : undefined}
                printDemo={async (printFunc) => {
                  if (badgeTemplate) {
                    const imageData = await downloadFile(badgeTemplate ?? '');
                    setImageSrc(
                      `data:${getType(
                        badgeTemplate ?? '',
                      )};base64,${imageData.toString('base64')}`,
                    );
                  } else {
                    setImageSrc('');
                  }
                  setTimeout(printFunc, 0);
                }}
              />
            </div>
          </div>
          {match ? (
            <>
              <div className="row mt-4">
                <div className="col-5 d-flex flex-column align-items-center">
                  <div className="app-text font-weight-bold">
                    {t('forms-labels.teams-types.receiver')}
                  </div>
                </div>
                <div className="col-2" />
                <div className="col-5 d-flex flex-column align-items-center">
                  <div className="app-text font-weight-bold">
                    {t('forms-labels.teams-types.visitor')}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-5 d-flex flex-column align-items-center">
                  <CountryFlag alpha2={receiverCountry?.alpha2} />
                  <div className="app-text mt-2">
                    {(isRtl
                      ? receiverCountry?.nameAr
                      : receiverCountry?.nameFr) || t('non-avilable')}
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-center pt-2">
                  —
                </div>
                <div className="col-5 d-flex flex-column align-items-center">
                  <CountryFlag alpha2={visitorCountry?.alpha2} />
                  <div className="app-text mt-2">
                    {(isRtl
                      ? visitorCountry?.nameAr
                      : visitorCountry?.nameFr) || t('non-avilable')}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}

type FormEventTabs = 'basics' | 'details';

export function FormEvent({
  id,
  type,
  nameFr = '',
  nameAr = '',
  eventDate,
  accreditationDeadline: accDeadLine,
  location,
  match,
  poster,
  badgeTemplate,
  onAfterSubmission: afterSubmission,
  formType: defaultFormType,
  closePath,
  onClose,
}: EventDto & StandAloneFormModalProps) {
  const [demoTitleFr, setDemoTitleFr] = useState('');
  const [demoBadgeTemplate, setDemoBadgeTemplate] = useState('');
  const [demoTitleAr, setDemoTitleAr] = useState('');
  const [demoLeftImageName, setDemoLeftImageName] = useState('');
  const [demoRightImageName, setDemoRightImageName] = useState('');
  const [demoSubtitle, setDemoSubtitle] = useState('');
  const [demoLocation, setDemoLocation] = useState<LocationDto>();
  const [demoLeftCountry, setDemoLeftCountry] = useState<CountryDto>();
  const [demoRightCountry, setDemoRightCountry] = useState<CountryDto>();
  const [demoEventDate, setDemoEventDate] = useState<Date>();
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [selectedEventDate, setSelectedEventDate] = useState(
    new Date(
      eventDate
        ? eventDate
        : moment
            .utc(new Date().toISOString().substring(0, 10))
            .add(5, 'd')
            .format(),
    ),
  );
  const [selectedEventType, setSelectedEventType] = useState(type);
  const [selectedReceiverCountry, setSelectedReceiverCountry] = useState(
    match?.receiverTeam?.trim(),
  );
  const [selectedVisitorCountry, setSelectedVisitorCountry] = useState(
    match?.visitorTeam?.trim(),
  );
  const [tab, setTab] = useState<FormEventTabs>('basics');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/event');
  };

  const {
    control: basicsControl,
    handleSubmit: submitBasics,
    reset: resetBasics,
    getValues: getBasicsValues,
  } = useForm({
    defaultValues: {
      nameFr,
      nameAr,
      location,
      poster,
    },
  });
  const {
    control: detailsControl,
    handleSubmit: submitDetails,
    reset: resetDetails,
    getValues: getDetailsValues,
  } = useForm({
    defaultValues: {
      eventDate: selectedEventDate,
      accreditationDeadline: new Date(
        accDeadLine ?? moment(selectedEventDate).subtract(1, 'd').format(),
      ),
      type: selectedEventType,
      match,
      badgeTemplate,
    },
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const formType =
    defaultFormType || pathname.startsWith('/event/edit/') ? 'edit' : 'add';
  const formMethod = formType === 'add' ? 'post' : 'put';
  const formAction = formType === 'add' ? '/event/add' : `/event/edit/${id}`;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const submit = async () => {
    if (tab === 'basics') {
      setTab('details');
      return;
    }

    setModalRole('processing');

    const data = {
      ...getBasicsValues(),
      ...getDetailsValues(),
    };

    if ('Match' !== selectedEventType?.nameFr) {
      delete data.match;
    }

    try {
      await axios[formMethod](formAction || pathname, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error: any) {
      setModalRole('content');
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const printDemo = async (handlePrint: Function) => {
    const basicsValues = getBasicsValues();
    const detailsValues = getDetailsValues();
    setDemoTitleFr(basicsValues.nameFr);
    setDemoTitleAr(basicsValues.nameAr);
    setDemoLeftImageName(
      detailsValues.match?.competition?.competitionLogo ?? '',
    );
    setDemoRightImageName(detailsValues.match?.competition?.unitedLogo ?? '');
    const receiverCountry = countries.find(
      ({ alpha2 }) => alpha2 === match?.receiverTeam?.trim(),
    );
    const visitorCountry = countries.find(
      ({ alpha2 }) => alpha2 === match?.visitorTeam?.trim(),
    );
    setDemoSubtitle(
      detailsValues.match
        ? `${receiverCountry?.nameFr} vs ${visitorCountry?.nameFr}`
        : 'Réunion Générale',
    );
    setDemoLocation(basicsValues.location);
    setDemoLeftCountry(receiverCountry);
    setDemoRightCountry(visitorCountry);
    setDemoEventDate(detailsValues.eventDate);
    let imageSrc = '';
    if (detailsValues.badgeTemplate) {
      const imageData = await downloadFile(detailsValues.badgeTemplate ?? '');
      imageSrc = `data:${getType(
        detailsValues.badgeTemplate ?? '',
      )};base64,${imageData.toString('base64')}`;
    }
    setDemoBadgeTemplate(imageSrc);
    setTimeout(handlePrint, 0);
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/event');
      }}
    />
  );
  const formTitle = t(`modals-titles.${tab}`);

  let bottomBtns: ReactNode;
  if (tab === 'basics') {
    bottomBtns = (
      <Button
        btnRole="primary"
        type="submit"
        form={'basics' as FormEventTabs}
        style={{ width: '145px' }}
      >
        <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
        <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
      </Button>
    );
  } else {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('basics')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form={'details' as FormEventTabs}
          style={{ width: '145px' }}
        >
          {t('save-button')}
        </Button>
      </>
    );
  }

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      pointsOptions={{
        count: 2,
        selectedPoint: [tab === 'basics', tab === 'details'],
      }}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      successMessage={
        formType === 'add'
          ? t('event.add-success-message')
          : t('event.modify-success-message')
      }
      successButtons={successButtons}
      onAfterClose={() => {
        resetBasics();
        resetDetails();
      }}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="form-title app-heading-3 mb-3">{formTitle}</div>
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form
            id={'basics' as FormEventTabs}
            className={`collapsible ${
              tab === 'basics' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitBasics(submit)}
          >
            <div className="row mb-3">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis" htmlFor="poster">
                  {t('forms-labels.poster')}
                </label>
              </div>
              <div className="col-md-10 mb-1">
                <Controller
                  control={basicsControl}
                  name="poster"
                  render={({ field: { value, onChange } }) => (
                    <Picture
                      enableUpload
                      defaultImageName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="name-fr">
                  {t('forms-labels.event-title')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="nameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="titre en français"
                      required
                      id="name-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={basicsControl}
                  name="nameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="العنوان بالعربية"
                      required
                      id="name-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="location">
                  {t('forms-labels.location')}
                </label>
              </div>
              <div className="form-group col-md-5">
                <Controller
                  control={basicsControl}
                  name="location"
                  render={({ field: { value, onChange } }) => (
                    <AsyncPaginate
                      id="location"
                      menuPosition="fixed"
                      defaultOptions
                      required
                      isOptionSelected={(option) =>
                        option.value.id === value?.id
                      }
                      cacheOptions
                      filterOption={createFilter({ ignoreAccents: false })}
                      value={value ? dataToOption(value, isRtl) : undefined}
                      loadOptions={async (searchValue, loadedOptions) => {
                        searchValue = searchValue.trim().toLowerCase();
                        const offset = 20;
                        const page = getPage(loadedOptions.length, offset);
                        const { data } = await axios.get<
                          never,
                          AxiosResponse<Paginate<LocationDto>>
                        >(`/location/${page}/${offset}/${searchValue}`);
                        const options = dataArrayToOptions(data.results, isRtl);

                        return {
                          options,
                          hasMore:
                            data.total > options.length + loadedOptions.length,
                        };
                      }}
                      onChange={(option) => onChange(option.value)}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <form
            id={'details' as FormEventTabs}
            className={`collapsible ${
              tab === 'details' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitDetails(submit)}
          >
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="event-date"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '180px' }}
                >
                  {t('forms-labels.event-date')}
                </label>
              </div>
              <div className="form-group col-md-6">
                <Controller
                  control={detailsControl}
                  name="eventDate"
                  render={({ field: { onChange } }) => (
                    <div style={{ display: 'flex', gap: '5px' }}>
                      <DatePicker
                        id="event-date"
                        required
                        locale={isRtl ? localAr : localFr}
                        placeholderText={t('date-field-placeholder')}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => {
                          if (!date) return;
                          onChange(date);
                          setSelectedEventDate(date as Date);
                        }}
                        selected={selectedEventDate}
                        minDate={
                          formType === 'add' ? getTommorrow(new Date()) : null
                        }
                        calendarStartDay={6}
                      />
                      <TimePicker
                        value={format(selectedEventDate, 'HH:mm', {
                          locale: localEn,
                        })}
                        format="HH:mm"
                        disableClock
                        onChange={(value) => {
                          let date = new Date(selectedEventDate ?? new Date());
                          if (!value) {
                            date.setHours(0, 0, 0, 0);
                          } else {
                            const hours = parseInt(value.slice(0, 2));
                            const minutes = parseInt(value.slice(3, 5));
                            date.setHours(hours, minutes, 0, 0);
                          }
                          onChange(date);
                          setSelectedEventDate(date);
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="accreditation-deadline"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '130px' }}
                >
                  {t('forms-labels.event-last-date')}
                </label>
              </div>
              <div className="form-group col-md-6">
                <Controller
                  control={detailsControl}
                  name="accreditationDeadline"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      id="accreditation-deadline"
                      required
                      locale={isRtl ? localAr : localFr}
                      placeholderText={t('date-field-placeholder')}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => onChange(date)}
                      selected={value}
                      minDate={formType === 'add' ? new Date() : null}
                      maxDate={selectedEventDate}
                      calendarStartDay={6}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="event-type"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '70px' }}
                >
                  {t('forms-labels.type')}
                </label>
              </div>
              <div
                className={`form-group col-md-${
                  'Match' === selectedEventType?.nameFr ? '6' : '10'
                }`}
              >
                <div className="input-group" style={{ maxWidth: '320px' }}>
                  <Controller
                    control={detailsControl}
                    name="type"
                    render={({ field: { onChange } }) => (
                      <AsyncPaginate
                        className="w-100"
                        menuPosition="fixed"
                        isOptionSelected={(option) =>
                          option.value.id === selectedEventType?.id
                        }
                        placeholder={t('select-field-placeholder')}
                        isSearchable
                        id="event-type"
                        defaultOptions
                        cacheOptions
                        value={
                          selectedEventType
                            ? dataToOption(
                                selectedEventType as OptionsData,
                                isRtl,
                              )
                            : undefined
                        }
                        loadOptions={async (searchValue, loadedOptions) => {
                          searchValue = searchValue.trim().toLowerCase();
                          const offset = 20;
                          const page = getPage(loadedOptions.length, offset);
                          const {
                            data: { results, total },
                          } = await axios.get<
                            never,
                            AxiosResponse<Paginate<EventTypeDto>>
                          >(`/event/type/${page}/${offset}/${searchValue}`);

                          const options = dataArrayToOptions(results, isRtl);

                          let searchValues = [
                            'Assemblée générale',
                            'جمعية عامة',
                          ];

                          if (selectedEventType) {
                            searchValues = [
                              selectedEventType.nameFr,
                              selectedEventType.nameAr,
                            ];
                          }

                          const selectedOption = (options as OptionType[]).find(
                            ({ label }) => searchValues.includes(label),
                          );

                          if (selectedOption) {
                            const selectedEventType = results.find(
                              ({ id }) => id === selectedOption?.value?.id,
                            )!;
                            onChange(selectedOption.value);
                            setSelectedEventType(selectedEventType);
                          }

                          return {
                            options,
                            hasMore:
                              total > options.length + loadedOptions.length,
                          };
                        }}
                        onChange={(option) => {
                          const eventType = (option as OptionType)?.value;
                          onChange(eventType);
                          setSelectedEventType(eventType);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            {selectedEventType?.nameFr === 'Match' ? (
              <div className="row">
                <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                  <label
                    htmlFor="receiver-team"
                    className={`m-0 app-emphasis required ${
                      isRtl ? 'rtl reversed' : 'ltr reversed'
                    }`}
                    style={{ minWidth: '120px' }}
                  >
                    {t('forms-labels.teams')}
                  </label>
                </div>
                <div className="form-group col-md-5">
                  <div className="input-group">
                    <Controller
                      control={detailsControl}
                      name="match.receiverTeam"
                      render={({ field: { onChange } }) => (
                        <AsyncPaginate
                          className="w-100"
                          menuPosition="fixed"
                          placeholder={t('forms-labels.teams-types.receiver')}
                          required
                          isSearchable
                          id="receiver-team"
                          defaultOptions
                          cacheOptions
                          value={alpha2ToOptionType(
                            selectedReceiverCountry || '',
                            isRtl,
                          )}
                          loadOptions={async (
                            inputValue: string,
                            loadedOptions: OptionsList<OptionType>,
                          ) => {
                            const countriesOptions = await loadCountriesOptions(
                              isRtl,
                            );

                            const filteredList = countriesOptions.filter(
                              ({ label }) =>
                                label.toLowerCase().includes(inputValue),
                            );

                            const offset = loadedOptions.length;

                            const truncatedList = filteredList.slice(
                              offset,
                              offset + 20,
                            );

                            return {
                              options: truncatedList,
                              hasMore:
                                truncatedList.length < filteredList.length,
                            };
                          }}
                          components={{ Option: CountryOption }}
                          onChange={(option) => {
                            const { value } = option as OptionType;

                            setSelectedReceiverCountry(value);
                            onChange(option ? value : undefined);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="form-group col-md-5">
                  <div className="input-group">
                    <Controller
                      control={detailsControl}
                      name="match.visitorTeam"
                      render={({ field: { onChange } }) => (
                        <AsyncPaginate
                          className="w-100"
                          menuPosition="fixed"
                          placeholder={t('forms-labels.teams-types.visitor')}
                          required
                          isSearchable
                          id="visitor-team"
                          defaultOptions
                          cacheOptions
                          value={alpha2ToOptionType(
                            selectedVisitorCountry || '',
                            isRtl,
                          )}
                          loadOptions={async (
                            inputValue: string,
                            loadedOptions: OptionsList<OptionType>,
                          ) => {
                            const countriesOptions = await loadCountriesOptions(
                              isRtl,
                            );

                            const filteredList = countriesOptions.filter(
                              ({ label }) =>
                                label.toLowerCase().includes(inputValue),
                            );

                            const offset = loadedOptions.length;

                            const truncatedList = filteredList.slice(
                              offset,
                              offset + 20,
                            );

                            return {
                              options: truncatedList,
                              hasMore:
                                truncatedList.length < filteredList.length,
                            };
                          }}
                          components={{ Option: CountryOption }}
                          onChange={(option) => {
                            const { value } = option as OptionType;

                            setSelectedVisitorCountry(value);
                            onChange(option ? value : undefined);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Fragment />
            )}
            {selectedEventType?.nameFr === 'Match' ? (
              <div className="row">
                <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                  <label
                    htmlFor="competition"
                    className={`m-0 app-emphasis required ${
                      isRtl ? 'rtl reversed' : 'ltr reversed'
                    }`}
                    style={{ minWidth: '180px' }}
                  >
                    {t('forms-labels.competition')}
                  </label>
                </div>
                <div className="form-group col-md-8">
                  <Controller
                    control={detailsControl}
                    name="match.competition"
                    render={({ field: { value, onChange } }) => (
                      <AsyncPaginate
                        id="competition"
                        menuPosition="fixed"
                        placeholder={t('select-field-placeholder')}
                        defaultOptions
                        required
                        cacheOptions
                        value={
                          value
                            ? dataToOption(value as OptionsData, isRtl)
                            : undefined
                        }
                        isOptionSelected={(option) =>
                          option.value.id === value?.id
                        }
                        filterOption={createFilter({ ignoreAccents: false })}
                        loadOptions={async (searchValue, loadedOptions) => {
                          searchValue = searchValue.trim().toLowerCase();
                          const offset = 20;
                          const page = getPage(loadedOptions.length, offset);
                          const { data } = await axios.get<
                            never,
                            AxiosResponse<Paginate<CompetitionDto>>
                          >(
                            `/event/competition/${page}/${offset}/${searchValue}`,
                          );
                          const options = dataArrayToOptions(
                            data.results,
                            isRtl,
                          );

                          return {
                            options,
                            hasMore:
                              data.total >
                              options.length + loadedOptions.length,
                          };
                        }}
                        onChange={(option: ValueType<OptionType, false>) =>
                          onChange(option?.value)
                        }
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              <Fragment />
            )}
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="badge-template"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '180px' }}
                >
                  {t('forms-labels.badge-template')}
                </label>
              </div>
              <div className="form-group col-md-10">
                <Controller
                  control={detailsControl}
                  name="badgeTemplate"
                  render={({ field: { value, onChange } }) => (
                    <div className="d-flex">
                      <FileButton
                        id="badge-template"
                        required
                        enableUpload
                        defaultFileName={value}
                        onNameChange={onChange}
                      />
                      <PreviewDemoBadge
                        titleFr={demoTitleFr}
                        titleAr={demoTitleAr}
                        badgeTemplate={demoBadgeTemplate}
                        leftImageName={demoLeftImageName}
                        rightImageName={demoRightImageName}
                        subtitle={demoSubtitle}
                        location={demoLocation}
                        leftCountry={demoLeftCountry}
                        rightCountry={demoRightCountry}
                        eventDate={demoEventDate}
                        printDemo={printDemo}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export interface PreviewDemoBadgeProps {
  titleFr: string;
  titleAr: string;
  badgeTemplate: string;
  leftImageName: string;
  rightImageName: string;
  subtitle: string;
  location?: LocationDto;
  leftCountry?: CountryDto;
  rightCountry?: CountryDto;
  eventDate?: Date;
  printDemo: (printFunc: Function) => void;
}

export function PreviewDemoBadge({
  titleFr,
  titleAr,
  badgeTemplate,
  leftImageName,
  rightImageName,
  subtitle,
  location,
  leftCountry,
  rightCountry,
  eventDate,
  printDemo,
}: PreviewDemoBadgeProps) {
  const firstNameFr = useMemo(() => 'Mohammed', []);
  const lastNameFr = useMemo(() => 'Omar', []);
  const firstNameAr = useMemo(() => 'محمد', []);
  const lastNameAr = useMemo(() => 'عمر', []);
  const nationality = useMemo(() => 'dz', []);
  const badgeRef: MutableRefObject<any> = useRef();
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => badgeRef.current,
    removeAfterPrint: true,
  });

  return (
    <>
      <div className="d-none">
        <BadgeContainer className="ltr">
          <div ref={badgeRef}>
            <Badge
              badgeId={999999}
              titleFr={titleFr}
              titleAr={titleAr}
              leftImageName={leftImageName}
              rightImageName={rightImageName}
              personImageName={''}
              subtitle={subtitle}
              personFirstNameFr={firstNameFr}
              personLastNameFr={lastNameFr}
              personFirstNameAr={firstNameAr}
              personLastNameAr={lastNameAr}
              category={demoFunctionality?.category}
              location={location?.nameFr}
              functionality={demoFunctionality}
              variantColor={demoFunctionality?.category?.color}
              alpha2={nationality}
              categoryTextColor={getContrastYIQ(
                demoFunctionality?.category?.color || '#ffffff',
              )}
              zones={[2, 4, 6, 8]}
              leftCountry={leftCountry}
              rightCountry={rightCountry}
              created={new Date()}
              eventDate={eventDate ? new Date(eventDate) : undefined}
              template={badgeTemplate}
            />
          </div>
        </BadgeContainer>
      </div>
      <Button
        className="mx-3"
        btnRole="secondary"
        onClick={() => printDemo(handlePrint)}
      >
        {t('preview-demo-button')}
      </Button>
    </>
  );
}
