import { ReactComponent as ArrowLeftSvg } from 'assets/arrow-left.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as ResetSvg } from 'assets/reset.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import { BadgeContainer, BadgeZone } from 'components/badge/Badge';
import Button, { ModalCloseButton } from 'components/button/Button';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput from 'components/icon-input/IconInput';
import ListView, { ListTile } from 'components/listview/ListView';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import { useAuth } from 'components/routes/AuthRouter';
import { AsyncPaginate } from 'components/select/Select';
import { t } from 'i18next';
import { useEncryption } from 'lib/encryption';
import { downloadFile } from 'lib/file-transfer';
import { getType } from 'mime';
import {
  ChangeEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router';
import { ValueType } from 'react-select';
import { OptionsList } from 'react-select-async-paginate';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import { CountryDto, countries } from 'utils/countries';
import {
  DemandBadgeTile,
  OptionType,
  OptionsData,
  PathLocationDto,
  StandAloneFormModalProps,
  StandAloneViewModalProps,
  chunk,
  dataArrayToOptions,
  dataToOption,
  demandsToTiles,
  getPage,
  normalizeToastMessage,
  objectIsEmpty,
  personDataArrayToOptions,
  personDataToOption,
  personToFullname,
} from 'utils/utils';
import { BadgeDto } from '../../../../shared/dtos/badge.dto';
import {
  FixedGetEventSortedDemandDto,
  GetDemandDto,
  SetDemandDto,
} from '../../../../shared/dtos/demand.dto';
import { EventDto } from '../../../../shared/dtos/event.dto';
import { GetJournalistDto } from '../../../../shared/dtos/journalist.dto';
import { MatchDto } from '../../../../shared/dtos/match.dto';
import { Paginate, SearchPaginate } from '../../../../shared/types';
import './Demand.css';
import { DemandSelectedBadge } from './components/DemandSelectedBadge';
import { TileBadge } from './components/TileBadge';

export default function Demand() {
  const [data, setData] = useState<FixedGetEventSortedDemandDto[]>();
  const history = useHistory();
  const auth = useAuth();
  const { encrypt, decrypt } = useEncryption();
  const {
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [perPage, setPerPage] = useState<number>(24);
  const [total, setTotal] = useState<number>(0);
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<
        never,
        AxiosResponse<SearchPaginate<FixedGetEventSortedDemandDto>>
      >(`/demand/${newPage - 1}/${newPerPage}/${searchValue}`);

      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page ${isRtl ? 'rtl' : 'ltr'} container-fluid`}>
      <Route
        path="/demand/view/:encryptedEventId/:encryptedOmId"
        render={({
          match: {
            params: { encryptedEventId, encryptedOmId },
          },
        }) => {
          const decryptedEventId = decrypt(`${encryptedEventId}`);
          const decryptedOmId = decrypt(`${encryptedOmId}`);
          const selectedDemandPack = data?.find(
            ({ id, om }) =>
              id === parseInt(decryptedEventId) &&
              om?.id === parseInt(decryptedOmId),
          );

          if (!selectedDemandPack) {
            return null;
          }

          return (
            <ViewDemands
              {...selectedDemandPack}
              onEditClick={() => updateCards(1, perPage)}
            />
          );
        }}
      />
      <Route path="/demand/add">
        <FormDemand onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: FixedEventSortedDemandLabelDto = {
                sheet: t('sidebar.demand'),
                title: t('pages-titles.demands'),
                number: t('forms-labels.number'),
                nameFr: t('forms-labels.event'),
                nameAr: t('forms-labels.event'),
                eventDate: t('forms-labels.event-date'),
                accreditationDeadline: t('forms-labels.event-last-date'),
                om: {
                  sheet: t('sidebar.om'),
                  title: t('pages-titles.om'),
                  number: t('forms-labels.number'),
                  nameFr: t('forms-labels.om'),
                  nameAr: t('forms-labels.om'),
                  addressAr: t('forms-labels.address'),
                  addressFr: t('forms-labels.address'),
                  email: t('forms-labels.email'),
                  suspended: {
                    name: t('forms-labels.participation'),
                    true: t('suspension-status.true'),
                    false: t('suspension-status.false'),
                  },
                  creationDate: t('forms-labels.creation-date'),
                  type: {
                    nameAr: t('forms-labels.type'),
                    nameFr: t('forms-labels.type'),
                  },
                  phones: {
                    mobile: t('forms-labels.phone-number'),
                    mobile2: t('forms-labels.phone-number2'),
                    fax: t('forms-labels.fax-number'),
                  },
                  demands: {
                    badge: {
                      zones: t('forms-labels.accessible-zones'),
                    },
                    journalist: {
                      sheet: t('sidebar.journalist'),
                      title: t('pages-titles.journalist'),
                      number: t('forms-labels.number'),
                      fullNameFr: t('forms-labels.full-name'),
                      fullNameAr: t('forms-labels.full-name'),
                      function: {
                        nameAr: t('forms-labels.function'),
                        nameFr: t('forms-labels.function'),
                        category: {
                          nameAr: t('forms-labels.category'),
                          nameFr: t('forms-labels.category'),
                        },
                      },
                      email: t('forms-labels.email'),
                      birthPlace: t('forms-labels.birthplace'),
                      birthday: t('forms-labels.birthday'),
                      issueDate: t('forms-labels.card-edition-date'),
                      nationality: t('forms-labels.nationality'),
                      phones: {
                        mobile: t('forms-labels.phone-number'),
                        mobile2: t('forms-labels.phone-number2'),
                        fax: t('forms-labels.fax-number'),
                      },
                      om: {
                        sheet: t('sidebar.om'),
                        title: t('pages-titles.om'),
                        number: t('forms-labels.number'),
                        nameFr: t('forms-labels.om'),
                        nameAr: t('forms-labels.om'),
                      },
                    },
                  },
                },
                type: {
                  sheet: t('sidebar.event-type'),
                  title: t('pages-titles.event-type'),
                  number: t('forms-labels.number'),
                  nameFr: t('forms-labels.type'),
                  nameAr: t('forms-labels.type'),
                },
                location: {
                  sheet: t('sidebar.location'),
                  title: t('pages-titles.location'),
                  number: t('forms-labels.number'),
                  nameFr: t('forms-labels.location'),
                  nameAr: t('forms-labels.location'),
                  addressFr: t('forms-labels.address'),
                  addressAr: t('forms-labels.address'),
                },
                match: {
                  sheet: t('sidebar.match'),
                  title: t('pages-titles.match'),
                  number: t('forms-labels.number'),
                  receiverTeam: t('forms-labels.teams-types.receiver'),
                  visitorTeam: t('forms-labels.teams-types.visitor'),
                  competition: {
                    sheet: t('sidebar.competition'),
                    title: t('pages-titles.competition'),
                    number: t('forms-labels.number'),
                    nameFr: t('forms-labels.competition'),
                    nameAr: t('forms-labels.competition'),
                  },
                },
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'demand/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          {auth.user?.userType === 'om' && auth.user?.suspended !== true ? (
            <Button
              btnRole="primary"
              className={isRtl ? 'mr-3' : 'ml-3'}
              onClick={() => history.push('/demand/add')}
            >
              {t('demand.add-new-button')}
            </Button>
          ) : undefined}
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <ListView className="mb-5">
            {data.map(
              (
                { id, om, poster, nameFr, nameAr, eventDate, location },
                index,
              ) => {
                const pendingCount =
                  om?.demands?.filter((d) => d.demandState === 'pending')
                    ?.length ?? 0;
                const viewDetailsBtn = (
                  <Button
                    className="app-emphasis"
                    btnRole="secondary"
                    btnSize="md"
                    onClick={() => {
                      history.push(
                        `/demand/view/${encrypt(
                          id?.toString() || '',
                        )}/${encrypt(om?.id?.toString() || '')}`,
                      );
                    }}
                  >
                    <span className="view-details-button-text">
                      {t('details-button')}
                    </span>
                  </Button>
                );

                return (
                  <ListTile
                    key={index}
                    imageName={
                      auth.user?.userType === 'faf'
                        ? om?.logo
                        : poster ?? location?.image
                    }
                    leading={`${pendingCount ? `(${pendingCount}) ` : ''}${
                      auth.user?.userType === 'faf'
                        ? isRtl
                          ? om?.nameAr
                          : om?.nameFr
                        : isRtl
                        ? nameAr
                        : nameFr
                    }`}
                    tileTitle={
                      auth.user?.userType === 'faf'
                        ? isRtl
                          ? nameAr
                          : nameFr
                        : undefined
                    }
                    subtitle={
                      eventDate
                        ? new Date(eventDate).toLocaleDateString('fr-DZ')
                        : undefined
                    }
                    endToggles={viewDetailsBtn}
                  />
                );
              },
            )}
          </ListView>
          <Paginator
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

type ViewDemandsTabs = 'view&handle' | 'badge';

export function ViewDemands({
  id,
  nameFr,
  nameAr,
  onEditClick,
  om,
  onClose,
  badgeTemplate,
  ...rest
}: FixedGetEventSortedDemandDto & StandAloneViewModalProps) {
  const badgeRef: MutableRefObject<any> = useRef();
  const multipleBadgesRef: MutableRefObject<any> = useRef();
  const handlePrint = useReactToPrint({
    content: () => badgeRef.current,
    removeAfterPrint: true,
  });
  const handleMultiplesPrint = useReactToPrint({
    content: () => multipleBadgesRef.current,
    removeAfterPrint: true,
  });
  const [count, setCount] = useState(Number.MIN_SAFE_INTEGER);
  const history = useHistory<PathLocationDto>();
  const auth = useAuth();
  const [badge, setBadge] = useState<BadgeDto>();
  const [imageSrc, setImageSrc] = useState('');
  const [badgeJournalist, setBadgeJournalist] = useState<GetJournalistDto>();
  const [tab, setTab] = useState<ViewDemandsTabs>('view&handle');
  const [edited, setEdited] = useState(false);
  const [tiles, setTiles] = useState<DemandBadgeTile[]>(
    demandsToTiles({ id, nameFr, om, ...rest }),
  );
  const [match, setMatch] = useState<MatchDto>();
  let [titleFr, setTitleFr] = useState('');
  let [titleAr, setTitleAr] = useState('');
  let [leftImageName, setLeftImageName] = useState('');
  let [rightImageName, setRightImageName] = useState('');
  let [leftCountry, setLeftCountry] = useState<CountryDto>();
  let [rightCountry, setRightCountry] = useState<CountryDto>();
  let [subtitle, setSubtitle] = useState('');
  const [printIndexes, setPrintIndexes] = useState<number[]>([]);
  const {
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  const submit = async (index: number) => {
    const data = tiles[index];
    const badge = data.badge;
    const demandId = data.demand!.demandId!;

    if ((badge?.zones?.length || 0) < 1) {
      toast.error(t('errors.unselected-journalist-zones'));
      return;
    }

    try {
      const { data } = await axios.put<never, AxiosResponse<BadgeDto>>(
        `demand/answer/accept/${demandId}`,
        badge,
      );

      tiles[index].demand!.demandState = 'accepted' as any;
      tiles[index].badge = data;
      setEdited(true);
      setTiles(Array.from(tiles));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const reject = async (index: number) => {
    const data = tiles[index];
    const demandId = data.demand!.demandId!;

    try {
      // the object supplied with the request is necessary but ignored because action is reject.
      await axios.put(`demand/answer/reject/${demandId}`, {
        eventId: -1,
        demandId: -1,
      });
      tiles[index].demand!.demandState = 'rejected' as any;
      setEdited(true);
      setTiles(Array.from(tiles));
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const getBadge = async (journalistId: number, eventId: number) => {
    try {
      const { data } = await axios.get<never, AxiosResponse<BadgeDto>>(
        `/badge/journalist/${journalistId}/event/${eventId}`,
      );
      data.zones = (data.zones?.map((v: any) => parseInt(v)) || []) as any;

      setBadge(data);
    } catch (error) {
      toast(t('errors.unable-loading-badge'));
      setBadge(undefined);
    }
  };
  const resetBadge = async (index: number) => {
    try {
      const badgeId = tiles[index].badge?.badgeId;
      if (!badgeId) throw Error();

      await axios.delete(`/badge/${badgeId}`);

      tiles[index].demand!.demandState = 'pending' as any;
      tiles[index].badge = {
        demandId: tiles[index].demand?.demandId,
        eventId: tiles[index].demand?.event?.id,
        ownerId: tiles[index].demand?.journalist?.id,
        ownerType: 'journalist' as any,
        zones: [] as any,
      } as BadgeDto;
      setCount(count + 1);
    } catch (error) {
      console.error(error);
      toast(t('errors.unable-reseting-badge'));
      setBadge(undefined);
    }
  };
  const printSelected = async () => {
    if (printIndexes.length === 0) {
      toast.warn(t('no-journalist-selected'));
      return;
    }

    if (badgeTemplate) {
      const imageData = await downloadFile(badgeTemplate ?? '');
      setImageSrc(
        `data:${getType(badgeTemplate ?? '')};base64,${imageData.toString(
          'base64',
        )}`,
      );
    } else {
      setImageSrc('');
    }

    setTimeout(handleMultiplesPrint, 0);
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (edited) onEditClick?.();
        if (onClose) {
          onClose();
        } else {
          history.push('/demand');
        }
      }}
    />
  );

  useEffect(() => {
    if (!match) {
      // event type is union
      setTitleFr(nameFr || '');
      setTitleAr(nameAr || '');
      setLeftImageName('');
      setRightImageName('');
      setLeftCountry(undefined);
      setRightCountry(undefined);
      setSubtitle('Réunion Générale');
      return;
    }

    // event type is match
    setTitleFr(match.competition?.nameFr || '');
    setTitleAr(match.competition?.nameAr || '');
    setLeftImageName(match.competition?.competitionLogo || '');
    setRightImageName(match.competition?.unitedLogo || '');
    const receiverCountry = countries.find(
      ({ alpha2 }) => alpha2 === match?.receiverTeam?.trim(),
    );
    const visitorCountry = countries.find(
      ({ alpha2 }) => alpha2 === match?.visitorTeam?.trim(),
    );
    setLeftCountry(receiverCountry);
    setRightCountry(visitorCountry);
    setSubtitle(`${receiverCountry?.nameFr} vs ${visitorCountry?.nameFr}`);
  }, [match, nameAr, nameFr]);

  useEffect(() => {
    if (id === undefined) {
      setMatch(undefined);
      return;
    }

    void axios
      .get<never, AxiosResponse<EventDto>>(`/event/${id}`)
      .then(({ data }) => setMatch(data.match));
  }, [id]);

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      className={tab === 'badge' ? 'demand-badge-modal-container' : ''}
      onRequestClose={() => {
        if (edited) onEditClick?.();
        if (onClose) {
          onClose();
        } else {
          history.push('/demand');
        }
      }}
      topRightButtons={modalCloseButton}
    >
      <div
        className={`demand-info demand-modal-content d-flex flex-column collapsible ${
          tab === 'view&handle' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="row w-75 ml-auto mr-auto mt-3">
          <div className="col-6">
            <div
              className="app-emphasis text-center mb-2"
              style={{ minWidth: '120px' }}
            >
              {t('forms-labels.event')}
            </div>
            <div className="app-text text-center">
              {isRtl ? nameAr : nameFr}
            </div>
          </div>
          {auth.user?.userType === 'faf' ? (
            <div className="col-6">
              <div className="app-emphasis text-center mb-2">
                {t('forms-labels.om')}
              </div>
              <div className="app-text text-center">
                {(isRtl ? om?.nameAr : om?.nameFr) || 'Non specifié'}
              </div>
            </div>
          ) : undefined}
        </div>
        <div className="dropdown-divider" />
        {auth.user?.userType === 'faf' ? (
          <div className="d-flex justify-content-between">
            <label className="print-all-button d-flex flex-row-reverse mt-2 mb-0 align-items-center">
              <span style={{ minWidth: '120px' }}>
                {t('select-all-button')}
              </span>
              <input
                type="checkbox"
                className={isRtl ? 'ml-3' : 'mr-3'}
                checked={
                  tiles.length > 0 &&
                  tiles.filter(
                    ({ demand }) => demand?.demandState === 'accepted',
                  ).length === printIndexes.length &&
                  printIndexes.length > 0
                }
                onChange={(ev) => {
                  if (ev.target.checked) {
                    const acceptedTilesIndexes: number[] = [];
                    tiles.forEach(({ demand }, index) => {
                      const isAccepted = demand?.demandState === 'accepted';
                      if (isAccepted) {
                        acceptedTilesIndexes.push(index);
                      }
                      return isAccepted;
                    });

                    setPrintIndexes(acceptedTilesIndexes);
                  } else {
                    setPrintIndexes([]);
                  }
                }}
              />
            </label>
            <Button
              btnRole="secondary"
              className="add-journalist-demand pl-3 pr-3"
              onClick={printSelected}
            >
              <span>{t('print-selected-button')}</span>
            </Button>
          </div>
        ) : undefined}
        <ListView className="journalists-demands-list mt-3 overflow-auto">
          {tiles.map(({ badge, demand }, index) => {
            const journalist = demand?.journalist;
            const zones = new Set(
              badge?.zones?.map((v: any) => parseInt(v)) || [],
            );

            const endToggles = (
              <>
                {auth.user?.userType === 'faf' &&
                demand?.demandState !== 'rejected' ? (
                  <div className="badge-zones mr-3">
                    {chunk([1, 2, 3, 4, 5, 6, 7, 8, 9], 5).map(
                      (numChunk, zonesIndex) => (
                        <div key={zonesIndex}>
                          {numChunk.map((num, index) => {
                            return (
                              <BadgeZone
                                key={index}
                                zone={num}
                                disabled={auth.user?.userType !== 'faf'}
                                active={zones.has(num)}
                                variantColor={
                                  journalist?.function?.category?.color
                                }
                                onClick={async () => {
                                  if (
                                    auth.user?.userType !== 'faf' ||
                                    demand?.demandState === 'rejected' ||
                                    demand?.demandState === 'accepted'
                                  ) {
                                    return;
                                  }

                                  if (zones.has(num)) {
                                    zones.delete(num);
                                  } else {
                                    zones.add(num);
                                  }

                                  if (badge?.zones) {
                                    badge.zones = Array.from(zones) as any;
                                  }

                                  setTiles(Array.from(tiles));
                                }}
                              />
                            );
                          })}
                        </div>
                      ),
                    )}
                  </div>
                ) : (
                  <div style={{ width: '410px' }}></div>
                )}
                <div className="demand-badge-action-buttons">
                  {auth.user?.userType === 'faf' ? (
                    demand?.demandState === 'pending' ? (
                      <>
                        {demand?.demandState === 'pending' ? (
                          <Button
                            btnRole="primary"
                            btnSize="sm"
                            className="ml-2 pl-3 pr-3"
                            disabled={zones.size === 0}
                            onClick={() => submit(index)}
                          >
                            {t('review-action.accept')}
                          </Button>
                        ) : undefined}
                        <Button
                          btnRole="secondary"
                          btnSize="sm"
                          accent="danger"
                          className="ml-2 pl-3 pr-3"
                          onClick={() => reject(index)}
                        >
                          {t('review-action.reject')}
                        </Button>
                      </>
                    ) : demand?.demandState === 'accepted' ? (
                      <>
                        <Button
                          btnRole="secondary"
                          btnSize="sm"
                          className="ml-2 pl-2 pr-2"
                          style={{ maxWidth: '60px' }}
                          onClick={() => resetBadge(index)}
                          title={t('reset-badge-button')}
                        >
                          <ResetSvg
                            className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`}
                          />
                        </Button>
                        <Button
                          btnRole="secondary"
                          btnSize="sm"
                          className="ml-2 pl-2 pr-2"
                          style={{ minWidth: '60px' }}
                          onClick={async () => {
                            await getBadge(journalist!.id!, id!);
                            setTab('badge');
                            setBadgeJournalist(journalist);
                          }}
                        >
                          {t('generate-badge')}
                        </Button>
                      </>
                    ) : undefined
                  ) : undefined}
                </div>
              </>
            );
            const fullname = isRtl
              ? personToFullname(
                  journalist?.firstNameAr || '',
                  journalist?.lastNameAr || '',
                )
              : personToFullname(
                  journalist?.firstNameFr || '',
                  journalist?.lastNameFr || '',
                );

            return (
              <ListTile
                key={index}
                index={index}
                imageName={journalist?.photo}
                leading={
                  fullname +
                  (auth.user?.userType === 'faf'
                    ? ` (${
                        isRtl
                          ? journalist?.function?.nameAr
                          : journalist?.function?.nameFr
                      })`
                    : '')
                }
                subtitle={
                  demand?.demandState === 'accepted' ? (
                    <span className="primary-txt ml-3 app-text">
                      {t('acceptance-status.accepted')}
                    </span>
                  ) : demand?.demandState === 'rejected' ? (
                    <span className="danger-txt ml-3 app-text">
                      {t('acceptance-status.rejected')}
                    </span>
                  ) : (
                    <span className="text-warning ml-3 app-text">
                      {t('acceptance-status.pending')}
                    </span>
                  )
                }
                endToggles={endToggles}
                checked={!!printIndexes.includes(index)}
                disableSelection={demand?.demandState !== 'accepted'}
                onSelectChange={
                  auth.user?.userType === 'faf'
                    ? () => {
                        if (printIndexes.includes(index)) {
                          setPrintIndexes(
                            printIndexes.filter((i) => i !== index),
                          );
                        } else {
                          setPrintIndexes([...printIndexes, index]);
                        }
                      }
                    : undefined
                }
              />
            );
          })}
        </ListView>
      </div>
      <div className="d-none">
        {/* <div style={{ scale: '35%', transform: 'translateX(-300px)' }}> */}
        <div ref={multipleBadgesRef}>
          {tiles.map((tile, index, items) => (
            <div className={printIndexes.includes(index) ? '' : 'd-none'}>
              <TileBadge
                key={index}
                tile={tile}
                match={match}
                nameFr={nameFr}
                nameAr={nameAr}
                location={rest.location}
                eventDate={rest.eventDate}
                om={om}
                template={imageSrc}
              />
              {!objectIsEmpty(items[index + 1]) &&
              index !== tiles.length - 1 &&
              printIndexes.includes(index + 1) ? (
                <div key={index} className="pagebreak" />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={`demand-badge-modal collapsible ${
          tab === 'badge' ? 'visible' : 'collapsed'
        }`}
      >
        <BadgeContainer className="ltr">
          <div ref={badgeRef}>
            <DemandSelectedBadge
              om={om}
              badge={badge}
              titleFr={titleFr}
              titleAr={titleAr}
              leftImageName={leftImageName}
              rightImageName={rightImageName}
              leftCountry={leftCountry}
              rightCountry={rightCountry}
              badgeJournalist={badgeJournalist}
              subtitle={subtitle}
              eventDate={rest.eventDate}
              location={rest.location}
              templateUrl={badgeTemplate}
            />
          </div>
        </BadgeContainer>
        <div className={`badge-config-panel mt-5 ${isRtl ? 'mr-5' : 'ml-5'}`}>
          <div>
            <Button
              className={`info-back mt-2 slidable app-small-text ${
                isRtl ? 'slidable-right' : 'slidable-left'
              }`}
              onClick={() => {
                setTab('view&handle');
                setBadgeJournalist(undefined);
                setBadge(undefined);
              }}
            >
              <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
              <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('return')}</span>
            </Button>
          </div>
          <div className="w-100 action-buttons d-flex justify-content-end">
            <Button
              className="print-btn"
              btnRole="secondary"
              type="button"
              onClick={handlePrint}
            >
              {t('print-button')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

interface DemandTileData {
  journalist?: GetJournalistDto;
  sent?: boolean;
}

export function FormDemand({
  onAfterSubmission: afterSubmission,
  closePath,
  onClose,
}: GetDemandDto & StandAloneFormModalProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const {
    control: allControl,
    reset: resetAll,
    getValues: getValuesAll,
    handleSubmit: submitAll,
  } = useForm<{
    event: Omit<EventDto, 'match'>;
    journalists: GetJournalistDto[];
  }>({
    defaultValues: { journalists: [] },
  });
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [journalistList, setJournalistList] = useState<DemandTileData[]>([]);
  const history = useHistory();
  const formMethod = 'post';
  const formAction = '/demand/add';
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }
    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/demand');
  };
  const submit = async (index?: number) => {
    setModalRole('processing');

    let data: SetDemandDto[];
    const values = getValuesAll();

    if (values.journalists.some((journalist) => !journalist?.id)) {
      if (index !== undefined) {
        toast.error(`${t('demand-empty-journalist')}.`);
      } else {
        toast.error(`${t('demand-empty-list')}.`);
      }
      setModalRole('content');
      return;
    }

    if (Number.isInteger(index)) {
      data = [
        {
          eventId: values.event?.id,
          journalistId: values.journalists[index!].id,
        },
      ];
    } else {
      data = values.journalists.map(({ id }) => {
        return { eventId: values.event?.id, journalistId: id };
      });
    }

    const errorMessages: ReactNode[] = [];

    for (const dataTuple of data) {
      try {
        await axios[formMethod](formAction, dataTuple);
      } catch (error: any) {
        if (axios.isAxiosError(error)) {
          const messages =
            error.response?.data?.message || error.response?.statusText;
          if (Array.isArray(messages)) {
            errorMessages.push(
              <>
                {normalizeToastMessage(
                  messages.reduce(
                    (prevMsg, msg) => (
                      <div>
                        {prevMsg}- {msg}.
                      </div>
                    ),
                    '',
                  ),
                )}
                <br />
              </>,
            );
          } else {
            errorMessages.push(
              <>
                {normalizeToastMessage(messages?.toString())}
                <br />
              </>,
            );
          }
        } else {
          errorMessages.push(
            <>
              {normalizeToastMessage(error.message)}
              <br />
            </>,
          );
        }
      }
    }

    if (errorMessages.length === 0) {
      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } else {
      setModalRole('content');
      toast.error(<div>{errorMessages}</div>);
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/demand');
      }}
    />
  );

  const bottomBtns = (
    <Button
      className={isRtl ? 'mr-3' : 'ml-3'}
      btnRole="primary"
      form="demand-form"
      style={{ width: '185px' }}
      disabled={
        journalistList.filter((j) => Object.keys(j).length > 0).length === 0
      }
      type="submit"
    >
      {t('save-all-button')}
    </Button>
  );

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      successMessage={t('demand.add-success-message')}
      successButtons={successButtons}
      modalRole={modalRole}
      onAfterClose={resetAll}
      bottomButtons={bottomBtns}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      topRightButtons={modalCloseButton}
    >
      <form
        id="demand-form"
        className="demand-modal-content d-flex flex-column"
        onSubmit={submitAll(() => submit())}
      >
        <div className="row w-50 ml-auto mr-auto">
          <div className="d-flex align-items-center justify-content-end col-md-3">
            <label
              htmlFor="event"
              className="m-0 app-emphasis text-right required mb-2"
              style={{ minWidth: '160px' }}
            >
              {t('forms-labels.event')}
            </label>
          </div>
          <div className="form-group col-md-8">
            <Controller
              control={allControl}
              name="event"
              render={({ field: { value, onChange } }) => {
                return (
                  <AsyncPaginate
                    className="w-100 demand-page-select"
                    menuPosition="fixed"
                    placeholder={t('select-field-placeholder')}
                    required
                    isSearchable
                    id="event"
                    defaultOptions
                    cacheOptions
                    isOptionSelected={(option) => option.value.id === value?.id}
                    value={value ? dataToOption(value, isRtl) : undefined}
                    loadOptions={async (searchValue, loadedOptions) => {
                      searchValue = searchValue.trim().toLowerCase();
                      const offset = 50;
                      const page = getPage(loadedOptions.length, offset);
                      const { data } = await axios.get<
                        never,
                        AxiosResponse<Paginate<EventDto>>
                      >(`/event/${page}/${offset}/${searchValue}`);

                      const options = dataArrayToOptions(data.results, isRtl);

                      return {
                        options,
                        hasMore:
                          data.total > options.length + loadedOptions.length,
                      };
                    }}
                    onChange={(option: ValueType<OptionType, false>) =>
                      onChange(option?.value)
                    }
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="dropdown-divider" />
        <div className="d-flex justify-content-end">
          <Button
            btnRole="primary"
            className="add-journalist-demand pl-3 pr-3"
            onClick={() => setJournalistList([...journalistList, {}])}
          >
            <span className="icon" data-icon="&#x52;" />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>
              {t('demand.new-journalist-button')}
            </span>
          </Button>
        </div>
        <ListView className="journalists-demands-container mt-3 overflow-auto">
          {journalistList.map(({ journalist, sent }, index) => {
            const journalistController = (
              <Controller
                control={allControl}
                name={`journalists.${index}`}
                render={({ field: { onChange } }) => {
                  return (
                    <AsyncPaginate
                      className="w-100 demand-page-select"
                      menuPosition="fixed"
                      placeholder={t('select-field-placeholder')}
                      required
                      isSearchable
                      id="journalist"
                      defaultOptions
                      cacheOptions
                      value={
                        journalist
                          ? personDataToOption(journalist as OptionsData, isRtl)
                          : undefined
                      }
                      loadOptions={async (
                        inputValue: string,
                        loadedOptions: OptionsList<OptionType>,
                      ) => {
                        const { data } = await axios.get<
                          never,
                          AxiosResponse<GetJournalistDto[]>
                        >('journalist/filter/accepted');

                        const filteredList = data.filter(
                          ({
                            firstNameFr,
                            lastNameFr,
                            firstNameAr,
                            lastNameAr,
                          }) =>
                            firstNameFr
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            lastNameFr
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            firstNameAr
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase()) ||
                            lastNameAr
                              ?.toLowerCase()
                              .includes(inputValue.toLowerCase()),
                        );

                        const options = personDataArrayToOptions(
                          filteredList as OptionsData[],
                          isRtl,
                        );

                        const offset = loadedOptions.length;

                        const truncatedList = options.slice(
                          offset,
                          offset + 20,
                        );

                        if (
                          !journalistList[index].journalist &&
                          data.length > 0
                        ) {
                          journalistList[index].journalist = data[0];
                          setJournalistList(Array.from(journalistList));
                          onChange(data[0]);
                        }

                        return {
                          options: truncatedList,
                          hasMore: truncatedList.length < filteredList.length,
                        };
                      }}
                      onChange={async (option) => {
                        const { value } = option as OptionType;

                        const { data } = await axios.get<
                          never,
                          AxiosResponse<GetJournalistDto>
                        >(`journalist/${value}`);

                        if (data) {
                          journalistList[index].journalist = data;
                          setJournalistList(Array.from(journalistList));
                          onChange(data);
                        }
                      }}
                    />
                  );
                }}
              />
            );
            const endToggles = (
              <>
                {/* <Button
                  btnRole="primary"
                  btnSize="sm"
                  className="ml-2 pl-3 pr-3"
                  onClick={() => submit(index)}
                >
                  {t('save-button')}
                </Button> */}
                <Button
                  btnRole="secondary"
                  btnSize="sm"
                  accent="danger"
                  className="ml-2 pl-3 pr-3"
                  onClick={() => {
                    // removes journalist of index from journalistList
                    journalistList.splice(index, 1);

                    setJournalistList(Array.from(journalistList));
                  }}
                >
                  {t('delete-button')}
                </Button>
              </>
            );

            return (
              <ListTile
                key={index}
                leading={journalistController}
                enableImage={false}
                endToggles={endToggles}
              />
            );
          })}
        </ListView>
      </form>
    </Modal>
  );
}
