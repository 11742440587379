import {
  HTMLProps,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './IconInput.css';
import { countriesDials } from 'utils/utils';

export interface IconInputProps extends HTMLProps<HTMLInputElement> {
  icon?: React.ReactNode;
  iconPosition?: 'start' | 'end';
  direction?: 'ltr' | 'rtl';
}

export default function IconInput({
  icon,
  iconPosition = 'end',
  width = '100%',
  className = '',
  direction,
  ...rest
}: IconInputProps) {
  let content = (
    <input className={`form-control icon-input ${className}`} {...rest} />
  );

  if (icon) {
    let padClass = iconPosition === 'end' ? 'icon-end' : 'icon-start';
    content = (
      <div
        className={`input-group ${direction || ''} ${padClass}`}
        style={{ width }}
      >
        <div className="pad-svg">{icon}</div>
        {content}
      </div>
    );
  } else {
    content = (
      <div className="input-group" style={{ width }}>
        {content}
      </div>
    );
  }

  return content;
}

export function DialIconInput({
  icon: defaultIcon = <img alt="" src={"/flags/32x32/te.png"} />,
  defaultValue,
  className = '',
  iconPosition,
  width = '100%',
  onInput,
  ...rest
}: IconInputProps & { type?: never }) {
  const [icon, setIcon] = useState<ReactNode>(defaultIcon);
  const isMounted = useRef(false);
  const updateIcon = useCallback(
    async (phoneNumber: string) => {
      let alpha2: string | undefined;

      if (/^0[567][0-9]{0,8}$/.test(phoneNumber)) {
        alpha2 = 'dz'; // algerian number
      } else {
        alpha2 = countriesDials.find(({ dial }) =>
          dial ? new RegExp(`^\\${dial}[0-9]*`).test(phoneNumber) : false,
        )?.alpha2;
      }

      if (alpha2) {
        setIcon(<img alt="" src={`/flags/32x32/${alpha2}.png`} />);
      } else {
        setIcon(defaultIcon);
      }
    },
    [defaultIcon],
  );

  useEffect(() => {
    if (!isMounted.current) {
      if (defaultValue && typeof defaultValue === 'string') {
        updateIcon(defaultValue);
      }
      isMounted.current = true;
    }
  }, [defaultValue, updateIcon]);

  return (
    <div className="input-group icon-start phone-icon-input" style={{ width }}>
      <div className="pad-svg">{icon}</div>
      <input
        className={'form-control icon-input ' + className}
        type="tel"
        pattern={/^0[567][0-9]{8}$|[+][0-9]{7,15}/.source}
        onInput={(e) => {
          onInput?.(e);
          const phoneNumber = (e.target as HTMLInputElement).value;
          updateIcon(phoneNumber);
        }}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  );
}
