import assert from 'assert';
import { t } from 'i18next';
import { OptionTypeBase } from 'react-select';
import { BadgeDto } from '../../../shared/dtos/badge.dto';
import {
  FixedGetEventSortedDemandDto,
  GetDemandDto,
} from '../../../shared/dtos/demand.dto';
import { SpreadsheetHeader } from '../../../shared/types';
import { countries } from './countries';
import { FunctionalityDto } from '../../../shared/dtos/function.dto';

export function isDevelopment(): boolean {
  return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}

export interface StandAloneFormModalProps {
  formType?: 'add' | 'edit';
  onAfterSubmission?: Function;
  closePath?: string;
  onClose?: Function;
}

export interface StandAloneViewModalProps {
  onClose?: Function;
  onEditClick?: Function;
  onGenerateBadgeClick?: Function;
}

export interface PathLocationDto {
  from: { pathname: string };
}

export interface PageModalUrlParams {
  encryptedId?: string;
}

export interface DemandPageModalUrlParams {
  encryptedEventId?: string;
  encryptedOmId?: string;
}

export const countriesDials = countries
  .filter(({ dial }) => !!dial)
  .map(({ alpha2, dial }) => ({ alpha2, dial }));

export const suspensionOptions: OptionType[] = [
  { label: 'false', value: 'false' },
  { label: 'true', value: 'true' },
];

export const languagesOptions: OptionType[] = [
  { label: 'عربية', value: 'ar' },
  { label: 'Français', value: 'fr' },
];

export const categoryColorsOptions: OptionType[] = [
  { label: 'white', value: 'white' },
  { label: 'black', value: 'black' },
];

export const statusOptions: OptionType[] = [
  { label: 'pending', value: 'pending' },
  { label: 'accepted', value: 'accepted' },
  { label: 'rejected', value: 'rejected' },
];

/**
 * separate big array to number of chunks of given size.
 *
 * given ex:
 * ```ts
 *  call: chunk(['a','b','c','d','e'], 2)
 *  result: [['a','b'], ['c','d'], ['e']].
 * ```
 *
 * @param array big array
 * @param size size of each chunk
 */
export function chunk(array: any[], size: number) {
  assert(
    Number.isSafeInteger(size) && Number.parseInt(`${size}`) > 0,
    `chunk function: size: '${size}' should be positive non zero integer.`,
  );

  const results = [];

  for (let i = 0; i < -Math.floor(-array.length / size); ++i) {
    results.push(array.slice(i * size, Math.min((i + 1) * size, array.length)));
  }

  return results;
}

/**
 * @deprecated
 */
export function hashCode(anything: any) {
  const str = anything.toString();
  var hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (var i = 0; i < str.length; i++) {
    var char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

export interface OptionsData {
  id?: number;
  nameFr?: string;
  nameAr?: string;
  [x: string]: any;
}

export interface PersonOptionsData {
  id?: number;
  firstNameAr?: string;
  firstNameFr?: string;
  lastNameAr?: string;
  lastNameFr?: string;
}

export interface DemandBadgeTile {
  demand?: GetDemandDto;
  badge?: BadgeDto;
}

export function dataToOption(
  { id, nameFr, nameAr, ...rest }: OptionsData,
  chooseAr?: boolean,
  subOptionsArg?: string,
): OptionType | GroupType {
  if (subOptionsArg && rest[subOptionsArg]) {
    return {
      label: `${chooseAr ? nameAr : nameFr}`,
      options: Array.isArray(rest[subOptionsArg])
        ? (dataArrayToOptions(
            rest[subOptionsArg] as OptionsData[],
            chooseAr,
          ) as OptionType[])
        : [
            dataToOption(
              rest[subOptionsArg] as OptionsData,
              chooseAr,
            ) as OptionType,
          ],
    };
  }
  return {
    label: `${chooseAr ? nameAr : nameFr}`,
    value: { id, nameFr, nameAr, ...rest },
  };
}

export function dataArrayToOptions(
  data: OptionsData[],
  chooseAr?: boolean,
  subOptionsArg?: string,
): OptionType[] | GroupType[] {
  return data.map((value) => dataToOption(value, chooseAr, subOptionsArg)) as
    | OptionType[]
    | GroupType[];
}

export interface OptionType extends OptionTypeBase {
  label: string;
  value: any;
}
export interface GroupType {
  label: string;
  options: OptionType[] | GroupType[];
}

export function fixMapMarkerPositionOnFullscreenClick() {
  const mapContainer = document.querySelector('.map') as HTMLDivElement | null;

  if (!mapContainer) return;

  const isMapFullscreen = mapContainer.contains(document.fullscreenElement);

  if (isMapFullscreen) {
    mapContainer.style.position = 'fixed';
    mapContainer.style.top = '0px';
    mapContainer.style.left = '0px';
  } else {
    mapContainer.style.position = 'relative';
    mapContainer.style.top = 'unset';
    mapContainer.style.left = 'unset';
  }
}

export function fixMapInfoWindowClickPropagation() {
  let iterationLimit = 3;
  const interval = setInterval(() => {
    if (iterationLimit === 0) {
      clearInterval(interval);
    } else {
      --iterationLimit;
    }

    const infoWindow = document.querySelector(
      '.gm-style-iw.gm-style-iw-c',
    ) as HTMLDivElement;

    if (infoWindow) {
      infoWindow.onclick = (e) => e.stopPropagation();
      clearInterval(interval);
    }
  }, 1000);
}

export function getYesterday(date: Date = new Date()) {
  return new Date(date.getTime() - 86400000);
}

export function getTommorrow(date: Date = new Date()) {
  return new Date(date.getTime() + 86400000);
}

export function alpha2ToOptionType(alpha2: string, chooseAr?: boolean) {
  const country = countries.find(({ alpha2: alpha }) => alpha === alpha2);

  return country
    ? {
        label: chooseAr ? country.nameAr : country.nameFr,
        value: country.alpha2,
      }
    : undefined;
}

export function personToFullname(firstName = '', lastName = '') {
  return `${lastName} ${firstName}`;
}

export function personDataToOption(
  { id, firstNameFr, lastNameFr, firstNameAr, lastNameAr }: PersonOptionsData,
  chooseAr?: boolean,
): OptionType {
  return {
    label: chooseAr
      ? personToFullname(firstNameAr, lastNameAr)
      : personToFullname(firstNameFr, lastNameFr),
    value: `${id}`,
  };
}

export function personDataArrayToOptions(
  data: PersonOptionsData[],
  chooseAr?: boolean,
): OptionType[] {
  return data.map((person) => personDataToOption(person, chooseAr));
}

/**
 *
 * @param phoneNumber can be international phone number or local algerian.
 * @param reverse if true, look for +213663xxxxxx and change it to 0663xxxxxx, otherwise do the opposite, default to false.
 * @param addSpaces whether to change numbers like 0663xxxxxx to 06 63 xx xx xx, default to false.
 * @returns formatted phone number.
 */
export function formatDzPhoneNumber(
  phoneNumber: string,
  reverse = false,
  addSpaces = false,
) {
  if (reverse) {
    if (/^\+213[567][0-9]{8}$/.test(phoneNumber)) {
      // it means look for +213663xxxxxx and change it to 0663xxxxxx.
      phoneNumber = '0' + phoneNumber.substr(4);

      if (addSpaces) {
        phoneNumber = phoneNumber
          .replace(/[\d]{2}/g, (v) => `${v} `)
          .slice(0, -1);
      }
    }
  } else if (/^0[567][0-9]{8}$/.test(phoneNumber)) {
    // if test is true, than it is a local algerian phone number.
    phoneNumber = '+213' + phoneNumber.substring(1);
  }

  return phoneNumber;
}

export function isColor(color: string) {
  var s = new Option().style;
  s.color = color;
  return s.color === color;
}

export function normalizeToastMessage(text: string) {
  if (/Key \(email\)=\(.*\).already exists\./.test(text)) {
    const emailMatch = text.match(/(?<=\(email\)=\().*(?=\))/gim);
    if (emailMatch) {
      return `L'e-mail ${emailMatch[0]} déjà existé.`;
    }
  } else if (/Key \("phoneNumber"\)=\(.*\).already exists\./.test(text)) {
    const numberMatch = text.match(/(?<=\("phoneNumber"\)=\().*(?=\))/gim);
    if (numberMatch) {
      return `Numéro de téléphone ${formatDzPhoneNumber(
        numberMatch[0],
        true,
      )} déjà existé.`;
    }
  } else if (
    /Key \("eventId", "journalistId"\)=\(.*\).already exists\./.test(text)
  ) {
    return 'Journalist déjà existé pour cet événement.';
  }
  return text;
}

export function objectIsEmpty(obj: object = {}) {
  for (const _ in obj) {
    return false;
  }

  return true;
}

export function demandsToTiles(
  body: FixedGetEventSortedDemandDto,
): DemandBadgeTile[] {
  return (
    body.om?.demands?.map((demand) => {
      return {
        demand,
        badge:
          demand.badge ||
          ({
            demandId: demand.demandId,
            eventId: body.id,
            ownerId: demand.journalist!.id,
            ownerType: 'journalist' as any,
            zones: [] as any,
          } as BadgeDto),
      };
    }) || []
  );
}

export function getContrastYIQ(hexcolor: string): 'black' | 'white' {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

export function getPage(numberOfItems: number, chunkSize: number) {
  var quotient = Math.floor(numberOfItems / chunkSize);
  var remainder = numberOfItems % chunkSize;

  return quotient + (remainder !== 0 ? 1 : 0);
}

export function getSpreadsheetHeader(): SpreadsheetHeader {
  return {
    countryDescription: t('country-description'),
    state: t('states.algiers'),
    establishment: t('establishments.faf'),
    cell: t('cells.journalists'),
    office: t('offices.journalists'),
  };
}

export const demoFunctionality: FunctionalityDto = {
  nameFr: 'PHOTOGRAPHER',
  nameAr: 'مصور فوتوغرافي',
  backgroundColor: '#349937',
  categoryId: 4,
  category: { nameFr: 'JOURNALISTE', nameAr: 'صحفي', color: '#ffc231' },
};

export const toBase64 = (file: File) => {
  return new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string | undefined);
    reader.onerror = (error) => reject(error);
  });
};
