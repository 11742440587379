import './LoadingIndicator.css';
import { ReactComponent as LoadingPulseSvg } from 'assets/loading-pulse.svg';
import { ReactComponent as CircularLoadingSvg } from 'assets/circular-loading.svg';
import { CSSProperties, HTMLProps } from 'react';

export interface LoadingIndicatorProps extends HTMLProps<HTMLDivElement> {
  fullscreen?: boolean;
  svgStyle?: CSSProperties;
}

export function LoadingIndicator({
  fullscreen = false,
  className = '',
  svgStyle,
  ...rest
}: LoadingIndicatorProps) {
  className = `${className} loading-indicator ${
    fullscreen ? 'fullscreen' : ''
  }`;

  return <div className={className} {...rest} />;
}

export function PulseIndicator({ svgStyle, ...rest }: LoadingIndicatorProps) {
  return (
    <LoadingIndicator {...rest}>
      <LoadingPulseSvg style={svgStyle} />
    </LoadingIndicator>
  );
}

export function CircularIndicator({
  svgStyle,
  ...rest
}: LoadingIndicatorProps) {
  return (
    <LoadingIndicator {...rest}>
      <CircularLoadingSvg style={svgStyle} />
    </LoadingIndicator>
  );
}
