import { HTMLProps, ReactNode } from 'react';
import './EmptyPageHolder.css';

export default function EmptyPageHolder({
  className = '',
  emptyContent,
  ...rest
}: HTMLProps<HTMLDivElement> & { emptyContent?: ReactNode }) {
  return (
    <div className={`empty-page-holder ${className}`} {...rest}>
      {emptyContent}
    </div>
  );
}
