import { createContext, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useTrans } from 'lib/translation';

type TransContextType = ReturnType<typeof useTrans>;

const transContext = createContext<TransContextType>(undefined!);

interface AutoTranslationProps {
  children: React.ReactNode;
}

export default function AutoTranslation({ children }: AutoTranslationProps) {
  const trans = useTrans();

  return (
    <BrowserRouter>
      <transContext.Provider value={trans}>{children}</transContext.Provider>
    </BrowserRouter>
  );
}

export function useAutoTrans() {
  return useContext(transContext);
}
