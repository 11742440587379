export function ZonesInfo() {
  return (
    <div id="g234">
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">Field of play /Terrain</span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">
          Competition Areas / Zones de compétition
        </span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">
          Public stands /Tribunes grand public
        </span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">
          Operation areas / Zones opérationnelles
        </span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">VIP area / Zone VIP</span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">Media tribune /Tribune Média</span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">Media centre/ Centre Média</span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">Broadcast area / Zone TV</span>
      </div>
      <div>
        <span className="zone">ZONE</span>
        <span className="zone-spacer" />
        <span className="zone-detail">Hospitality area / Hospitalité</span>
      </div>
    </div>
  );
}
