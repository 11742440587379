import { ReactComponent as ArrowLeftSvg } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as PhoneSvg } from 'assets/phone.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import Button, { FileButton, ModalCloseButton } from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import DatePicker from 'components/date-picker/DatePicker';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput, { DialIconInput } from 'components/icon-input/IconInput';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import Picture from 'components/picture/Picture';
import { useAuth } from 'components/routes/AuthRouter';
import Select, { AsyncPaginate } from 'components/select/Select';
import localAr from 'date-fns/locale/ar-DZ';
import localFr from 'date-fns/locale/fr';
import { useEncryption } from 'lib/encryption';
import moment from 'moment';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  OptionType,
  PageModalUrlParams,
  PathLocationDto,
  dataArrayToOptions,
  dataToOption,
  formatDzPhoneNumber,
  getPage,
  normalizeToastMessage,
  suspensionOptions,
} from 'utils/utils';
import { OmTypeDto } from '../../../../shared/dtos/om-type.dto';
import { OmDto, OmUsernameDto } from '../../../../shared/dtos/om.dto';
import { PhoneBookDto } from '../../../../shared/dtos/phone-book.dto';
import {
  ChangePasswordWithConfirmationDto,
  Paginate,
  SearchPaginate,
} from '../../../../shared/types';
import './Om.css';

export default function Om() {
  const [data, setData] = useState<OmDto[]>();
  const history = useHistory<PathLocationDto>();
  const { encrypt, decrypt } = useEncryption();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [perPage, setPerPage] = useState<number>(24);
  const [total, setTotal] = useState<number>(0);
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<never, AxiosResponse<SearchPaginate<OmDto>>>(
        `/om/${newPage - 1}/${newPerPage}/${searchValue}`,
      );
      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page container-fluid ${isRtl ? 'rtl' : 'ltr'}`}>
      <Route
        path="/om/view/:encryptedId"
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedOm = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedOm) {
            return null;
          }

          return (
            <ViewOm
              onQuickUpdate={() => updateCards(1, perPage)}
              enableEditPass
              enableEditUsername
              {...selectedOm}
            />
          );
        }}
      />
      <Route path="/om/add">
        <FormOm onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <Route
        path={'/om/edit/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedOm = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedOm) {
            return null;
          }

          return (
            <FormOm
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedOm}
            />
          );
        }}
      />
      <Route
        path={'/om/edit-pass/:encryptedId/:encryptedUserId'}
        render={({
          match: {
            params: { encryptedId, encryptedUserId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const decryptedUserId = parseInt(decrypt(`${encryptedUserId}`));
          const selectedOm = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedOm) {
            return null;
          }

          return (
            <FormPassOm
              userId={decryptedUserId}
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedOm}
            />
          );
        }}
      />
      <Route
        path={'/om/edit-username/:encryptedId/:encryptedUserId'}
        render={({
          match: {
            params: { encryptedId, encryptedUserId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const decryptedUserId = parseInt(decrypt(`${encryptedUserId}`));
          const selectedOm = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedOm) {
            return null;
          }

          return (
            <FormUsernameOm
              userId={decryptedUserId}
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedOm}
            />
          );
        }}
      />
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: OmLabelDto = {
                sheet: t('sidebar.om'),
                title: t('pages-titles.om'),
                number: t('forms-labels.number'),
                nameFr: t('forms-labels.om'),
                nameAr: t('forms-labels.om'),
                addressAr: t('forms-labels.address'),
                addressFr: t('forms-labels.address'),
                email: t('forms-labels.email'),
                suspended: {
                  name: t('forms-labels.participation'),
                  true: t('suspension-status.true'),
                  false: t('suspension-status.false'),
                },
                creationDate: t('forms-labels.creation-date'),
                type: {
                  nameAr: t('forms-labels.type'),
                  nameFr: t('forms-labels.type'),
                },
                phones: {
                  mobile: t('forms-labels.phone-number'),
                  mobile2: t('forms-labels.phone-number2'),
                  fax: t('forms-labels.fax-number'),
                },
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'om/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          <Button
            btnRole="primary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={() => history.push('/om/add')}
          >
            {t('om.add-new-button')}
          </Button>
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <CardsContainer
            direction={isRtl ? 'rtl' : 'ltr'}
            cardsDirection="horizontal"
          >
            {data.map(
              ({ id, nameFr, nameAr, logo, type, suspended }, index) => {
                const viewDetailsBtn = (
                  <Button
                    className={`slidable ${
                      isRtl ? 'slidable-left' : 'slidable-right'
                    } app-small-text d-flex`}
                    onClick={() => {
                      history.push(`/om/view/${encrypt(id?.toString() || '')}`);
                    }}
                  >
                    <span className="view-details-button-text">
                      {t('details-button')}
                    </span>
                    <ArrowRightSvg
                      className={`card-link-icon svg-icon ${
                        isRtl ? 'rtl' : 'ltr'
                      }`}
                    />
                  </Button>
                );
                const card = (
                  <Card
                    key={index}
                    imageName={logo}
                    cardTitle={isRtl ? nameAr : nameFr}
                    subtitle={isRtl ? type?.nameAr : type?.nameFr}
                    commentRole={suspended ? 'danger' : 'info'}
                    comment={t(`suspension-status.${suspended || false}`)}
                    detailsToggle={viewDetailsBtn}
                  />
                );
                return card;
              },
            )}
          </CardsContainer>
          <Paginator
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

type ViewOmTabs = 'info' | 'contact';

export function ViewOm({
  id,
  logo,
  nameFr,
  nameAr,
  type,
  email,
  addressFr,
  addressAr,
  creationDate,
  suspended,
  agreement,
  phones,
  onClose,
  enableEditPass,
  enableEditUsername,
  onEditClick,
  onQuickUpdate,
}: OmDto & {
  onClose?: Function;
  enableEditPass?: boolean;
  enableEditUsername?: boolean;
  onEditClick?: Function;
  onQuickUpdate?: Function;
}) {
  const { encrypt } = useEncryption();
  const [tab, setTab] = useState<ViewOmTabs>('info');
  const [username, setUsername] = useState('...');
  const [userId, setUserId] = useState(-1);
  const [hasChanged, setHasChanged] = useState(false);
  const auth = useAuth();
  const history = useHistory<PathLocationDto>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const { encryptedId } = useParams<PageModalUrlParams>();
  const phoneNumber = phones?.find(({ type }) => type === 'mobile')
    ?.phoneNumber;
  const faxNumber = phones?.find(({ type }) => type === 'fax')?.phoneNumber;

  useEffect(() => {
    setUsername('...');
    setUserId(-1);
    axios
      .get<OmUsernameDto>(`om/username/${id}`)
      .then(({ data }) => {
        setUserId(data.id ?? -1);
        setUsername(data.username ?? '...');
      })
      .catch((error) => {
        console.error(error);
        if (axios.isAxiosError(error)) {
          const messages =
            error.response?.data?.message || error.response?.statusText;
          if (Array.isArray(messages)) {
            toast.error(
              messages.reduce(
                (prevMsg, msg) => (
                  <div>
                    {prevMsg}- {msg}.
                  </div>
                ),
                '',
              ),
            );
          } else {
            console.error(error);

            toast.error(messages?.toString());
          }
        } else {
          toast.error((error as any).message);
        }
      });
  }, [id]);

  const bottomBtns = (
    <>
      {enableEditUsername && (
        <Button
          btnRole="secondary"
          className="ml-4"
          onClick={
            username !== '...' && userId !== -1
              ? () => {
                  history.push(
                    `/om/edit-username/${encryptedId}/${encrypt(
                      userId.toString(),
                    )}`,
                  );

                  if (hasChanged) {
                    onQuickUpdate?.();
                  }
                }
              : undefined
          }
        >
          {t('modify-username-button')}
        </Button>
      )}
      {enableEditPass && (
        <Button
          btnRole="secondary"
          accent="danger"
          className="ml-4"
          onClick={
            username !== '...' && userId !== -1
              ? () => {
                  history.push(
                    `/om/edit-pass/${encryptedId}/${encrypt(
                      userId.toString(),
                    )}`,
                  );

                  if (hasChanged) {
                    onQuickUpdate?.();
                  }
                }
              : undefined
          }
        >
          {t('modify-pass-button')}
        </Button>
      )}
      <Button
        btnRole="primary"
        className="ml-4"
        onClick={
          onEditClick
            ? () => {
                onEditClick();

                if (hasChanged) {
                  onQuickUpdate?.();
                }
              }
            : () => {
                history.push(`/om/edit/${encryptedId}`);

                if (hasChanged) {
                  onQuickUpdate?.();
                }
              }
        }
      >
        {t('modify-info-button')}
      </Button>
    </>
  );

  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/om');
        }

        if (hasChanged) {
          onQuickUpdate?.();
        }
      }}
    />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      bottomButtons={bottomBtns}
      onRequestClose={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/om');
        }

        if (hasChanged) {
          onQuickUpdate?.();
        }
      }}
      onAfterClose={() => setTab('info')}
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div
        className={`om-info collapsible ${
          tab === 'info' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="om-info-left">
          <Picture defaultImageName={logo} />
          <div className="app-title mt-3">{isRtl ? nameAr : nameFr}</div>
          <div className="app-text mt-3">
            {isRtl ? type?.nameAr : type?.nameFr}
          </div>
          <Button
            className={`contact-info mt-2 slidable ${
              isRtl ? 'slidable-left' : 'slidable-right'
            } app-small-text`}
            onClick={() => setTab('contact')}
          >
            <PhoneSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('contact-info')}</span>
          </Button>
        </div>
        <div
          className={`om-info-right d-flex flex-column justify-content-center ${
            isRtl ? 'mr-5' : 'ml-5'
          }`}
        >
          <div className="general-info-title app-title mb-3">
            {t('general-info')}
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.last-name')}
            </div>
            <div className="col-7 app-text">{isRtl ? nameAr : nameFr}</div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.username')}
            </div>
            <div className="col-7 app-text">{username}</div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">{t('forms-labels.type')}</div>
            <div className="col-7 app-text">
              {(isRtl ? type?.nameAr : type?.nameFr) || t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.creation-date')}
            </div>
            <div className="col-7 app-text">
              {(creationDate
                ? new Date(creationDate)
                : null
              )?.toLocaleDateString('fr-DZ')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.address')}
            </div>
            <div className="col-7 app-text">
              {(isRtl ? addressAr : addressFr) || t('non-specified')}
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.agreement')}
            </div>
            <div className="col-7 app-text">
              <FileButton id="id-card" defaultFileName={agreement} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.participation')}
            </div>
            <div className={`col-7 app-text ${isRtl ? 'pl-4' : 'pr-4'}`}>
              {auth.user?.userType === 'faf' ? (
                <Select
                  className="w-100"
                  menuPosition="fixed"
                  value={{
                    label: t(`suspension-status.${suspended}`),
                    value: `${suspended}`,
                  }}
                  options={suspensionOptions.map(({ label, value }) => ({
                    label: t(`suspension-status.${label}`),
                    value,
                  }))}
                  onChange={async (option) => {
                    const { value } = option as OptionType;
                    try {
                      await axios.put(`/om/${id}/suspension/${value}`);

                      setHasChanged(true);
                    } catch (error: any) {
                      console.error(error);
                      if (axios.isAxiosError(error)) {
                        const errorData = error.response?.data;
                        const messages =
                          errorData?.message || errorData?.messages;

                        if (messages) {
                          if (Array.isArray(messages)) {
                            toast.error(
                              messages.reduce(
                                (prevMsg: string, msg: string) => (
                                  <div>
                                    {prevMsg}-{' '}
                                    {normalizeToastMessage(msg || '')}.
                                  </div>
                                ),
                                '',
                              ),
                            );
                          } else {
                            toast.error(
                              normalizeToastMessage(messages?.toString() || ''),
                            );
                          }
                        } else {
                          toast.error(
                            normalizeToastMessage(messages?.toString()) || '',
                          );
                        }
                      } else {
                        toast.error(normalizeToastMessage(error.message || ''));
                      }
                    }
                  }}
                />
              ) : (
                <div
                  className={
                    suspended === true
                      ? 'danger-txt'
                      : suspended === false
                      ? 'text-info'
                      : ''
                  }
                >
                  {suspended !== undefined
                    ? t(`suspension-status.${suspended}`)
                    : t('non-specified')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`om-contact collapsible mb-5 ${
          tab === 'contact' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="contact-info-title app-title">
            {t('contact-info')}
          </div>
          <Button
            className={`info-back mt-2 slidable ${
              isRtl ? 'slidable-right' : 'slidable-left'
            } app-small-text`}
            onClick={() => setTab('info')}
          >
            <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('return')}</span>
          </Button>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">{t('forms-labels.email')}</div>
          <div className="col-7 app-text">{email}</div>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">
            {t('forms-labels.phone-number')}
          </div>
          <div className="col-7 ltr-text app-text">
            {formatDzPhoneNumber(phoneNumber || t('non-specified'), true, true)}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">
            {t('forms-labels.fax-number')}
          </div>
          <div className="col-7 ltr-text app-text">
            {formatDzPhoneNumber(faxNumber || t('non-specified'), true, true)}
          </div>
        </div>
      </div>
    </Modal>
  );
}

type FormOmTabs = 'basics' | 'details&contact' | 'files&access';

export interface FormOmProps extends OmDto {
  formType?: 'add' | 'edit';
  onAfterSubmission?: Function;
  closePath?: string;
  onClose?: Function;
}

export function FormOm({
  id,
  nameFr,
  nameAr,
  email,
  addressFr,
  addressAr,
  agreement,
  logo,
  creationDate,
  type,
  phones = [],
  username,
  formType: defaultFormType,
  closePath,
  onAfterSubmission: afterSubmission,
  onClose,
}: FormOmProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [selectedPhone, setSelectedPhone] = useState(
    phones?.find(({ type }) => type === 'mobile'),
  );
  const [selectedFax, setSelectedFax] = useState(
    phones?.find(({ type }) => type === 'fax'),
  );
  const [tab, setTab] = useState<FormOmTabs>('basics');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/om');
  };
  const {
    control: basicsControl,
    handleSubmit: submitBasics,
    reset: resetBasics,
    getValues: getBasicsValues,
  } = useForm({
    defaultValues: {
      nameFr,
      nameAr,
      addressFr,
      addressAr,
    },
  });
  const {
    control: detailsControl,
    handleSubmit: submitDetails,
    reset: resetDetails,
    getValues: getDetailsValues,
  } = useForm({
    defaultValues: {
      email,
      creationDate: new Date(
        creationDate ??
          moment.utc(new Date().toISOString().substring(0, 10)).format(),
      ),
      type,
      phones,
    },
  });
  const {
    control: accessControl,
    handleSubmit: submitAccess,
    reset: resetAccess,
    getValues: getAccessValues,
  } = useForm({
    defaultValues: {
      agreement,
      logo,
      username: '',
      password: '',
    },
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const formType =
    defaultFormType || pathname.startsWith('/om/edit/') ? 'edit' : 'add';
  const formMethod = formType === 'add' ? 'post' : 'put';
  const formAction = formType === 'add' ? '/om/add' : `/om/edit/${id}`;
  const submit = async () => {
    if (tab === 'basics') {
      setTab('details&contact');
      return;
    }
    if (tab === 'details&contact') {
      setTab('files&access');
      return;
    }

    setModalRole('processing');

    const data = {
      ...getBasicsValues(),
      ...getDetailsValues(),
      ...getAccessValues(),
    };

    data.phones = [selectedPhone!, selectedFax!];

    data.phones = data.phones?.filter((phone) => phone);

    // normalize phone number
    data.phones?.forEach(
      (phone) =>
        (phone.phoneNumber = formatDzPhoneNumber(phone?.phoneNumber || '')),
    );

    try {
      await axios[formMethod](formAction || pathname, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error) {
      setModalRole('content');
      console.error(error);
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          console.error(error);

          toast.error(messages?.toString());
        }
      } else {
        toast.error((error as any).message);
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/om');
      }}
    />
  );
  const formTitle = t(`modals-titles.${tab}`);

  let bottomBtns: ReactNode;
  if (tab === 'basics') {
    bottomBtns = (
      <Button
        btnRole="primary"
        type="submit"
        form={'basics' as FormOmTabs}
        style={{ width: '145px' }}
      >
        <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
        <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
      </Button>
    );
  } else if (tab === 'details&contact') {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('basics')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form={'details&contact' as FormOmTabs}
          style={{ width: '145px' }}
        >
          <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
          <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
        </Button>
      </>
    );
  } else {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('details&contact')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form={'files&access' as FormOmTabs}
          style={{ width: '145px' }}
        >
          {t('save-button')}
        </Button>
      </>
    );
  }

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      successMessage={
        formType === 'add'
          ? t('om.add-success-message')
          : t('om.modify-success-message')
      }
      successButtons={successButtons}
      pointsOptions={{
        count: 3,
        selectedPoint: [
          tab === 'basics',
          tab === 'details&contact',
          tab === 'files&access',
        ],
      }}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={() => {
        resetBasics();
        resetDetails();
        resetAccess();
      }}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="form-title app-heading-3 mb-3">{formTitle}</div>
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form
            id={'basics' as FormOmTabs}
            className={`collapsible ${
              tab === 'basics' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitBasics(submit)}
          >
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="name-fr">
                  {t('forms-labels.name')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="nameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Nom en français"
                      required
                      id="name-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={basicsControl}
                  name="nameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الإسم بالعربية"
                      required
                      id="name-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  htmlFor="address-fr"
                  style={{ minWidth: '130px' }}
                >
                  {t('forms-labels.address')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="addressFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="L'adresse géographique"
                      required
                      id="address-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group col-md-5 rtl">
                <Controller
                  control={basicsControl}
                  name="addressAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الموقع الجغرافي"
                      required
                      id="address-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <form
            id={'details&contact' as FormOmTabs}
            className={`collapsible ${
              tab === 'details&contact' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitDetails(submit)}
          >
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="om-type"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '60px' }}
                >
                  {t('forms-labels.type')}
                </label>
              </div>
              <div className="form-group col-md-10">
                <div className="input-group">
                  <Controller
                    control={detailsControl}
                    name="type"
                    render={({ field: { value, onChange } }) => (
                      <AsyncPaginate
                        className="w-100"
                        menuPosition="fixed"
                        placeholder={t('search-field-placeholder')}
                        isSearchable
                        required
                        id="om-type"
                        isOptionSelected={(option) =>
                          option.value.id === value?.id
                        }
                        defaultOptions
                        cacheOptions
                        value={value ? dataToOption(value, isRtl) : undefined}
                        loadOptions={async (searchValue, loadedOptions) => {
                          searchValue = searchValue.trim().toLowerCase();
                          const offset = 20;
                          const page = getPage(loadedOptions.length, offset);
                          const { data } = await axios.get<
                            never,
                            AxiosResponse<Paginate<OmTypeDto>>
                          >(`/om/type/${page}/${offset}/${searchValue}`);
                          const options = dataArrayToOptions(
                            data.results,
                            isRtl,
                          );

                          return {
                            options,
                            hasMore:
                              data.total >
                              options.length + loadedOptions.length,
                          };
                        }}
                        onChange={(option) => onChange(option.value)}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="creation-date"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '150px' }}
                >
                  {t('forms-labels.creation-date')}
                </label>
              </div>
              <div className="form-group col-md-6">
                <Controller
                  control={detailsControl}
                  name="creationDate"
                  render={({ field }) => (
                    <DatePicker
                      id="creation-date"
                      required
                      locale={isRtl ? localAr : localFr}
                      placeholderText={t('date-field-placeholder')}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                      calendarStartDay={6}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                  htmlFor="email"
                >
                  {t('forms-labels.email')}
                </label>
              </div>
              <div className="form-group ltr col-md-10">
                <Controller
                  control={detailsControl}
                  name="email"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      required
                      id="email"
                      type="email"
                      placeholder="example@email.com"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                  htmlFor="telephone"
                >
                  {t('forms-labels.phone-number')}
                </label>
              </div>
              <div className="form-group ltr col-md-10">
                <Controller
                  control={detailsControl}
                  name="phones.0"
                  render={({ field: { onChange } }) => (
                    <DialIconInput
                      required
                      id="telephone"
                      defaultValue={formatDzPhoneNumber(
                        selectedPhone?.phoneNumber || '',
                        true,
                      )}
                      placeholder="06xxxxxxxx/+213xxxxxxx"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newNumber = event.target.value;

                        if (!selectedPhone) {
                          setSelectedPhone({
                            phoneNumber: newNumber,
                            ownerType: 'om',
                            type: 'mobile',
                          } as PhoneBookDto);
                        } else {
                          selectedPhone.phoneNumber = newNumber;
                          setSelectedPhone(selectedPhone);
                        }

                        onChange(selectedPhone);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis" htmlFor="fax">
                  {t('forms-labels.fax-number')}
                </label>
              </div>
              <div className="form-group ltr col-md-10">
                <Controller
                  control={detailsControl}
                  name="phones.1"
                  render={({ field: { onChange } }) => (
                    <IconInput
                      icon={<img alt="" src={'/flags/32x32/dz.png'} />}
                      iconPosition="start"
                      id="fax"
                      defaultValue={selectedFax?.phoneNumber}
                      pattern={/0[0-9]{8}/.source}
                      placeholder="029xxxxxx"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newNumber = event.target.value;

                        if (!selectedFax) {
                          setSelectedFax({
                            phoneNumber: newNumber,
                            ownerType: 'om',
                            type: 'fax',
                          } as PhoneBookDto);
                        } else {
                          selectedFax.phoneNumber = newNumber;
                          setSelectedFax(selectedFax);
                        }

                        onChange(selectedFax);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <form
            id={'files&access' as FormOmTabs}
            className={`d-flex flex-column collapsible ${
              tab === 'files&access' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitAccess(submit)}
          >
            <div className="d-flex">
              <div className="files-access-left">
                <Controller
                  control={accessControl}
                  name="logo"
                  render={({ field: { value, onChange } }) => (
                    <Picture
                      required
                      enableUpload
                      defaultImageName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
              {formType === 'add' ? (
                <div className="files-access-right">
                  <div className="row">
                    <div
                      className={`${
                        isRtl ? 'mr-auto' : 'ml-auto'
                      } form-group col-md-4 d-flex align-items-center`}
                    >
                      <label
                        className={`m-0 app-emphasis required ${
                          isRtl ? 'rtl reversed' : 'ltr reversed'
                        }`}
                        htmlFor="username"
                        style={{ minWidth: '160px' }}
                      >
                        {t('forms-labels.username')}
                      </label>
                    </div>
                    <div className="form-group col-md-6">
                      <Controller
                        control={accessControl}
                        name="username"
                        render={({ field: { value, onChange } }) => (
                          <IconInput
                            value={value}
                            required
                            id="username"
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={`${
                        isRtl ? 'mr-auto' : 'ml-auto'
                      } form-group col-md-4 d-flex align-items-center`}
                    >
                      <label
                        className={`m-0 app-emphasis required ${
                          isRtl ? 'rtl reversed' : 'ltr reversed'
                        }`}
                        htmlFor="password"
                        style={{ minWidth: '160px' }}
                      >
                        {t('forms-labels.password')}
                      </label>
                    </div>
                    <div className="form-group col-md-6">
                      <Controller
                        control={accessControl}
                        name="password"
                        render={({ field: { value, onChange } }) => (
                          <IconInput
                            value={value}
                            required
                            id="password"
                            type="text"
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : undefined}
            </div>
            <div className="row mt-5">
              <div className="form-group col-md-3 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="agreement"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '120px' }}
                >
                  {t('forms-labels.agreement')}
                </label>
              </div>
              <div className="form-group col-md-9">
                <Controller
                  control={accessControl}
                  name="agreement"
                  render={({ field: { value, onChange } }) => (
                    <FileButton
                      id="agreement"
                      required
                      enableUpload
                      defaultFileName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export function FormPassOm({
  id,
  logo,
  nameFr,
  nameAr,
  userId,
  closePath,
  onAfterSubmission: afterSubmission,
  onClose,
}: FormOmProps & { userId: number }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/om');
  };
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: { newPassword: '', confirmNewPassword: '' },
  });
  const history = useHistory();
  const formMethod = 'patch';
  const formAction = `/users/password/${userId}/update`;
  const [passwordIdentical, setPasswordIdentical] = useState<
    boolean | undefined
  >();
  const checkConfirmation = ({
    newPassword,
    confirmNewPassword,
  }: ChangePasswordWithConfirmationDto) => {
    if (
      newPassword!.trim().length === 0 ||
      confirmNewPassword!.trim().length === 0
    ) {
      setPasswordIdentical(undefined);
    } else {
      setPasswordIdentical(newPassword!.trim() === confirmNewPassword!.trim());
    }
  };
  const submit = async () => {
    if (passwordIdentical !== true) return;

    setModalRole('processing');
    const data = getValues();
    try {
      await axios[formMethod](formAction, { newPassword: data.newPassword });

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error) {
      setModalRole('content');
      console.error(error);
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          console.error(error);

          toast.error(messages?.toString());
        }
      } else {
        toast.error((error as any).message);
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/om');
      }}
    />
  );
  const formTitle = t(`settings.headers.change-password`);

  let bottomBtns = (
    <Button
      className={isRtl ? 'mr-3' : 'ml-3'}
      btnRole="primary"
      type="submit"
      form="edit-pass"
      disabled={passwordIdentical !== true}
      style={{ width: '145px' }}
    >
      {t('save-button')}
    </Button>
  );

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      successMessage={t('om.modify-success-message')}
      successButtons={successButtons}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={reset}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="form-title app-heading-3 mb-3">{formTitle}</div>
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form
            id="edit-pass"
            className="d-flex flex-column collapsible"
            onSubmit={handleSubmit(submit)}
          >
            <div className="d-flex">
              <div className="files-access-left">
                <Picture defaultImageName={logo} />
              </div>
              <div className="files-access-right">
                <div className="app-title my-3 text-center">
                  {isRtl ? nameAr : nameFr}
                </div>
                <div className="row">
                  <div
                    className={`${
                      isRtl ? 'mr-auto' : 'ml-auto'
                    } form-group col-md-4 d-flex align-items-center`}
                  >
                    <label
                      className={`m-0 app-emphasis required ${
                        isRtl ? 'rtl reversed' : 'ltr reversed'
                      }`}
                      htmlFor="password"
                      style={{ minWidth: '160px' }}
                    >
                      {t('forms-labels.new-password')}
                    </label>
                  </div>
                  <div className="form-group col-md-6">
                    <Controller
                      control={control}
                      name="newPassword"
                      render={({ field: { value, onChange } }) => (
                        <IconInput
                          value={value}
                          required
                          id="password"
                          type="text"
                          onChange={(event) => {
                            onChange(event);
                            checkConfirmation(getValues());
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`${
                      isRtl ? 'mr-auto' : 'ml-auto'
                    } form-group col-md-4 d-flex align-items-center`}
                  >
                    <label
                      className={`m-0 app-emphasis required ${
                        isRtl ? 'rtl reversed' : 'ltr reversed'
                      }`}
                      htmlFor="confirm-pass"
                      style={{ minWidth: '160px' }}
                    >
                      {t('forms-labels.confirm-new-password')}
                    </label>
                  </div>
                  <div className="d-flex col-md-6" style={{ gap: '0.5rem' }}>
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="confirmNewPassword"
                        render={({ field: { value, onChange } }) => (
                          <IconInput
                            value={value}
                            required
                            id="confirm-pass"
                            type="text"
                            onChange={(event) => {
                              onChange(event);
                              checkConfirmation(getValues());
                            }}
                          />
                        )}
                      />
                    </div>
                    <div
                      className={`mb-3 d-flex align-items-center ${
                        passwordIdentical === true
                          ? 'primary-txt'
                          : passwordIdentical === false
                          ? 'danger-txt'
                          : ''
                      }`}
                      style={{ width: '2rem' }}
                      title={
                        passwordIdentical === true
                          ? t('settings.password-identical')
                          : passwordIdentical === false
                          ? t('settings.password-unidentical')
                          : undefined
                      }
                    >
                      {passwordIdentical === true ? (
                        <span className="icon" data-icon="&#x43;" />
                      ) : passwordIdentical === false ? (
                        <span className="icon" data-icon="&#x42;" />
                      ) : (
                        <div style={{ minWidth: '23px' }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export function FormUsernameOm({
  id,
  logo,
  nameFr,
  nameAr,
  username,
  closePath,
  onAfterSubmission: afterSubmission,
  userId,
  onClose,
}: FormOmProps & { userId: number }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/om');
  };
  const { control, handleSubmit, reset, getValues } = useForm({
    defaultValues: { newUsername: username ?? '' },
  });
  const history = useHistory();
  const formMethod = 'patch';
  const formAction = `/users/username/${userId}/update`;
  const submit = async () => {
    setModalRole('processing');

    const data = getValues();
    data.newUsername = data.newUsername.trim();

    try {
      await axios[formMethod](formAction, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error) {
      setModalRole('content');
      console.error(error);
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          console.error(error);

          toast.error(messages?.toString());
        }
      } else {
        toast.error((error as any).message);
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/om');
      }}
    />
  );
  const formTitle = t(`settings.headers.change-username`);

  let bottomBtns = (
    <Button
      className={isRtl ? 'mr-3' : 'ml-3'}
      btnRole="primary"
      type="submit"
      form="edit-pass"
      style={{ width: '145px' }}
    >
      {t('save-button')}
    </Button>
  );

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      successMessage={t('om.modify-success-message')}
      successButtons={successButtons}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={reset}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="form-title app-heading-3 mb-3">{formTitle}</div>
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form
            id="edit-pass"
            className="d-flex flex-column collapsible"
            onSubmit={handleSubmit(submit)}
          >
            <div className="d-flex">
              <div className="files-access-left">
                <Picture defaultImageName={logo} />
              </div>
              <div className="files-access-right">
                <div className="app-title my-3 text-center">
                  {isRtl ? nameAr : nameFr}
                </div>
                <div className="row">
                  <div
                    className={`${
                      isRtl ? 'mr-auto' : 'ml-auto'
                    } form-group col-md-4 d-flex align-items-center`}
                  >
                    <label
                      className={`m-0 app-emphasis required ${
                        isRtl ? 'rtl reversed' : 'ltr reversed'
                      }`}
                      htmlFor="username"
                      style={{ minWidth: '160px' }}
                    >
                      {t('forms-labels.username')}
                    </label>
                  </div>
                  <div className="form-group col-md-6">
                    <Controller
                      control={control}
                      name="newUsername"
                      render={({ field: { value, onChange } }) => (
                        <IconInput
                          value={value}
                          required
                          id="username"
                          type="text"
                          onChange={onChange}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
