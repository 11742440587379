import { useState } from 'react';
import i18n from 'i18n';

export function useTrans() {
  const {
    language: _language,
    changeLanguage: _changeLanguage,
    ...rest
  } = i18n;

  const [language, setLanguage] = useState<String>(_language);

  const changeLanguage = (lng: string) => {
    _changeLanguage(lng);
    localStorage.setItem('lng-pref', lng);
    setLanguage(lng);
  };

  return { language, changeLanguage, ...rest };
}
