import { HTMLProps } from 'react';
import { useAutoTrans } from 'components/auto-translation/AutoTranslation';

export default function NavigableContent({
  className = '',
  ...rest
}: HTMLProps<HTMLDivElement>) {
  const { language } = useAutoTrans();
  const isRtl = language === 'ar';
  className = `wide-container d-flex flex-grow-1 ${
    isRtl ? 'rtl' : ''
  } ${className}`;

  return <div className={className} {...rest} />;
}
