import { useTranslation } from 'react-i18next';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import './Paginator.css';

export default function Paginator({
  pageRangeDisplayed,
  className = '',
  ...rest
}: ReactPaginateProps) {
  const {
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  return (
    <ReactPaginate
      className={`app-paginator ${className}`}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      breakLabel="..."
      previousLabel={isRtl ? '→' : '←'}
      nextLabel={isRtl ? '←' : '→'}
      containerClassName="app-paginator"
      previousLinkClassName="btn button btn-primary"
      pageLinkClassName="btn button btn-secondary"
      nextLinkClassName="btn button btn-primary"
      disabledLinkClassName="disabled"
      activeLinkClassName="btn-primary"
      {...rest}
    />
  );
}
