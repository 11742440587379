import AutoTranslation, {
  useAutoTrans,
} from 'components/auto-translation/AutoTranslation';
import CookieConsent from 'components/cookie-consent/CookieConsent';
import { PrivateNavbar } from 'components/navbar/Navbar';
import NavigableContent from 'components/navigable-content/NavigableContent';
import AuthRouter from 'components/routes/AuthRouter';
import LoginRoute from 'components/routes/LoginRoute';
import PrivateRoute from 'components/routes/PrivateRoute';
import { PrivateSidebar } from 'components/sidebar/Sidebar';
import { Page404 } from 'pages/404/404';
import Competition from 'pages/competition/Competition';
import Demand from 'pages/demand/Demand';
import Event from 'pages/event/Event';
import Home from 'pages/home/Home';
import Journalist from 'pages/journalist/Journalist';
import Location from 'pages/location/Location';
import Login from 'pages/login/Login';
import Om from 'pages/om/Om';
import Person from 'pages/person/Person';
import Settings from 'pages/settings/Settings';
import { Suspense } from 'react';
import { Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function AuthenticatedApp() {
  const { language } = useAutoTrans();
  const isRtl = language === 'ar';

  return (
    <Suspense fallback="loading">
      <ToastContainer
        position={isRtl ? 'top-left' : 'top-right'}
        rtl={isRtl}
        bodyClassName={isRtl ? 'rtl' : 'ltr'}
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
      />
      <CookieConsent />
      <PrivateNavbar />
      <NavigableContent>
        <PrivateSidebar />
        <Switch>
          <LoginRoute path="/login">
            <Login />
          </LoginRoute>
          <PrivateRoute
            path="/event"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Event />
          </PrivateRoute>
          <PrivateRoute
            userType="faf"
            path="/location"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Location />
          </PrivateRoute>
          <PrivateRoute
            userType="faf"
            path="/om"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Om />
          </PrivateRoute>
          <PrivateRoute
            path="/journalist"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Journalist />
          </PrivateRoute>
          <PrivateRoute
            userType="faf"
            path="/person"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Person />
          </PrivateRoute>
          <PrivateRoute
            path="/demand"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Demand />
          </PrivateRoute>
          <PrivateRoute
            userType="faf"
            path="/competition"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Competition />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/home"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Home />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/settings"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Settings />
          </PrivateRoute>
          <PrivateRoute
            exact
            path="/"
            redirectOnForbidden
            redirectOnUnauthorized
          >
            <Redirect to="/home" />
          </PrivateRoute>
          <PrivateRoute path="/" redirectOnForbidden redirectOnUnauthorized>
            <Page404 />
          </PrivateRoute>
        </Switch>
      </NavigableContent>
    </Suspense>
  );
}

export default function App() {
  return (
    <AuthRouter>
      <AutoTranslation>
        <AuthenticatedApp />
      </AutoTranslation>
    </AuthRouter>
  );
}
