import { PathLocationDto } from 'utils/utils';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import { useAuth } from './AuthRouter';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';

interface PrivateRouteProps extends RouteProps {
  redirectOnUnauthorized?: boolean; // when the user is not logged in.
  redirectOnForbidden?: boolean; // user type does not allow for this route.
  userType?: 'faf' | 'om';
}

export default function PrivateRoute({
  children,
  redirectOnUnauthorized = false,
  redirectOnForbidden = false,
  userType,
  render,
  ...rest
}: PrivateRouteProps) {
  const auth = useAuth();

  render = ({ location }: RouteComponentProps) => {
    if (auth.status === 'authorized') {
      if (!userType || userType === auth.user?.userType) {
        return children;
      } else {
        return redirectOnForbidden ? (
          <Redirect to={{ pathname: '/home' }} />
        ) : null;
      }
    } else if (auth.status === 'unchecked') {
      return <PulseIndicator fullscreen />;
    } else {
      if (redirectOnUnauthorized) {
        const state: PathLocationDto = { from: location };

        return <Redirect to={{ pathname: '/login', state }} />;
      }
    }
  };

  return <Route {...rest} render={render} />;
}
