import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import ReactDatePicker, {
  ReactDatePickerProps as Props,
} from 'react-datepicker';

export default function DatePicker({ className = '', ...rest }: Props) {
  return (
    <div className="input-group app-date-picker-container">
      <ReactDatePicker
        className={`app-date-picker icon-input form-control ${className}`}
        {...rest}
      />
    </div>
  );
}
