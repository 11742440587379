import './Button.css';
import React, {
  ChangeEventHandler,
  HTMLProps,
  useEffect,
  useState,
} from 'react';
import {
  checkFileExists,
  openFile,
  supportedFiles,
  uploadFile,
} from 'lib/file-transfer';
import { ReactComponent as UploadSvg } from 'assets/upload.svg';
import { ReactComponent as WarnSvg } from 'assets/warn.svg';
import { FileExistsStateType } from '../../../../shared/types';
import { ReactComponent as CloseSvg } from 'assets/close.svg';
import { CircularIndicator } from 'components/loading-indicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export type ButtonSize = 'sm' | 'md' | 'lg';
export type ButtonRole = 'primary' | 'secondary' | 'unset';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  btnRole?: ButtonRole;
  btnSize?: ButtonSize;
  accent?: 'normal' | 'danger';
  type?: 'submit' | 'button';
  direction?: 'ltr' | 'rtl';
}

export default function Button({
  btnSize = 'md',
  btnRole = 'unset',
  className = '',
  accent = 'normal',
  children = 'Click Me!',
  direction,
  type = 'button',
  ...rest
}: ButtonProps) {
  className = `button btn btn-${btnRole} btn-${accent} btn-${btnSize} ${
    direction || ''
  } ${className}`;

  return (
    <button className={className} type={type} {...rest}>
      {children}
    </button>
  );
}

export function ModalCloseButton({
  className = '',
  ...rest
}: HTMLProps<HTMLButtonElement> & {
  children?: never;
  type?: 'submit' | 'button' | 'reset';
}) {
  return (
    <button className={`modal-close-button ${className}`} {...rest}>
      <CloseSvg />
    </button>
  );
}

export interface FileButtonProps extends HTMLProps<HTMLDivElement> {
  defaultFileName?: string;
  btnSize?: ButtonSize;
  onNameChange?: (name: string) => any;
  onFileExistsCheck?: (exists: boolean) => any;
  enableUpload?: boolean;
  children?: never;
}

export function FileButton({
  defaultFileName = '',
  className = '',
  btnSize = 'md',
  enableUpload,
  onNameChange,
  form,
  required,
  onFileExistsCheck,
  ...rest
}: FileButtonProps) {
  const [fileName, setFileName] = useState<string>(defaultFileName);
  const [fileExists, setFileExists] = useState<FileExistsStateType>('no-check');
  const [invalid, setInvalid] = useState<boolean>(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const upload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    const file = e.target.files?.item(0);

    // check user chose a file (did not cancel), and the file is supported.
    if (!file || !supportedFiles.includes(file.type)) return;

    uploadFile(file)
      .then((name) => {
        setFileName(name);
        onNameChange?.(name);
      })
      .catch((error) => {
        console.error(error);
        setFileExists('check-error');
      });
  };
  const onInvalid = () => {
    if (invalid) return;

    setInvalid(true);
    setTimeout(() => setInvalid(false), 1100);
  };

  useEffect(() => {
    if (!fileName) return;

    checkFileExists(fileName)
      .then((exists) => {
        setFileExists(exists ? 'exists' : 'not-exists');
        onFileExistsCheck?.(exists);
      })
      .catch((error) => {
        console.error(error);
        setFileExists('check-error');
      });
  }, [fileName, onFileExistsCheck]);

  let content: React.ReactNode;

  if (fileName) {
    if (fileExists === 'no-check') {
      content = (
        <Button btnSize={btnSize} className="d-flex">
          <CircularIndicator svgStyle={{ width: '28px', height: '28px' }} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>
            {t('file-button.processing')}
          </span>
        </Button>
      );
    } else if (fileExists === 'exists') {
      content = (
        <div className="d-flex">
          <Button
            className="d-flex"
            btnSize={btnSize}
            btnRole="primary"
            onClick={() => openFile(fileName)}
          >
            <span className="icon" data-icon="&#x43;" />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>
              {t('file-button.open-preview')}
            </span>
          </Button>
          {enableUpload ? (
            <label
              className={`btn button btn-secondary m-0 ${
                isRtl ? 'mr-3' : 'ml-3'
              } btn-${btnSize}`}
            >
              <input
                type="file"
                onChange={upload}
                accept=".jpeg,.jpg,.png,.pdf"
              />
              <UploadSvg />
              <span className={isRtl ? 'mr-2' : 'ml-2'}>
                {t('file-button.replace')}
              </span>
            </label>
          ) : null}
        </div>
      );
    } else {
      content = (
        <div className="d-flex">
          <Button btnSize={btnSize} btnRole="unset">
            <WarnSvg />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>
              {t('file-button.error-message')}
            </span>
          </Button>
          {enableUpload ? (
            <label
              className={`btn button btn-secondary m-0 ${
                isRtl ? 'mr-3' : 'ml-3'
              } btn-${btnSize} ${invalid ? 'upload-invalid' : ''}`}
            >
              <input
                type="file"
                onChange={upload}
                accept=".jpeg,.jpg,.png,.pdf"
              />
              <UploadSvg />
              <span className={isRtl ? 'mr-2' : 'ml-2'}>
                {t('file-button.replace')}
              </span>
            </label>
          ) : null}
        </div>
      );
    }
  } else {
    content = enableUpload ? (
      <label
        className={`btn button btn-secondary m-0 btn-${btnSize} ${
          invalid ? 'upload-invalid' : ''
        }`}
      >
        <input type="file" onChange={upload} accept=".jpeg,.jpg,.png,.pdf" />
        <UploadSvg />
        <span className={isRtl ? 'mr-2' : 'ml-2'}>
          {t('file-button.add-message')}
        </span>
      </label>
    ) : (
      <label className={`btn button btn-secondary m-0 btn-${btnSize}`}>
        {t('file-button.no-document')}
      </label>
    );
  }

  return (
    <div className={`upload-button ${className}`} {...rest}>
      {required ? (
        <input
          className="d-none"
          form={form}
          required
          onChange={() => {}}
          pattern={/^exists$/.source}
          onInvalid={onInvalid}
          value={fileExists}
        />
      ) : null}
      {content}
    </div>
  );
}

export interface UploadButtonProps extends HTMLProps<HTMLDivElement> {
  btnSize?: ButtonSize;
  enableUpload?: boolean;
  accept?: string;
  endpoint: string;
  onUploadFinished?: () => void;
}

export function UploadButton({
  className = '',
  btnSize = 'md',
  enableUpload,
  accept,
  children,
  endpoint,
  onUploadFinished,
  ...rest
}: UploadButtonProps) {
  const [fileUploading, setFileUploading] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  async function uploadFile(file: File) {
    const data = new FormData();
    data.append('file', file);

    const res = await axios.post(endpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // res.data holds the file name on server
    return res.data as string;
  }

  const onUploadRequest: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    const file = e.target.files?.item(0);

    // check user chose a file (did not cancel), and the file is supported.
    if (!file) return;
    setFileUploading(true);
    uploadFile(file)
      .then(() => {
        setFileUploading(false);
        onUploadFinished?.();
      })
      .catch((error) => {
        console.error(error);
        setFileUploading(false);
      });
  };

  return (
    <div className={`upload-button ${className}`} {...rest}>
      {!enableUpload ? (
        <label className={`btn button btn-secondary m-0 btn-${btnSize}`}>
          {t('file-button.no-document')}
        </label>
      ) : fileUploading ? (
        <Button btnSize={btnSize} className="d-flex">
          <CircularIndicator svgStyle={{ width: '28px', height: '28px' }} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>
            {t('file-button.processing')}
          </span>
        </Button>
      ) : (
        <label className={`btn button btn-secondary m-0 btn-${btnSize}`}>
          <input
            type="file"
            onChange={onUploadRequest}
            accept={accept ?? '.csv'}
          />
          <UploadSvg />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{children}</span>
        </label>
      )}
    </div>
  );
}
