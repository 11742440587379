import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from 'utils/reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toggle/style.css';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import './index.css';
import App from './App';
import { initConnection } from 'lib/access';
import { setAppElement } from 'react-modal';
import './i18n';

// Initialize axios defaults.
initConnection();

// Initialize react modal for users of screenreaders.
setAppElement('#root');

// Optional, loading countries with flags in the background.
// loadCountriesOptions();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
