import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import axios, { AxiosResponse } from 'axios';
import Button from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { useAuth } from 'components/routes/AuthRouter';
import {
  FormCompetition,
  ViewCompetition,
} from 'pages/competition/Competition';
import { ViewDemands } from 'pages/demand/Demand';
import { FormEvent, ViewEvent } from 'pages/event/Event';
import { FormJournalist, ViewJournalist } from 'pages/journalist/Journalist';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { personToFullname } from 'utils/utils';
import { CompetitionDto } from '../../../../shared/dtos/competition.dto';
import { FixedGetEventSortedDemandDto } from '../../../../shared/dtos/demand.dto';
import { EventDto } from '../../../../shared/dtos/event.dto';
import { GetJournalistDto } from '../../../../shared/dtos/journalist.dto';
import { Paginate } from '../../../../shared/types';
import './Home.css';

type HomeModalMode = 'closed' | 'view' | 'edit' | 'badge';
type HomeModal = 'journalist' | 'demand' | 'event' | 'competition';

export default function Home() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const history = useHistory();
  const auth = useAuth();
  const [recentEvents, setRecentEvents] = useState<EventDto[]>();
  const [recentJournalists, setRecentJournalists] = useState<
    GetJournalistDto[]
  >();
  const [recentDemands, setRecentDemands] = useState<
    FixedGetEventSortedDemandDto[]
  >();
  const [recentCompetitions, setRecentCompetitions] = useState<
    CompetitionDto[]
  >();
  const [openModal, setOpenModal] = useState<HomeModal>();
  const [modalMode, setModalMode] = useState<HomeModalMode>();
  const [selectedId, setSelectedId] = useState<number>();
  const [selectedId2, setSelectedId2] = useState<number>();

  const loadData = useCallback(async () => {
    try {
      const {
        data: { results },
      } = await axios.get<never, AxiosResponse<Paginate<EventDto>>>(
        '/event/0/6',
      );
      setRecentEvents(results.slice(0, 6));
    } catch (error) {
      console.error(error);
    }
    try {
      const { data } = await axios.get<
        never,
        AxiosResponse<GetJournalistDto[]>
      >('/journalist/filter/pending');
      setRecentJournalists(data.slice(0, 6));
    } catch (error) {
      console.error(error);
    }
    try {
      const {
        data: { results },
      } = await axios.get<
        never,
        AxiosResponse<Paginate<FixedGetEventSortedDemandDto>>
      >('/demand/0/6');
      setRecentDemands(results.slice(0, 6));
    } catch (error) {
      console.error(error);
    }
    if (auth.user?.userType === 'faf') {
      try {
        const {
          data: { results },
        } = await axios.get<never, AxiosResponse<Paginate<CompetitionDto>>>(
          '/event/competition/0/6',
        );
        setRecentCompetitions(results.slice(0, 6));
      } catch (error) {
        console.error(error);
      }
    }
  }, [auth.user?.userType]);

  useEffect(() => void loadData(), [auth, loadData]);

  return (
    <div
      className={`page ${
        isRtl ? 'rtl' : 'ltr'
      } container flex-column d-flex pt-4 pb-4`}
    >
      {openModal === 'event' ? (
        modalMode === 'view' ? (
          <ViewEvent
            {...recentEvents?.find(({ id }) => id === selectedId)}
            onClose={() => {
              setSelectedId(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
            onEditClick={() => setModalMode('edit')}
          />
        ) : modalMode === 'edit' ? (
          <FormEvent
            {...recentEvents?.find(({ id }) => id === selectedId)}
            onClose={() => {
              setSelectedId(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
            closePath="/home"
            formType="edit"
          />
        ) : undefined
      ) : openModal === 'competition' ? (
        modalMode === 'view' ? (
          <ViewCompetition
            {...recentCompetitions?.find(({ id }) => id === selectedId)}
            onClose={() => {
              setSelectedId(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
            onEditClick={() => setModalMode('edit')}
          />
        ) : modalMode === 'edit' ? (
          <FormCompetition
            {...recentCompetitions?.find(({ id }) => id === selectedId)}
            onClose={() => {
              setSelectedId(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
            closePath="/home"
            formType="edit"
          />
        ) : undefined
      ) : openModal === 'journalist' ? (
        modalMode === 'view' ? (
          <ViewJournalist
            {...recentJournalists?.find(({ id }) => id === selectedId)}
            onClose={() => {
              setSelectedId(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
            onEditClick={() => setModalMode('edit')}
            onQuickUpdate={() => loadData()}
          />
        ) : modalMode === 'edit' ? (
          <FormJournalist
            {...recentJournalists?.find(({ id }) => id === selectedId)}
            onClose={() => {
              setSelectedId(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
            closePath="/home"
            formType="edit"
          />
        ) : undefined
      ) : openModal === 'demand' ? (
        modalMode === 'view' ? (
          <ViewDemands
            {...recentDemands?.find(
              ({ id, om }) => id === selectedId && om!.id === selectedId2,
            )}
            onClose={() => {
              setSelectedId(undefined);
              setSelectedId2(undefined);
              setModalMode(undefined);
              setOpenModal(undefined);
            }}
          />
        ) : undefined
      ) : undefined}
      <div className="d-flex align-items-center justify-content-stretch">
        <div className="app-title" style={{ minWidth: '240px' }}>
          {t('home.header.waiting-journalists')}
        </div>
        <div className="dropdown-divider home-aligner w-100 ml-1" />
        <Button
          className="primary-txt ml-2"
          btnSize="sm"
          style={{ minWidth: '120px' }}
          onClick={() => history.push('/journalist')}
        >
          <span className="mr-2">{t('more-button')}</span>
          <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
        </Button>
      </div>
      <div className="home-cards-view pt-4">
        {recentJournalists ? (
          recentJournalists.length > 0 ? (
            <CardsContainer
              direction={isRtl ? 'rtl' : 'ltr'}
              cardsDirection="horizontal"
            >
              {recentJournalists.map(
                (
                  {
                    id,
                    photo,
                    firstNameFr,
                    lastNameFr,
                    firstNameAr,
                    lastNameAr,
                    suspended,
                    status,
                    function: functionality,
                    om,
                  },
                  index,
                ) => {
                  const fullname = isRtl
                    ? personToFullname(firstNameAr, lastNameAr)
                    : personToFullname(firstNameFr, lastNameFr);

                  const viewDetailsBtn = (
                    <Button
                      className={`slidable ${
                        isRtl ? 'slidable-left' : 'slidable-right'
                      } app-small-text d-flex`}
                      onClick={() => {
                        setSelectedId(id);
                        setModalMode('view');
                        setOpenModal('journalist');
                      }}
                    >
                      <span className="view-details-button-text">
                        {t('details-button')}
                      </span>
                      <ArrowRightSvg
                        className={`card-link-icon svg-icon ${
                          isRtl ? 'rtl' : 'ltr'
                        }`}
                      />
                    </Button>
                  );
                  const card = (
                    <Card
                      key={index}
                      imageName={photo}
                      cardTitle={fullname}
                      subtitle={
                        auth.user?.userType === 'om'
                          ? isRtl
                            ? functionality?.nameAr
                            : functionality?.nameFr
                          : isRtl
                          ? om?.nameAr
                          : om?.nameFr
                      }
                      commentRole={
                        status === 'accepted'
                          ? suspended
                            ? 'danger'
                            : 'info'
                          : status === 'pending'
                          ? 'warning'
                          : 'danger'
                      }
                      comment={
                        status === 'accepted'
                          ? t(`suspension-status.${suspended || false}`)
                          : t(`acceptance-status.${status || 'pending'}`)
                      }
                      detailsToggle={viewDetailsBtn}
                    />
                  );
                  return card;
                },
              )}
            </CardsContainer>
          ) : (
            <h3 className="d-flex justify-content-center align-items-center pt-2 pb-5">
              {t('home.no-content-feedback.waiting-journalists')}.
            </h3>
          )
        ) : (
          <PulseIndicator />
        )}
      </div>
      <div className="d-flex align-items-center justify-content-stretch">
        <div className="app-title" style={{ minWidth: '220px' }}>
          {t('home.header.waiting-demands')}
        </div>
        <div className="dropdown-divider home-aligner w-100 ml-1" />
        <Button
          className="primary-txt ml-2"
          btnSize="sm"
          style={{ minWidth: '120px' }}
          onClick={() => history.push('/demand')}
        >
          <span className="mr-2">{t('more-button')}</span>
          <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
        </Button>
      </div>
      <div className="home-cards-view pt-4">
        {recentDemands ? (
          recentDemands.length > 0 ? (
            <CardsContainer
              direction={isRtl ? 'rtl' : 'ltr'}
              cardsDirection="horizontal"
            >
              {recentDemands.map(
                ({ id, nameFr, nameAr, om, eventDate, location }, index) => {
                  const viewDetailsBtn = (
                    <Button
                      className={`slidable ${
                        isRtl ? 'slidable-left' : 'slidable-right'
                      } app-small-text d-flex`}
                      onClick={() => {
                        setSelectedId(id);
                        setSelectedId2(om!.id);
                        setModalMode('view');
                        setOpenModal('demand');
                      }}
                    >
                      <span className="view-details-button-text">
                        {t('details-button')}
                      </span>
                      <ArrowRightSvg
                        className={`card-link-icon svg-icon ${
                          isRtl ? 'rtl' : 'ltr'
                        }`}
                      />
                    </Button>
                  );
                  const card = (
                    <Card
                      key={index}
                      imageName={
                        auth.user?.userType === 'faf'
                          ? om?.logo
                          : location?.image
                      }
                      cardTitle={isRtl ? nameAr : nameFr}
                      subtitle={
                        auth.user?.userType === 'faf'
                          ? isRtl
                            ? om?.nameAr
                            : om?.nameFr
                          : undefined
                      }
                      comment={
                        eventDate
                          ? new Date(eventDate).toLocaleDateString('fr-DZ')
                          : undefined
                      }
                      commentType="date"
                      detailsToggle={viewDetailsBtn}
                    />
                  );
                  return card;
                },
              )}
            </CardsContainer>
          ) : (
            <h3 className="d-flex justify-content-center align-items-center pt-2 pb-5">
              {t('home.no-content-feedback.waiting-demands')}.
            </h3>
          )
        ) : (
          <PulseIndicator />
        )}
      </div>
      <div className="d-flex align-items-center justify-content-stretch">
        <div className="app-title" style={{ minWidth: '210px' }}>
          {t('home.header.recent-events')}
        </div>
        <div className="dropdown-divider home-aligner w-100 ml-1" />
        <Button
          className="primary-txt ml-2"
          btnSize="sm"
          style={{ minWidth: '120px' }}
          onClick={() => history.push('/event')}
        >
          <span className="mr-2">{t('more-button')}</span>
          <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
        </Button>
      </div>
      <div className="home-cards-view pt-4">
        {recentEvents ? (
          recentEvents.length > 0 ? (
            <CardsContainer direction={isRtl ? 'rtl' : 'ltr'}>
              {recentEvents.map(
                ({ id, nameFr, nameAr, location, eventDate, type }, index) => {
                  const viewDetailsBtn = (
                    <Button
                      className={`slidable ${
                        isRtl ? 'slidable-left' : 'slidable-right'
                      } app-small-text d-flex`}
                      onClick={() => {
                        setSelectedId(id);
                        setModalMode('view');
                        setOpenModal('event');
                      }}
                    >
                      <span className="view-details-button-text">
                        {t('details-button')}
                      </span>
                      <ArrowRightSvg
                        className={`card-link-icon svg-icon ${
                          isRtl ? 'rtl' : 'ltr'
                        }`}
                      />
                    </Button>
                  );
                  const card = (
                    <Card
                      key={index}
                      imageName={location?.image}
                      cardTitle={isRtl ? nameAr : nameFr}
                      subtitle={isRtl ? type?.nameAr : type?.nameFr}
                      comment={
                        eventDate
                          ? new Date(eventDate).toLocaleDateString('fr-DZ')
                          : ''
                      }
                      commentType="date"
                      detailsToggle={viewDetailsBtn}
                    />
                  );
                  return card;
                },
              )}
            </CardsContainer>
          ) : (
            <h3 className="d-flex justify-content-center align-items-center pt-2 pb-5">
              {t('home.no-content-feedback.recent-events')}.
            </h3>
          )
        ) : (
          <PulseIndicator />
        )}
      </div>
      {auth.user?.userType === 'faf' ? (
        <>
          <div className="d-flex align-items-center justify-content-stretch">
            <div className="app-title" style={{ minWidth: '140px' }}>
              {t('home.header.competitions')}
            </div>
            <div className="dropdown-divider home-aligner w-100 ml-1" />
            <Button
              className="primary-txt ml-2"
              btnSize="sm"
              style={{ minWidth: '120px' }}
              onClick={() => history.push('/competition')}
            >
              <span className="mr-2">{t('more-button')}</span>
              <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            </Button>
          </div>
          <div className="home-cards-view pt-4">
            {recentCompetitions ? (
              recentCompetitions.length > 0 ? (
                <CardsContainer
                  direction={isRtl ? 'rtl' : 'ltr'}
                  cardsDirection="horizontal"
                >
                  {recentCompetitions.map(
                    ({ id, nameFr, nameAr, competitionLogo }, index) => {
                      const viewDetailsBtn = (
                        <Button
                          className={`slidable ${
                            isRtl ? 'slidable-left' : 'slidable-right'
                          } app-small-text d-flex`}
                          onClick={() => {
                            setSelectedId(id);
                            setModalMode('view');
                            setOpenModal('competition');
                          }}
                        >
                          <span className="view-details-button-text">
                            {t('details-button')}
                          </span>
                          <ArrowRightSvg
                            className={`card-link-icon svg-icon ${
                              isRtl ? 'rtl' : 'ltr'
                            }`}
                          />
                        </Button>
                      );
                      const card = (
                        <Card
                          key={index}
                          imageName={competitionLogo}
                          cardTitle={isRtl ? nameAr : nameFr}
                          detailsToggle={viewDetailsBtn}
                        />
                      );
                      return card;
                    },
                  )}
                </CardsContainer>
              ) : (
                <h3 className="d-flex justify-content-center align-items-center pt-2 pb-5">
                  {t('home.no-content-feedback.competitions')}.
                </h3>
              )
            ) : (
              <PulseIndicator />
            )}
          </div>
        </>
      ) : undefined}
    </div>
  );
}
