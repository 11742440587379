import Picture from 'components/picture/Picture';
import { HTMLProps, Key } from 'react';
import './ListView.css';

export default function ListView({
  className = '',
  children,
  ...rest
}: HTMLProps<HTMLDivElement>) {
  return (
    <div className={`list-view ${className}`} {...rest}>
      {children}
    </div>
  );
}

export interface ListTileProps
  extends HTMLProps<HTMLLabelElement & HTMLDivElement> {
  imageName?: string;
  enableImage?: boolean;
  leading?: React.ReactNode;
  tileTitle?: React.ReactNode;
  subtitle?: React.ReactNode;
  comment?: React.ReactNode;
  subComment?: React.ReactNode;
  subCommentRole?: 'danger' | 'warning' | 'info' | 'success' | 'unset';
  endToggles?: React.ReactNode;
  onSelectChange?: (index: Key | null | undefined) => any;
  index?: number;
  disableSelection?: boolean;
  wrapWithLabels?: boolean;
}

export function ListTile({
  className = '',
  enableImage = true,
  imageName,
  leading,
  tileTitle,
  subtitle,
  comment,
  subComment: subcomment,
  subCommentRole = 'unset',
  endToggles,
  checked,
  disableSelection,
  onSelectChange,
  index,
  wrapWithLabels,
  ...rest
}: ListTileProps & { children?: never }) {
  const _subCommentRole =
    subCommentRole !== 'unset' ? `text-${subCommentRole}` : '';

  let content = (
    <>
      {onSelectChange ? (
        <input
          checked={checked}
          type="checkbox"
          className="m-2"
          disabled={disableSelection}
          onChange={() => onSelectChange(index)}
        />
      ) : undefined}
      {enableImage ? <Picture imageName={imageName} /> : null}
      <div className="tile-leading">{leading}</div>
      <div className="tile-title">{tileTitle}</div>
      <div className="tile-subtitle">{subtitle}</div>
      <div className="tile-comment">{comment}</div>
      <div className={`tile-subcomment ${_subCommentRole}`}>{subcomment}</div>
      <div className="tile-link">{endToggles}</div>
    </>
  );

  if (wrapWithLabels) {
    content = (
      <label className={`list-tile ${className}`} {...rest}>
        {content}
      </label>
    );
  } else {
    content = (
      <div className={`list-tile ${className}`} {...rest}>
        {content}
      </div>
    );
  }

  return content;
}
