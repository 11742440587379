import axios from 'axios';
import { t } from 'i18next';
import { HTMLProps, useEffect, useMemo, useRef, useState } from 'react';
import { CountryDto, countries } from 'utils/countries';
import { getContrastYIQ, isColor } from 'utils/utils';
import { CategoryDto } from '../../../../shared/dtos/category.dto';
import { FunctionalityDto } from '../../../../shared/dtos/function.dto';
import './Badge.css';
import { default as sponsorsImg } from 'assets/sponsors.png';
import { default as noTemplateFallbackImg } from 'assets/no_template_fallback.png';
import { format } from 'date-fns';
import localAr from 'date-fns/locale/ar-DZ';
import localEn from 'date-fns/locale/en-GB';
import {
  AgreeTerms,
  TermsEnBody,
  TermsEnTitle,
  TermsFrBody,
  TermsFrTitle,
} from './components/Terms';
import { ZonesInfo } from './components/ZonesInfo';
import { DemandOmDto } from '../../../../shared/dtos/om.dto';

export interface BadgeProps extends HTMLProps<HTMLDivElement> {
  badgeId?: number | string;
  titleFr?: string;
  titleAr?: string;
  subtitle?: string;
  comment?: string;
  leftImageName?: string;
  rightImageName?: string;
  personImageName?: string;
  personFirstNameFr?: string;
  personLastNameFr?: string;
  personFirstNameAr?: string;
  personLastNameAr?: string;
  location?: string;
  category?: CategoryDto;
  categoryTextColor?: 'black' | 'white';
  functionality?: FunctionalityDto;
  variantColor?: string;
  alpha2?: string;
  enableClickableZones?: boolean;
  zones?: number[];
  defaultZones?: number[];
  isPrinting?: boolean;
  leftCountry?: CountryDto | undefined;
  rightCountry?: CountryDto | undefined;
  created?: Date;
  eventDate?: Date;
  om?: DemandOmDto;
  template?: string;
  templateUrl?: string;
}

export default function Badge({
  badgeId,
  titleFr,
  titleAr,
  subtitle,
  comment,
  leftImageName,
  rightImageName,
  personImageName,
  personFirstNameFr,
  personLastNameFr,
  personFirstNameAr,
  personLastNameAr,
  location,
  category,
  categoryTextColor = 'black',
  functionality,
  variantColor,
  alpha2,
  enableClickableZones = false,
  zones,
  className = '',
  defaultZones = [],
  isPrinting,
  leftCountry,
  rightCountry,
  created,
  eventDate,
  om,
  template,
  templateUrl,
  ...rest
}: BadgeProps) {
  const isMounted = useRef(false);
  const [selectedZones, setSelectedZones] = useState(
    new Set(zones || defaultZones),
  );
  const country = useMemo(
    () => countries.find(({ alpha2: a }) => a === alpha2?.trim()),
    [alpha2],
  );
  const functionalityBgColor =
    functionality?.backgroundColor ??
    (['PLAYER', 'STAFF'].includes(functionality?.nameFr ?? '')
      ? country?.color ?? '#ff0000'
      : '#ffffff');

  useEffect(() => {
    if (isMounted.current) {
      setSelectedZones(new Set(zones));
    } else {
      isMounted.current = true;
    }
  }, [zones]);

  variantColor =
    variantColor ||
    (category && isColor(category.color || 'no-color')
      ? category.color!
      : 'black');

  return (
    <div
      className={`app-badge ${isPrinting ? 'h-100' : ''} ${className}`}
      {...rest}
    >
      <svg
        width="891"
        height="1260"
        version="1.1"
        viewBox="0 0 891 1260"
        xmlns="http://www.w3.org/2000/svg"
      >
        {template ? (
          <image height="1260" width="891" href={template} />
        ) : templateUrl ? (
          <image
            height="1260"
            width="891"
            href={`${axios.defaults.baseURL}/file/${templateUrl}`}
          />
        ) : (
          <image href={noTemplateFallbackImg} />
        )}
        {/* <image href={countriesImg} x="30px" y="150px" />
        <image href={countriesImg} x="470px" y="150px" /> */}
        <g id="g263" transform="matrix(1.4962 0 0 1.4962 .44167 .25309)">
          <path
            id="path230"
            className="st41"
            d="m280.68 735.64h-261.27v-287.04c0-5.81 4.71-10.52 10.52-10.52h240.24c5.81 0 10.52 4.71 10.52 10.52v287.04z"
          />
          <path
            id="path231"
            className="st41"
            d="m270.16 808.66h-240.23c-5.81 0-10.52-4.71-10.52-10.52v-52.76h261.27v52.76c0 5.82-4.71 10.52-10.52 10.52z"
          />
          <path
            id="path232"
            className="st41"
            d="m576.55 735.64h-261.27v-287.04c0-5.81 4.71-10.52 10.52-10.52h240.24c5.81 0 10.52 4.71 10.52 10.52v287.04z"
          />
          <path
            id="path233"
            className="st41"
            d="m566.04 808.66h-240.24c-5.81 0-10.52-4.71-10.52-10.52v-52.76h261.27v52.76c0 5.82-4.71 10.52-10.51 10.52z"
          />
          <path
            id="path234"
            className="st41"
            d="m561.02 261.53h-232.86c-7.85 0-14.21-6.36-14.21-14.21v-108.71c0-7.85 6.36-14.21 14.21-14.21h232.86c7.85 0 14.21 6.36 14.21 14.21v108.71c-0.01 7.85-6.37 14.21-14.21 14.21z"
          />
          <path
            id="path235"
            className="st48"
            d="m563.91 333.03h-235.98c-6.98 0-12.64-5.66-12.64-12.64v-30.59c0-6.98 5.66-12.64 12.64-12.64h235.98c6.98 0 12.64 5.66 12.64 12.64v30.58c0 6.99-5.66 12.65-12.64 12.65z"
          />
          <path
            id="path236"
            className="st41"
            d="m563.91 396.04h-235.98c-6.98 0-12.64-5.66-12.64-12.64v-30.04c0-6.98 5.66-12.64 12.64-12.64h235.98c6.98 0 12.64 5.66 12.64 12.64v30.04c0 6.98-5.66 12.64-12.64 12.64z"
          />
          <g id="g236" transform="translate(380, 345)">
            <image height="50" width="180" href={sponsorsImg} />
          </g>
          <path
            id="path237"
            className="st41"
            d="m29.87 404.42h239.57c6.43 0 11.64-5.21 11.64-11.64v-31.59h-262.85v31.59c0 6.43 5.21 11.64 11.64 11.64z"
          />
          <rect
            id="rect237"
            className="st41"
            x="18.23"
            y="228.94"
            width="262.86"
            height="122.74"
          />
          <rect
            id="rect238"
            className="st41"
            x="18.23"
            y="124.52"
            width="87.4"
            height="93.78"
          />
          <g id="g238" transform="translate(26, 134)">
            <image
              height="74"
              width="74"
              href={`${axios.defaults.baseURL}/file/${personImageName}`}
            />
          </g>
          <rect
            id="rect239"
            className="st41"
            x="114.06"
            y="124.52"
            width="166.62"
            height="93.78"
          />
          {/* <rect
            id="rect240"
            className="st41"
            x="-.96355"
            y="-.51"
            width="596.28"
            height="55.87"
            stroke-width="1.0009"
          /> */}
          <polygon
            id="polygon240"
            className="st41"
            points="34.06 73.51 18.1 57.55 18.1 89.47"
          />
          <polygon
            id="polygon241"
            className="st41"
            points="333.47 73.51 317.51 57.55 317.51 89.47"
          />
          <path
            id="path241"
            d="m43.48 260.61c1.06-1.22 2.55-1.69 4-2.2 1.39-0.48 1.4-0.45 1.4-1.91v-9.62c0-1.58 0.86-2.07 2.23-1.27 1.99 1.16 3.97 2.32 5.95 3.49 1 0.59 1 0.79-0.03 1.4-1.72 1.03-3.44 2.07-5.18 3.07-0.42 0.24-0.57 0.51-0.55 0.99 0.04 0.9 0.01 1.8 0.01 2.78 9.28-1.91 18.6-2.4 28.01-2.53v-10.72c0-1.52 0.88-2.04 2.19-1.28 2.05 1.2 4.1 2.39 6.14 3.6 0.8 0.47 0.8 0.74 0.01 1.21-1.78 1.07-3.57 2.14-5.37 3.18-0.39 0.23-0.54 0.48-0.53 0.93 0.03 1 0.01 2 0.01 2.96 4.75 0.25 9.42 0.42 14.07 0.76 4.63 0.34 9.25 0.88 13.9 1.97v-10.46c0-0.6 0.02-1.19 0.61-1.55 0.63-0.38 1.16-0.08 1.7 0.24 2 1.18 4.01 2.35 6.01 3.53 0.83 0.49 0.83 0.75-0.02 1.25-1.72 1.03-3.43 2.08-5.18 3.06-0.53 0.3-0.7 0.64-0.67 1.22 0.04 0.85 0.05 1.72 0 2.57-0.04 0.59 0.16 0.81 0.72 1.02 1.27 0.48 2.54 1.01 3.73 1.67 0.97 0.54 0.98 1.07-0.02 1.56-1.59 0.78-3.25 1.45-4.93 2.01-1.34 0.45-2.75 0.68-4.14 0.98-0.23 0.05-0.49 0.03-0.72-0.02-5.37-1.2-10.83-1.76-16.31-1.95-5.6-0.19-11.21-0.26-16.82-0.15-6.68 0.13-13.34 0.66-19.9 2.08-0.68 0.15-1.49-0.15-2.21-0.34-1.84-0.5-3.69-0.98-5.47-1.64-0.96-0.35-1.79-1.06-2.67-1.6 0.03-0.1 0.03-0.19 0.03-0.29z"
          />
          <path
            id="path242"
            d="m93.28 298.11v-11.69c0-1.71-0.75-2.47-2.46-2.47h-20.72c-1.75 0-2.48 0.75-2.48 2.52v11.43c-5.74-0.69-11.31-1.63-16.49-4.18-2.24-1.1-3.64-2.55-3.85-5.26-0.31-3.95-1.02-7.86-1.56-11.79-0.02-0.14 0-0.29 0-0.32 2.49 0.59 4.96 1.26 7.47 1.76 4.86 0.96 9.77 1.54 14.72 1.71 6.16 0.21 12.34 0.38 18.5 0.29 7.94-0.11 15.84-0.76 23.62-2.47 1.74-0.38 3.44-0.91 5.23-1.39-0.19 1.44-0.37 2.88-0.56 4.33-0.42 3.15-0.86 6.3-1.27 9.46-0.12 0.92-0.49 1.64-1.26 2.22-2.64 1.97-5.65 3.14-8.8 3.88-3.27 0.77-6.61 1.3-10.09 1.97z"
          />
          <path
            id="path243"
            d="m44.09 264.38c6.47 2.63 13.24 3.41 20.05 4.03 7.98 0.72 15.99 0.86 23.99 0.55 7.36-0.29 14.71-0.81 21.92-2.47 2.28-0.53 4.5-1.28 6.8-1.95-0.11 0.88-0.22 1.86-0.35 2.83-0.24 1.79-0.5 3.59-0.71 5.38-0.06 0.52-0.36 0.68-0.77 0.85-3.08 1.25-6.31 1.92-9.57 2.44-9.73 1.55-19.53 1.89-29.35 1.7-5.56-0.11-11.11-0.4-16.62-1.12-4.54-0.58-9.06-1.28-13.36-2.93-0.58-0.22-0.89-0.51-0.97-1.17-0.31-2.57-0.69-5.14-1.04-7.71-0.04-0.12-0.02-0.25-0.02-0.43z"
          />
          <path
            id="path244"
            d="m70.08 286.43h2.98v10.45h14.77v-10.45h2.98v0.84c0 4.19 0.01 8.37-0.01 12.56 0 0.5 0.14 0.74 0.62 0.88 1.08 0.32 2.14 0.7 3.21 1.04 0.67 0.22 0.97 0.68 1 1.37 0.09 2-0.03 2.13-2.02 2.13h-26.81c-1.34 0-1.51-0.18-1.52-1.53 0-1.52 0.11-1.67 1.55-2.14 0.88-0.29 1.75-0.61 2.65-0.87 0.49-0.14 0.62-0.38 0.62-0.88-0.02-4.24-0.01-8.47-0.01-12.71-0.01-0.21-0.01-0.43-0.01-0.69z"
          />
          <path
            id="path245"
            d="m59.74 265.42c13.77-1.7 27.53-1.7 41.3 0.01-13.77 1.62-27.53 1.62-41.3-0.01z"
          />
          {/* <image href={capHolderSvg} x="222" /> */}
          <polyline
            id="polyline251"
            className="st52"
            points="21.78 203.67 21.78 214.98 31.26 214.98"
          />
          <polyline
            id="polyline252"
            className="st52"
            points="102.76 203.67 102.76 214.98 93.44 214.98"
          />
          <polyline
            id="polyline253"
            className="st52"
            points="21.78 138.82 21.78 128.1 31.26 128.1"
          />
          <polyline
            id="polyline254"
            className="st52"
            points="93.44 128.1 102.76 128.1 102.76 138.82"
          />
          {!!leftCountry && (
            <>
              <rect
                id="rect254"
                className="st41"
                x="44.45"
                y="101.5"
                width="216.37"
                height="14.81"
              />
              <circle
                id="circle254"
                className="st41"
                cx="151.71"
                cy="109.71"
                r="8.96"
              />
              <path
                id="path254"
                className="st53"
                d="m148.41 104.35c-0.23 3.05-0.46 6.09-0.69 9.15 0.82 0 1.58-0.01 2.34 0.01 0.21 0 0.3-0.06 0.38-0.25 1.13-2.89 2.28-5.77 3.42-8.66 0.03-0.08 0.05-0.15 0.09-0.26-0.65 0-1.26 0.01-1.88 0-0.19 0-0.26 0.07-0.31 0.23-0.69 2.07-1.38 4.14-2.08 6.21-0.02 0.06-0.04 0.11-0.07 0.17 0.33-2.2 0.66-4.39 1-6.59-0.75-0.01-1.45-0.01-2.2-0.01zm4.04 9.47c-0.08 0-0.14-0.01-0.2-0.01-0.55 0-1.1 0-1.65 0.01-0.05 0-0.14 0.07-0.14 0.11-0.09 0.47-0.22 0.94-0.23 1.41-0.02 0.82 0.4 1.37 1.17 1.56 0.94 0.23 1.86 0.16 2.73-0.3 0.9-0.48 1.35-1.28 1.54-2.25 0.18-0.88-0.18-1.54-0.77-2.14-0.31-0.32-0.63-0.64-0.91-0.99-0.21-0.26-0.4-0.55-0.53-0.85-0.16-0.39 0.17-0.87 0.58-0.95 0.43-0.08 0.67 0.14 0.63 0.57-0.01 0.12-0.05 0.23-0.07 0.35-0.03 0.16-0.07 0.33-0.11 0.51h1.93c0.07-0.51 0.2-1.03 0.21-1.55 0.02-0.67-0.34-1.12-0.99-1.3-0.93-0.27-1.85-0.2-2.73 0.25-0.12 0.06-0.24 0.2-0.29 0.34-0.35 0.85-0.66 1.72-1.03 2.56-0.18 0.4-0.09 0.69 0.15 0.99 0.07 0.09 0.15 0.19 0.23 0.27 0.37 0.37 0.74 0.73 1.1 1.1 0.17 0.18 0.34 0.38 0.45 0.59 0.24 0.46 0.02 1.06-0.43 1.27-0.22 0.1-0.46 0.13-0.67-0.02-0.2-0.14-0.19-0.37-0.15-0.58 0.04-0.33 0.11-0.63 0.18-0.95zm-2.24 0.03c-0.4-0.09-0.41-0.08-0.54 0.25-0.2 0.5-0.4 1.01-0.6 1.51-0.18 0.46-0.36 0.92-0.54 1.38 0.43 0.07 0.43 0.07 0.57-0.29 0.2-0.52 0.41-1.03 0.61-1.55 0.17-0.44 0.33-0.87 0.5-1.3zm3.4-9.79c0.2-0.04 0.43-0.02 0.48-0.11 0.16-0.29 0.26-0.62 0.41-1.01-0.21 0.05-0.43 0.04-0.48 0.13-0.16 0.29-0.26 0.62-0.41 0.99z"
              />
              <path
                id="path255"
                className="st53"
                d="m148.41 104.35h2.2c-0.33 2.21-0.67 4.39-1 6.59 0.02-0.06 0.05-0.11 0.07-0.17 0.69-2.07 1.39-4.14 2.08-6.21 0.05-0.17 0.13-0.24 0.31-0.23 0.61 0.01 1.22 0 1.88 0-0.04 0.11-0.06 0.18-0.09 0.26-1.14 2.89-2.28 5.77-3.42 8.66-0.08 0.19-0.17 0.25-0.38 0.25-0.76-0.01-1.52-0.01-2.34-0.01 0.23-3.05 0.46-6.09 0.69-9.14z"
              />
              <path
                id="path256"
                className="st53"
                d="m152.45 113.82c-0.07 0.32-0.13 0.62-0.19 0.93-0.04 0.22-0.05 0.44 0.15 0.58 0.21 0.15 0.45 0.12 0.67 0.02 0.45-0.2 0.67-0.8 0.43-1.27-0.11-0.22-0.28-0.41-0.45-0.59-0.36-0.38-0.73-0.73-1.1-1.1-0.08-0.08-0.15-0.18-0.23-0.27-0.24-0.3-0.33-0.59-0.15-0.99 0.38-0.84 0.68-1.71 1.03-2.56 0.05-0.13 0.17-0.27 0.29-0.34 0.87-0.45 1.79-0.51 2.73-0.25 0.65 0.18 1.01 0.63 0.99 1.3-0.01 0.52-0.14 1.03-0.21 1.55h-1.93c0.04-0.18 0.07-0.34 0.11-0.51 0.02-0.12 0.06-0.23 0.07-0.35 0.05-0.44-0.19-0.66-0.63-0.57-0.41 0.08-0.74 0.56-0.58 0.95 0.12 0.31 0.32 0.6 0.53 0.85 0.28 0.35 0.6 0.67 0.91 0.99 0.59 0.6 0.95 1.26 0.77 2.14-0.2 0.98-0.65 1.77-1.54 2.25-0.86 0.46-1.79 0.53-2.73 0.3-0.77-0.19-1.19-0.74-1.17-1.56 0.01-0.47 0.14-0.94 0.23-1.41 0.01-0.05 0.09-0.11 0.14-0.11 0.55-0.01 1.1-0.01 1.65-0.01 0.07 0.02 0.13 0.03 0.21 0.03z"
              />
              <path
                id="path257"
                className="st53"
                d="m150.21 113.85c-0.17 0.43-0.33 0.86-0.5 1.29-0.2 0.52-0.41 1.03-0.61 1.55-0.14 0.36-0.14 0.36-0.57 0.29 0.18-0.46 0.36-0.92 0.54-1.38 0.2-0.5 0.4-1.01 0.6-1.51 0.13-0.32 0.14-0.33 0.54-0.24z"
              />
              <path
                id="path258"
                className="st53"
                d="m153.61 104.06c0.15-0.37 0.25-0.7 0.41-0.99 0.05-0.09 0.27-0.08 0.48-0.13-0.15 0.39-0.25 0.72-0.41 1.01-0.05 0.08-0.28 0.07-0.48 0.11z"
              />
              <rect
                id="rect258"
                className="st41"
                x="340.41"
                y="101.5"
                width="216.37"
                height="14.81"
              />
              <circle
                id="circle258"
                className="st41"
                cx="447.67"
                cy="109.71"
                r="8.96"
              />
              <path
                id="path259"
                className="st53"
                d="m444.37 104.35c-0.23 3.05-0.46 6.09-0.69 9.15 0.82 0 1.58-0.01 2.34 0.01 0.21 0 0.3-0.06 0.38-0.25 1.13-2.89 2.28-5.77 3.42-8.66 0.03-0.08 0.05-0.15 0.09-0.26-0.65 0-1.26 0.01-1.88 0-0.19 0-0.26 0.07-0.31 0.23-0.69 2.07-1.38 4.14-2.08 6.21-0.02 0.06-0.04 0.11-0.07 0.17 0.33-2.2 0.66-4.39 1-6.59-0.75-0.01-1.45-0.01-2.2-0.01zm4.04 9.47c-0.08 0-0.14-0.01-0.2-0.01-0.55 0-1.1 0-1.65 0.01-0.05 0-0.14 0.07-0.14 0.11-0.09 0.47-0.22 0.94-0.23 1.41-0.02 0.82 0.4 1.37 1.17 1.56 0.94 0.23 1.86 0.16 2.73-0.3 0.9-0.48 1.35-1.28 1.54-2.25 0.18-0.88-0.18-1.54-0.77-2.14-0.31-0.32-0.63-0.64-0.91-0.99-0.21-0.26-0.4-0.55-0.53-0.85-0.16-0.39 0.17-0.87 0.58-0.95 0.43-0.08 0.67 0.14 0.63 0.57-0.01 0.12-0.05 0.23-0.07 0.35-0.03 0.16-0.07 0.33-0.11 0.51h1.93c0.07-0.51 0.2-1.03 0.21-1.55 0.02-0.67-0.34-1.12-0.99-1.3-0.93-0.27-1.85-0.2-2.73 0.25-0.12 0.06-0.24 0.2-0.29 0.34-0.35 0.85-0.66 1.72-1.03 2.56-0.18 0.4-0.09 0.69 0.15 0.99 0.07 0.09 0.15 0.19 0.23 0.27 0.37 0.37 0.74 0.73 1.1 1.1 0.17 0.18 0.34 0.38 0.45 0.59 0.24 0.46 0.02 1.06-0.43 1.27-0.22 0.1-0.46 0.13-0.67-0.02-0.2-0.14-0.19-0.37-0.15-0.58 0.04-0.33 0.11-0.63 0.18-0.95zm-2.24 0.03c-0.4-0.09-0.41-0.08-0.54 0.25-0.2 0.5-0.4 1.01-0.6 1.51-0.18 0.46-0.36 0.92-0.54 1.38 0.43 0.07 0.43 0.07 0.57-0.29 0.2-0.52 0.41-1.03 0.61-1.55 0.17-0.44 0.33-0.87 0.5-1.3zm3.4-9.79c0.2-0.04 0.43-0.02 0.48-0.11 0.16-0.29 0.26-0.62 0.41-1.01-0.21 0.05-0.43 0.04-0.48 0.13-0.16 0.29-0.26 0.62-0.41 0.99z"
              />
              <path
                id="path260"
                className="st53"
                d="m444.37 104.35h2.2c-0.33 2.21-0.67 4.39-1 6.59 0.02-0.06 0.05-0.11 0.07-0.17 0.69-2.07 1.39-4.14 2.08-6.21 0.05-0.17 0.13-0.24 0.31-0.23 0.61 0.01 1.22 0 1.88 0-0.04 0.11-0.06 0.18-0.09 0.26-1.14 2.89-2.28 5.77-3.42 8.66-0.08 0.19-0.17 0.25-0.38 0.25-0.76-0.01-1.52-0.01-2.34-0.01 0.23-3.05 0.46-6.09 0.69-9.14z"
              />
              <path
                id="path261"
                className="st53"
                d="m448.41 113.82c-0.07 0.32-0.13 0.62-0.19 0.93-0.04 0.22-0.05 0.44 0.15 0.58 0.21 0.15 0.45 0.12 0.67 0.02 0.45-0.2 0.67-0.8 0.43-1.27-0.11-0.22-0.28-0.41-0.45-0.59-0.36-0.38-0.73-0.73-1.1-1.1-0.08-0.08-0.15-0.18-0.23-0.27-0.24-0.3-0.33-0.59-0.15-0.99 0.38-0.84 0.68-1.71 1.03-2.56 0.05-0.13 0.17-0.27 0.29-0.34 0.87-0.45 1.79-0.51 2.73-0.25 0.65 0.18 1.01 0.63 0.99 1.3-0.01 0.52-0.14 1.03-0.21 1.55h-1.93c0.04-0.18 0.07-0.34 0.11-0.51 0.02-0.12 0.06-0.23 0.07-0.35 0.05-0.44-0.19-0.66-0.63-0.57-0.41 0.08-0.74 0.56-0.58 0.95 0.12 0.31 0.32 0.6 0.53 0.85 0.28 0.35 0.6 0.67 0.91 0.99 0.59 0.6 0.95 1.26 0.77 2.14-0.2 0.98-0.65 1.77-1.54 2.25-0.86 0.46-1.79 0.53-2.73 0.3-0.77-0.19-1.19-0.74-1.17-1.56 0.01-0.47 0.14-0.94 0.23-1.41 0.01-0.05 0.09-0.11 0.14-0.11 0.55-0.01 1.1-0.01 1.65-0.01 0.07 0.02 0.13 0.03 0.21 0.03z"
              />
              <path
                id="path262"
                className="st53"
                d="m446.17 113.85c-0.17 0.43-0.33 0.86-0.5 1.29-0.2 0.52-0.41 1.03-0.61 1.55-0.14 0.36-0.14 0.36-0.57 0.29 0.18-0.46 0.36-0.92 0.54-1.38 0.2-0.5 0.4-1.01 0.6-1.51 0.13-0.32 0.14-0.33 0.54-0.24z"
              />
              <path
                id="path263"
                className="st53"
                d="m449.57 104.06c0.15-0.37 0.25-0.7 0.41-0.99 0.05-0.09 0.27-0.08 0.48-0.13-0.15 0.39-0.25 0.72-0.41 1.01-0.05 0.08-0.28 0.07-0.48 0.11z"
              />
            </>
          )}
        </g>
        <g
          id="Calque_8_-_copie"
          transform="matrix(1.4962 0 0 1.4962 .44167 .25309)"
        >
          <circle
            id="circle263"
            className="st54"
            cx="369.77"
            cy="139.04"
            r="6.23"
          />
          <circle
            id="circle264"
            className="st55"
            cx="369.77"
            cy="153.62"
            r="6.23"
          />
          <circle
            id="circle265"
            className="st56"
            cx="369.77"
            cy="167.31"
            r="6.23"
          />
          <circle
            id="circle266"
            className="st57"
            cx="369.77"
            cy="181.29"
            r="6.23"
          />
          <circle
            id="circle267"
            className="st58"
            cx="369.77"
            cy="195.1"
            r="6.23"
          />
          <circle
            id="circle268"
            className="st59"
            cx="369.77"
            cy="208.79"
            r="6.23"
          />
          <circle
            id="circle269"
            className="st60"
            cx="369.77"
            cy="222.71"
            r="6.23"
          />
          <circle
            id="circle270"
            className="st61"
            cx="369.77"
            cy="236.76"
            r="6.23"
          />
          <circle
            id="circle271"
            className="st62"
            cx="369.77"
            cy="250.74"
            r="6.23"
          />
          <rect
            id="rect271"
            className="st42"
            x="367.3"
            y="135.61"
            width="238.92"
            height="121.83"
          />
          <text
            id="text271"
            className="st41 st64 st65"
            transform="translate(367.3,142.11)"
          >
            1
          </text>
          <text
            id="text272"
            className="st41 st64 st65"
            transform="translate(367.3,156.01)"
          >
            2
          </text>
          <text
            id="text273"
            className="st41 st64 st65"
            transform="translate(367.3,169.91)"
          >
            3
          </text>
          <text
            id="text274"
            className="st41 st64 st65"
            transform="translate(367.3,183.81)"
          >
            4
          </text>
          <text
            id="text275"
            className="st41 st64 st65"
            transform="translate(367.3,197.7)"
          >
            5
          </text>
          <text
            id="text276"
            className="st41 st64 st65"
            transform="translate(367.3,211.6)"
          >
            6
          </text>
          <text
            id="text277"
            className="st41 st64 st65"
            transform="translate(367.3,225.5)"
          >
            7
          </text>
          <text
            id="text278"
            className="st41 st64 st65"
            transform="translate(367.3,239.4)"
          >
            8
          </text>
          <text
            id="text279"
            className="st41 st64 st65"
            transform="translate(367.3,253.3)"
          >
            9
          </text>
          <rect
            id="rect279"
            className="st42"
            x="330.21"
            y="286.9"
            width="228.79"
            height="37.24"
          />
          <line
            id="line279"
            className="st66"
            x1="195.53"
            x2="31.92"
            y1="451.09"
            y2="451.09"
          />
          <line
            id="line280"
            className="st66"
            x1="491.49"
            x2="327.88"
            y1="451.09"
            y2="451.09"
          />
          <rect
            id="rect280"
            className="st42"
            x="319.23"
            y="443.11"
            width="253.73"
            height="289.98"
          />
          {leftImageName && (
            <>
              <path
                id="path280"
                className="st67"
                d="m64.19 92.42h-18.45c-3.94 0-7.16-3.22-7.16-7.16v-20.42c0-3.94 3.22-7.16 7.16-7.16h18.45c3.94 0 7.16 3.22 7.16 7.16v20.41c0 3.94-3.22 7.17-7.16 7.17z"
              />
              <g id="g280" transform="translate(42, 62)">
                <image
                  height="26"
                  width="26"
                  href={`${axios.defaults.baseURL}/file/${leftImageName}`}
                />
              </g>
            </>
          )}
          {rightImageName && (
            <>
              <path
                id="path281"
                className="st67"
                d="m102.76 92.42h-18.45c-3.94 0-7.16-3.22-7.16-7.16v-20.42c0-3.94 3.22-7.16 7.16-7.16h18.45c3.94 0 7.16 3.22 7.16 7.16v20.41c0.01 3.94-3.21 7.17-7.16 7.17z"
              />
              <g id="g281" transform="translate(81, 62)">
                <image
                  height="26"
                  width="26"
                  href={`${axios.defaults.baseURL}/file/${rightImageName}`}
                />
              </g>
            </>
          )}
          {leftImageName && (
            <>
              <path
                id="path282"
                className="st67"
                d="m363.6 92.42h-18.45c-3.94 0-7.16-3.22-7.16-7.16v-20.42c0-3.94 3.22-7.16 7.16-7.16h18.45c3.94 0 7.16 3.22 7.16 7.16v20.41c0.01 3.94-3.22 7.17-7.16 7.17z"
              />
              <g id="g282" transform="translate(341, 62)">
                <image
                  height="26"
                  width="26"
                  href={`${axios.defaults.baseURL}/file/${leftImageName}`}
                />
              </g>
            </>
          )}
          {rightImageName && (
            <>
              <path
                id="path283"
                className="st67"
                d="m402.18 92.42h-18.45c-3.94 0-7.16-3.22-7.16-7.16v-20.42c0-3.94 3.22-7.16 7.16-7.16h18.45c3.94 0 7.16 3.22 7.16 7.16v20.41c0 3.94-3.22 7.17-7.16 7.17z"
              />
              <g id="g283" transform="translate(380, 62)">
                <image
                  height="26"
                  width="26"
                  href={`${axios.defaults.baseURL}/file/${rightImageName}`}
                />
              </g>
            </>
          )}
          {(!!leftCountry || !!rightCountry) && (
            <>
              <rect
                id="rect283"
                className="st41"
                x="17.93"
                y="98.36"
                width="26.96"
                height="20.17"
              />
              <g id="g283" transform="translate(17.5, 95)">
                <image
                  height="28"
                  width="28"
                  href={`/flags/128x128/${(
                    leftCountry?.alpha2 ?? 'te'
                  ).trim()}.png`}
                />
              </g>
              <rect
                id="rect284"
                className="st41"
                x="254.03"
                y="98.36"
                width="26.96"
                height="20.17"
              />
              <g id="g284" transform="translate(253.5, 95)">
                <image
                  height="28"
                  width="28"
                  href={`/flags/128x128/${(
                    rightCountry?.alpha2 ?? 'te'
                  ).trim()}.png`}
                />
              </g>
              <rect
                id="rect285"
                className="st41"
                x="313.89"
                y="98.36"
                width="26.96"
                height="20.17"
              />
              <g id="g285" transform="translate(313, 95)">
                <image
                  height="28"
                  width="28"
                  href={`/flags/128x128/${(
                    leftCountry?.alpha2 ?? 'te'
                  ).trim()}.png`}
                />
              </g>
              <rect
                id="rect286"
                className="st41"
                x="549.99"
                y="98.36"
                width="26.96"
                height="20.17"
              />
              <g id="g286" transform="translate(549.5, 95)">
                <image
                  height="28"
                  width="28"
                  href={`/flags/128x128/${(
                    rightCountry?.alpha2 ?? 'te'
                  ).trim()}.png`}
                />
              </g>
            </>
          )}
          <path
            id="path286"
            className="st68"
            d="m372.33 345.34c0.21 3.9 0.3 7.79 0.38 11.69s0.07 7.79 0.12 11.69c-0.04 3.9-0.04 7.79-0.11 11.69l-0.14 5.85c-0.08 1.95-0.13 3.9-0.25 5.85-0.11-1.95-0.16-3.9-0.25-5.85l-0.14-5.85c-0.08-3.9-0.08-7.79-0.11-11.69 0.04-3.9 0.03-7.79 0.12-11.69 0.08-3.9 0.18-7.8 0.38-11.69z"
          />
          <g id="XMLID_00000025421455065513426430000016201891939120119694_">
            <path
              id="path287"
              className="st41"
              d="m345.97 350.27c-10.93 0-19.8 8.86-19.8 19.8 0 10.93 8.86 19.8 19.8 19.8 10.93 0 19.8-8.86 19.8-19.8-0.01-10.93-8.87-19.8-19.8-19.8"
            />
            <linearGradient
              id="SVGID_00000143610541549673409060000000588133254959901338_"
              x1="325.42"
              x2="366.52"
              y1="473.35"
              y2="473.35"
              gradientTransform="matrix(1,0,0,-1,0,843.42)"
              gradientUnits="userSpaceOnUse"
            >
              <stop id="stop287" stop-color="#C59A57" offset="0" />
              <stop id="stop288" stop-color="#A07339" offset=".2784" />
              <stop id="stop289" stop-color="#C29454" offset=".3981" />
              <stop id="stop290" stop-color="#ECBE79" offset=".5486" />
              <stop id="stop291" stop-color="#FDD089" offset=".6275" />
              <stop id="stop292" stop-color="#E9BB76" offset=".7204" />
              <stop id="stop293" stop-color="#B98B4C" offset=".9047" />
              <stop id="stop294" stop-color="#A07339" offset="1" />
            </linearGradient>
            <path
              id="path294"
              d="m345.97 390.62c-11.33 0-20.55-9.22-20.55-20.55s9.22-20.55 20.55-20.55 20.55 9.22 20.55 20.55-9.22 20.55-20.55 20.55zm0-39.6c-10.5 0-19.05 8.54-19.05 19.05s8.54 19.05 19.05 19.05c10.5 0 19.05-8.54 19.05-19.05s-8.55-19.05-19.05-19.05z"
              fill="url(#SVGID_00000143610541549673409060000000588133254959901338_)"
            />
          </g>
          <g id="XMLID_00000167365618448833118930000001418108250879015073_">
            <path
              id="XMLID_00000158028379109876916920000000753921772948767151_"
              className="st70"
              d="m341.75 382.95c-0.17-0.35-0.21-0.61-0.21-0.61l-0.1 0.08h-0.27v-2.4c0-0.16 0.23-0.2 0.23-0.2-0.39-0.25-0.57-0.41-0.76-0.6-0.19-0.2-0.3-0.35-0.3-0.35 0.02 0.08 0.02 0.34 0.02 0.34v4.29h1.74c0.01 0.01-0.23-0.3-0.35-0.55"
            />
            <path
              id="XMLID_00000171690459984752644440000013736542752965527213_"
              className="st70"
              d="m355.01 382.95c-0.17-0.35-0.21-0.61-0.21-0.61l-0.09 0.08h-0.28v-2.4c0-0.16 0.23-0.2 0.23-0.2-0.39-0.25-0.57-0.41-0.76-0.6-0.19-0.2-0.3-0.35-0.3-0.35 0.02 0.08 0.02 0.34 0.02 0.34v4.29h1.74c0 0.01-0.23-0.3-0.35-0.55"
            />
            <path
              id="XMLID_00000046319313660980204660000015233943974066181519_"
              className="st70"
              d="m343.11 381.23c0 0.21-0.17 0.38-0.38 0.38s-0.38-0.17-0.38-0.38 0.17-0.38 0.38-0.38 0.38 0.17 0.38 0.38"
            />
            <path
              id="XMLID_00000068647770393198054330000015003532598436065703_"
              className="st70"
              d="m346.51 384.05c0 0.21-0.17 0.38-0.38 0.38s-0.38-0.17-0.38-0.38 0.17-0.38 0.38-0.38 0.38 0.17 0.38 0.38"
            />
            <path
              id="XMLID_00000144333864175733052420000013241929101155715762_"
              className="st70"
              d="m352.5 379.23c-0.19 0.19-0.37 0.36-0.76 0.6 0 0 0.23 0.04 0.23 0.2v2.53h-4.08c-0.12-0.07-0.26-0.16-0.36-0.29-0.32-0.4-0.77-1.02-1.2-1.32-0.08-0.06-0.24-0.16-0.48-0.2-0.26-0.03-0.57 0.04-0.78 0.26 0 0-0.29 0.35-0.4 0.44-0.11 0.1-0.35 0.15-0.35 0.15l0.88 0.66s-0.02-0.28 0.02-0.41c0.03-0.14 0.46-0.34 0.82-0.13 0.47 0.27 0.59 0.68 0.62 0.85h-3.41v-0.87s-0.33 0.4-0.74 0.67c-0.27 0.18-0.36 0.21-0.36 0.21s0.13 0.04 0.18 0.18c0.03 0.08 0.13 0.52 0.06 0.91-0.05 0.26-0.3 0.4-0.54 0.4h-0.27s0.58 0.58 0.87 0.51c0.55-0.15 0.79-0.65 0.88-0.93 0.04-0.13 0.16-0.12 0.16-0.12h4.02l0.69 0.62h1.25s-0.31-0.31-0.29-0.48c0.01-0.09 0.06-0.12 0.11-0.14h3.53v-4.29s0-0.26 0.02-0.34c-0.02-0.02-0.12 0.13-0.32 0.33"
            />
            <path
              id="XMLID_00000039847014045382077850000004770012202170574732_"
              className="st70"
              d="m339.96 381.59s-0.24 0.56-0.59 0.8c-0.08 0.06-0.28 0.17-0.46 0.17h-2.06v-0.73s-0.3 0.33-0.63 0.55c-0.23 0.15-0.39 0.19-0.39 0.19s0.17 0.03 0.17 0.16v0.98s0.03 0.31-0.18 0.35-0.6-0.01-0.6-0.01 0.54 0.58 1.08 0.45c0.41-0.1 0.63-0.47 0.73-0.83 0.04-0.17 0.23-0.16 0.23-0.16h2.7z"
            />
            <path
              id="XMLID_00000134938507672070541970000015610158370555392958_"
              className="st70"
              d="m337.19 382.1h1.48s-0.46-0.44-0.54-0.58c-0.03-0.06-0.06-0.16-0.01-0.28 0.05-0.13 0.36-0.49 0.62-0.57 0 0-0.28-0.05-0.53-0.03-0.25 0.03-0.72 0.14-0.79 0.36-0.03 0.1 0.07 0.09 0.08 0.54-0.01 0-0.21 0.43-0.31 0.56"
            />
          </g>
          <path
            id="XMLID_00000165921897712916564680000003926534766994584244_"
            className="st71"
            d="m356.51 366.62c-0.35 4.16-3.54 7.42-7.41 7.42-0.12 0-0.25 0-0.37-0.01-0.51 1.24-1.32 2.33-2.34 3.17 0.4 0.06 0.8 0.09 1.21 0.09 4.94 0 8.95-4.4 8.95-9.83-0.01-0.29-0.02-0.57-0.04-0.84"
          />
          <path
            id="XMLID_00000137117095194014651550000011109967196433117057_"
            className="st71"
            d="m348.65 357.69c-0.35-0.05-0.7-0.07-1.06-0.07-3.5 0-6.54 2.21-8.01 5.44 0.54-0.11 1.1-0.17 1.67-0.17h0.9c1.04-2.91 3.53-5.01 6.5-5.2"
          />
          <path
            id="XMLID_00000027564056898374963870000014237552108303397025_"
            className="st41"
            d="m334.04 370.95c0-3.98 3.23-7.21 7.21-7.21s7.21 3.23 7.21 7.21-3.23 7.21-7.21 7.21c-3.98 0.01-7.21-3.22-7.21-7.21"
          />
          <path
            id="XMLID_00000044141779718912855750000015458577359947296157_"
            d="m338.85 364.16c-1.94 0.69-3.5 2.18-4.27 4.07l3.05-1.38z"
          />
          <path
            id="XMLID_00000011718740274859160700000015475282343764176536_"
            d="m346.92 366.49c-1.06-1.34-2.58-2.3-4.33-2.62l1.67 2.21z"
          />
          <path
            id="XMLID_00000067925010369049798350000005467279057782416014_"
            d="m334.05 370.76c0 0.06-0.01 0.13-0.01 0.19 0 1.89 0.73 3.61 1.92 4.9l0.31-2.61z"
          />
          <path
            id="XMLID_00000008145859858826389610000009055326468951470721_"
            d="m348.41 370.07-1.45 1.87 0.32 2.97c0.75-1.14 1.18-2.5 1.18-3.96 0-0.29-0.02-0.59-0.05-0.88"
          />
          <path
            id="XMLID_00000109714844068557824710000000166622926883841157_"
            d="m339.28 377.89c0.63 0.18 1.29 0.28 1.98 0.28 1.4 0 2.71-0.4 3.82-1.1l-3.3-0.51z"
          />
          <polygon
            id="XMLID_00000150801079872321605290000004591947972537502398_"
            points="343.14 368.66 339.09 368.83 338.33 372.53 341.86 374.22 344.69 371.42"
          />
          <g id="XMLID_00000008149922758641531190000000070905894714087327_">
            <path
              id="XMLID_00000126325701938645698470000000454398322893234353_"
              className="st70"
              d="m350.74 365.59v-1.16c-3.95-0.05-4.29-2.3-7.63-2.66-0.2 0.36-0.38 0.73-0.52 1.12 3.84 0.17 4 2.65 8.15 2.7"
            />
            <path
              id="XMLID_00000177478181737172153620000004386424182158120091_"
              className="st70"
              d="m334.04 361.72v1.16h5.17c0.18-0.4 0.39-0.79 0.62-1.16z"
            />
            <path
              id="XMLID_00000018920015416931176850000014809065673803359374_"
              className="st70"
              d="m334.04 360.16v1.16h6.05c0.28-0.41 0.59-0.8 0.93-1.16z"
            />
            <path
              id="XMLID_00000156581609307147069010000000302814669582665867_"
              className="st70"
              d="m350.74 364.03v-1.16c-3.53-0.04-4.17-1.84-6.64-2.48-0.28 0.31-0.54 0.65-0.76 1.01 3.13 0.43 3.54 2.58 7.4 2.63"
            />
            <path
              id="XMLID_00000048480117057151507870000006530022503870579626_"
              className="st70"
              d="m342.16 358.59h-8.12v1.16h7.37c0.44-0.42 0.92-0.8 1.43-1.13-0.21-0.02-0.44-0.03-0.68-0.03"
            />
            <path
              id="XMLID_00000054960054962815464090000017753866172221632659_"
              className="st70"
              d="m344.41 360.07c2.22 0.71 2.96 2.36 6.34 2.4v-1.16c-2.86-0.03-3.83-1.22-5.41-2.02-0.33 0.23-0.64 0.49-0.93 0.78"
            />
            <polygon
              id="XMLID_00000096775709728519167630000014936455502750303397_"
              className="st70"
              points="354.04 362.87 351.57 365.59 352.9 365.59 354.31 364.03 354.68 364.03 354.79 365.59 355.83 365.59 355.83 362.87"
            />
            <polygon
              id="XMLID_00000052805376169813816800000017844870146896617350_"
              className="st70"
              points="360.14 361.31 356.25 361.31 356.25 362.46 359.09 362.46"
            />
            <polygon
              id="XMLID_00000036969830884036527190000004718056869977951934_"
              className="st70"
              points="353.99 362.46 355.05 361.31 351.16 361.31 351.16 362.46"
            />
            <polygon
              id="XMLID_00000140003274574711182250000012565413428075653051_"
              className="st70"
              points="355.46 361.31 354.41 362.46 355.83 362.46 355.83 361.31"
            />
            <polygon
              id="XMLID_00000050632348938733515290000001132664330136019359_"
              className="st70"
              points="352.58 364.03 353.63 362.87 351.16 362.87 351.16 365.59 352.1 364.55 352.1 364.03"
            />
            <polygon
              id="XMLID_00000087395692475131687180000009324438968499369124_"
              className="st70"
              points="357.67 364.03 358.72 362.87 356.25 362.87 356.25 365.59 357.19 364.55 357.19 364.03"
            />
          </g>
          <polygon
            id="XMLID_00000042707863498874326920000011646247425670988420_"
            className="st71"
            points="349.58 369.92 351.46 370.2 351.78 372.09 352.63 370.38 354.52 370.66 353.15 369.32 354 367.61 352.31 368.5 350.95 367.16 351.27 369.04"
          />
        </g>
        <g id="g325" transform="matrix(1.4962 0 0 1.4962 .14052 .8363)">
          <path
            id="path317"
            className="st68"
            d="m40.9 314.07c25.14-0.31 53.34-0.31 78.48 0-25.14 0.31-53.34 0.31-78.48 0z"
            fill="#010202"
          />
          <circle
            id="circle317"
            className={`st54 ${selectedZones.has(1) ? 'pass-active' : ''}`}
            cx="154.77"
            cy="252.42"
            r="16.92"
            fill="#349937"
          />
          <text
            id="text317"
            className={`st77 st78 st79 ${
              selectedZones.has(1) ? 'pass-active' : ''
            }`}
            transform="translate(146.72,263.53)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            1
          </text>
          <circle
            id="circle318"
            className={`st55 ${selectedZones.has(2) ? 'pass-active' : ''}`}
            cx="194.18"
            cy="252.42"
            r="16.92"
            fill="#eaa211"
          />
          <text
            id="text318"
            className={`st77 st78 st79 ${
              selectedZones.has(2) ? 'pass-active' : ''
            }`}
            transform="translate(186.13,263.53)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            2
          </text>
          <circle
            id="circle319"
            className={`st56 ${selectedZones.has(3) ? 'pass-active' : ''}`}
            cx="237.91"
            cy="252.42"
            r="16.92"
            fill="#3c6bb3"
          />
          <text
            id="text319"
            className={`st77 st78 st79 ${
              selectedZones.has(3) ? 'pass-active' : ''
            }`}
            transform="translate(229.86,263.53)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            3
          </text>
          <circle
            id="circle320"
            className={`st57 ${selectedZones.has(4) ? 'pass-active' : ''}`}
            cx="154.77"
            cy="290.33"
            r="16.92"
            fill="#eb611a"
          />
          <text
            id="text320"
            className={`st77 st78 st79 ${
              selectedZones.has(4) ? 'pass-active' : ''
            }`}
            transform="translate(146.72,301.44)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            4
          </text>
          <circle
            id="circle321"
            className={`st58 ${selectedZones.has(5) ? 'pass-active' : ''}`}
            cx="194.67"
            cy="290.31"
            r="16.92"
            fill="#be1622"
          />
          <text
            id="text321"
            className={`st77 st78 st79 ${
              selectedZones.has(5) ? 'pass-active' : ''
            }`}
            transform="translate(186.62,301.42)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            5
          </text>
          <circle
            id="circle322"
            className={`st59 ${selectedZones.has(6) ? 'pass-active' : ''}`}
            cx="237.91"
            cy="290.33"
            r="16.92"
            fill="#309bd7"
          />
          <text
            id="text322"
            className={`st77 st78 st79 ${
              selectedZones.has(6) ? 'pass-active' : ''
            }`}
            transform="translate(229.86,301.44)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            6
          </text>
          <circle
            id="circle323"
            className={`st60 ${selectedZones.has(7) ? 'pass-active' : ''}`}
            cx="154.77"
            cy="329.57"
            r="16.92"
            fill="#a85fa2"
          />
          <text
            id="text323"
            className={`st77 st78 st79 ${
              selectedZones.has(7) ? 'pass-active' : ''
            }`}
            transform="translate(146.72,340.68)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            7
          </text>
          <circle
            id="circle324"
            className={`st61 ${selectedZones.has(8) ? 'pass-active' : ''}`}
            cx="194.67"
            cy="329.57"
            r="16.92"
            fill="#4a494a"
          />
          <text
            id="text324"
            className={`st77 st78 st79 ${
              selectedZones.has(8) ? 'pass-active' : ''
            }`}
            transform="translate(186.62,340.68)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            8
          </text>
          <circle
            id="circle325"
            className={`st62 ${selectedZones.has(9) ? 'pass-active' : ''}`}
            cx="237.91"
            cy="329.57"
            r="16.92"
            fill="#7ac3a5"
          />
          <text
            id="text325"
            className={`st77 st78 st79 ${
              selectedZones.has(9) ? 'pass-active' : ''
            }`}
            transform="translate(229.86,340.68)"
            fill="#fdfdfd"
            font-family="Helvetica-Bold"
            font-size="30px"
          >
            9
          </text>
        </g>
        {/* <image href={fafImg} x="62px" y="92px" />
        <image href={unaf24Img} x="120px" y="92px" width="42" height="42" />
        <image href={fafImg} x="509px" y="92px" />
        <image href={unaf24Img} x="567px" y="92px" width="42" height="42" /> */}
      </svg>
      <div className="text-layer">
        <span id="g254-1" className="country-text-holder">
          <span className="country-text">
            {leftCountry?.nameFr?.toLocaleUpperCase()}
          </span>
        </span>
        <span id="g254-2" className="country-text-holder">
          <span className="country-text">
            {rightCountry?.nameFr?.toLocaleUpperCase()}
          </span>
        </span>
        <span id="g258-1" className="country-text-holder">
          <span className="country-text">
            {leftCountry?.nameAr?.toLocaleUpperCase()}
          </span>
        </span>
        <span id="g258-2" className="country-text-holder">
          <span className="country-text">
            {rightCountry?.nameAr?.toLocaleUpperCase()}
          </span>
        </span>
        <ZonesInfo />
        <AgreeTerms />
        <TermsEnTitle />
        <TermsEnBody />
        <TermsFrTitle />
        <TermsFrBody />
        <span id="g237-bg" style={{ backgroundColor: functionalityBgColor }} />
        <span id="g237" style={{ color: getContrastYIQ(functionalityBgColor) }}>
          {functionality?.nameFr}
        </span>
        <div id="g231">
          <span>
            Name of Card Holder : {personFirstNameFr} {personLastNameFr}
          </span>
          <span>
            Accreditation Card Number :{' '}
            {badgeId ? badgeId.toString().padStart(6, '0') : 'Unspecifed'}
          </span>
          <span>
            Date :{' '}
            {created
              ? format(created, 'dd-MM-yyyy - HH:mm', { locale: localEn })
              : 'Unspecifed'}
          </span>
          <span>Signature : </span>
        </div>
        <div id="g233">
          <span className="badge-text-ar">
            اسم صاحب البطاقة : &#x061C;{personLastNameAr} {personFirstNameAr}
          </span>
          <span className="badge-text-ar">
            رقم بطاقة الاعتماد : &#x061C;
            {badgeId ? badgeId.toString().padStart(6, '0') : 'غير معلوم'}
          </span>
          <span className="badge-text-ar">
            تاريخ : &#x061C;{' '}
            {created
              ? format(created, 'dd-MM-yyyy - HH:mm', { locale: localAr })
              : 'غير معلوم'}
          </span>
          <span className="badge-text-ar">توقيع : &#x061C;</span>
        </div>
        <span id="g244">{location}</span>
        <div id="g240">
          <span className="pass-header">ACCREDITATION PASS</span>
          <span className="pass-title">{titleFr}</span>
          {eventDate && (
            <span className="pass-time">
              {format(eventDate, 'eeee, d LLL Y - HH:mm', { locale: localEn })}
            </span>
          )}
        </div>
        <div id="g241">
          <span className="pass-header badge-text-ar">
            بــطــــــاقـة الإعــتـمــــــاد
          </span>
          <span className="pass-title badge-text-ar">{titleAr}</span>
          {eventDate && (
            <span className="pass-time badge-text-ar">
              {format(eventDate, 'eeee, d LLL Y - HH:mm', { locale: localAr })}
            </span>
          )}
        </div>
        <div id="g239">
          <span>{personFirstNameFr}</span>
          <span>{personLastNameFr}</span>
          <span>{category?.nameFr}</span>
          {om?.nameFr && <span>{om?.nameFr}</span>}
          <span>
            {badgeId ? badgeId.toString().padStart(6, '0') : 'Unspecifed'}
          </span>
        </div>
      </div>
    </div>
  );
}

export function BadgeContainer({
  className = '',
  ...rest
}: HTMLProps<HTMLDivElement>) {
  return <div className={`badge-container ${className}`} {...rest} />;
}

export function Ring({
  className = '',
  variantColor,
  children,
  style,
  ...rest
}: HTMLProps<HTMLDivElement> & {
  variantColor: string;
}) {
  return (
    <div
      className={`ring ${className}`}
      style={{ borderColor: variantColor || style?.borderColor, ...style }}
      {...rest}
    >
      <div
        className="ring-content"
        style={{ backgroundColor: variantColor || style?.backgroundColor }}
      >
        {children}
      </div>
    </div>
  );
}

export function BadgeZone({
  className = '',
  style,
  variantColor,
  active,
  zone,
  ...rest
}: HTMLProps<HTMLDivElement> & {
  zone?: string | number;
  variantColor?: string;
  active?: boolean;
  children?: never;
}) {
  return (
    <div
      className={`badge-zone ${
        active === false ? 'inactive' : ''
      } ${className}`}
      style={{ borderColor: variantColor || style?.borderColor, ...style }}
      {...rest}
    >
      <span>{zone}</span>
    </div>
  );
}

export function BadgeBanner({
  className = '',
  ...rest
}: HTMLProps<HTMLDivElement>) {
  return (
    <div className={`badge-banner ${className}`} {...rest}>
      <div className="app-title">{t('register-participation-message')}</div>
    </div>
  );
}
