import { createContext, useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useClientAuth } from 'lib/access';

type AuthContextType = ReturnType<typeof useClientAuth>;

const authContext = createContext<AuthContextType>(undefined!);

interface AuthRouterProps {
  children: React.ReactNode;
}

export default function AuthRouter({ children }: AuthRouterProps) {
  const auth = useClientAuth();
  return (
    <BrowserRouter>
      <authContext.Provider value={auth}>{children}</authContext.Provider>
    </BrowserRouter>
  );
}

export function useAuth() {
  return useContext(authContext);
}
