import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import Button, { ModalCloseButton } from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput from 'components/icon-input/IconInput';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import Picture from 'components/picture/Picture';
import { useEncryption } from 'lib/encryption';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { PageModalUrlParams, PathLocationDto } from 'utils/utils';
import { LocationDto } from '../../../../shared/dtos/location.dto';
import { SearchPaginate } from '../../../../shared/types';
import './Location.css';

export default function Location() {
  const [data, setData] = useState<LocationDto[]>();
  const history = useHistory<PathLocationDto>();
  const { encrypt, decrypt } = useEncryption();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [perPage, setPerPage] = useState<number>(24);
  const [total, setTotal] = useState<number>(0);
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<never, AxiosResponse<SearchPaginate<LocationDto>>>(
        `/location/${newPage - 1}/${newPerPage}/${searchValue}`,
      );
      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page container-fluid ${isRtl ? 'rtl' : 'ltr'}`}>
      <Route
        path="/location/view/:encryptedId"
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedLocation = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedLocation) {
            return null;
          }

          return <ViewLocation {...selectedLocation} />;
        }}
      />
      <Route path="/location/add">
        <FormLocation onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <Route
        path={'/location/edit/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedLocation = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedLocation) {
            return null;
          }

          return (
            <FormLocation
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedLocation}
            />
          );
        }}
      />
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: LocationLabelDto = {
                sheet: t('sidebar.location'),
                title: t('pages-titles.location'),
                number: t('forms-labels.number'),
                nameFr: t('forms-labels.location'),
                nameAr: t('forms-labels.location'),
                addressFr: t('forms-labels.address'),
                addressAr: t('forms-labels.address'),
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'location/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          <Button
            btnRole="primary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={() => history.push('/location/add')}
          >
            {t('location.add-new-button')}
          </Button>
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <CardsContainer
            direction={isRtl ? 'rtl' : 'ltr'}
            cardsDirection="horizontal"
          >
            {data.map(
              ({ id, nameFr, nameAr, addressFr, addressAr, image }, index) => {
                const viewDetailsBtn = (
                  <Button
                    className={`slidable ${
                      isRtl ? 'slidable-left' : 'slidable-right'
                    } app-small-text d-flex`}
                    onClick={() => {
                      history.push(
                        `/location/view/${encrypt(id?.toString() || '')}`,
                      );
                    }}
                  >
                    <span className="view-details-button-text">
                      {t('details-button')}
                    </span>
                    <ArrowRightSvg
                      className={`card-link-icon svg-icon ${
                        isRtl ? 'rtl' : 'ltr'
                      }`}
                    />
                  </Button>
                );
                const card = (
                  <Card
                    key={index}
                    imageName={image}
                    cardTitle={isRtl ? nameAr : nameFr}
                    commentType="place"
                    comment={isRtl ? addressAr : addressFr}
                    detailsToggle={viewDetailsBtn}
                  />
                );
                return card;
              },
            )}
          </CardsContainer>
          <Paginator
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

function ViewLocation({
  image,
  nameFr,
  nameAr,
  addressFr,
  addressAr,
}: LocationDto) {
  const history = useHistory<PathLocationDto>();
  const { encryptedId } = useParams<PageModalUrlParams>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  const bottomBtns = (
    <Button
      btnRole="primary"
      className="ml-4"
      onClick={() => history.push(`/location/edit/${encryptedId}`)}
    >
      {t('modify-info-button')}
    </Button>
  );

  const modalCloseButton = (
    <ModalCloseButton onClick={() => history.push('/location')} />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      onRequestClose={() => history.push('/location')}
      bottomButtons={bottomBtns}
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div className="location-info">
        <Picture defaultImageName={image} className="mb-3" />
        <div className="general-info-title app-title mt-3 mb-3">
          {t('general-info')}
        </div>
        <div className="row">
          <div className="col-5 app-emphasis">{t('forms-labels.name')}</div>
          <div className="col-7 app-text">{isRtl ? nameAr : nameFr}</div>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">{t('forms-labels.address')}</div>
          <div className="col-7 app-text">{isRtl ? addressAr : addressFr}</div>
        </div>
      </div>
    </Modal>
  );
}

function FormLocation({
  id,
  nameFr,
  nameAr,
  addressFr,
  addressAr,
  image,
  onAfterSubmission: afterSubmission,
}: LocationDto & { onAfterSubmission?: Function }) {
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      image,
      nameFr,
      nameAr,
      addressFr,
      addressAr,
    },
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const formType = pathname.startsWith('/location/edit/') ? 'edit' : 'add';
  const formMethod = formType === 'add' ? 'post' : 'put';
  const formAction =
    formType === 'add' ? '/location/add' : `/location/update/${id}`;
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    history.replace('/location');
  };
  const submit = async (data: LocationDto) => {
    setModalRole('processing');

    try {
      await axios[formMethod](formAction || pathname, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error: any) {
      setModalRole('content');
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton onClick={() => history.push('/location')} />
  );

  const bottomBtns = (
    <Button
      className={isRtl ? 'mr-3' : 'ml-3'}
      btnRole="primary"
      type="submit"
      form="location-form"
      style={{ width: '145px' }}
    >
      {t('save-button')}
    </Button>
  );

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      successMessage={
        formType === 'add'
          ? t('location.add-success-message')
          : t('location.modify-success-message')
      }
      successButtons={successButtons}
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={reset}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form id="location-form" onSubmit={handleSubmit(submit)}>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="name-fr">
                  {t('forms-labels.image')}
                </label>
              </div>
              <div className="col-md-10 mb-3">
                <Controller
                  control={control}
                  name="image"
                  render={({ field: { value, onChange } }) => (
                    <Picture
                      required
                      enableUpload
                      defaultImageName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="name-fr">
                  {t('forms-labels.name')}
                </label>
              </div>
              <div className="form-group col-md-5 ltr">
                <Controller
                  control={control}
                  name="nameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Nom en français"
                      required
                      id="name-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group col-md-5 rtl">
                <Controller
                  control={control}
                  name="nameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الإسم بالعربية"
                      required
                      id="name-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="address-fr"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '160px' }}
                >
                  {t('forms-labels.address')}
                </label>
              </div>
              <div className="form-group col-md-5 ltr">
                <Controller
                  control={control}
                  name="addressFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Adresse géographique"
                      required
                      id="address-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group col-md-5 rtl">
                <Controller
                  control={control}
                  name="addressAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الموقع الجغرافي"
                      required
                      id="address-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
