import { CountryDto, countries } from 'utils/countries';
import { DemandBadgeTile, getContrastYIQ, objectIsEmpty } from 'utils/utils';
import { MatchDto } from '../../../../../shared/dtos/match.dto';
import Badge, { BadgeBanner } from 'components/badge/Badge';
import { FunctionalityDto } from '../../../../../shared/dtos/function.dto';
import { useEffect, useState } from 'react';
import { EditJournalistDto } from '../../../../../shared/dtos/journalist.dto';
import axios, { AxiosResponse } from 'axios';
import { LocationDto } from '../../../../../shared/dtos/location.dto';
import { DemandOmDto } from '../../../../../shared/dtos/om.dto';

export interface TileBadgeProps {
  tile: DemandBadgeTile;
  match: MatchDto | undefined;
  nameFr?: string;
  nameAr?: string;
  location?: LocationDto;
  eventDate?: string;
  om?: DemandOmDto;
  template?: string;
}

export function TileBadge({
  tile,
  match,
  nameFr,
  nameAr,
  location,
  eventDate,
  om,
  template,
}: TileBadgeProps) {
  const badge = tile.badge!;
  const journalist = tile.demand?.journalist!;

  let [titleFr, setTitleFr] = useState('');
  let [titleAr, setTitleAr] = useState('');
  let [leftImageName, setLeftImageName] = useState('');
  let [rightImageName, setRightImageName] = useState('');
  let [leftCountry, setLeftCountry] = useState<CountryDto>();
  let [rightCountry, setRightCountry] = useState<CountryDto>();
  let [subtitle, setSubtitle] = useState('');
  let [functionality, setFunctionality] = useState<FunctionalityDto>();

  useEffect(() => {
    if (!match) {
      // event type is union
      setTitleFr(nameFr || '');
      setTitleAr(nameAr || '');
      setLeftImageName('');
      setRightImageName('');
      setSubtitle('Réunion Générale');
      setLeftCountry(undefined);
      setRightCountry(undefined);
      return;
    }
    // event type is match
    setTitleFr(match.competition?.nameFr || '');
    setTitleAr(match.competition?.nameAr || '');
    setLeftImageName(match.competition?.competitionLogo || '');
    setRightImageName(match.competition?.unitedLogo || '');
    const receiverCountry = countries.find(
      ({ alpha2 }) => alpha2 === match?.receiverTeam?.trim(),
    );
    const visitorCountry = countries.find(
      ({ alpha2 }) => alpha2 === match?.visitorTeam?.trim(),
    );
    setSubtitle(`${receiverCountry?.nameFr} vs ${visitorCountry?.nameFr}`);
    setLeftCountry(receiverCountry);
    setRightCountry(visitorCountry);
  }, [match, nameAr, nameFr]);

  useEffect(() => {
    const functionId = (tile.demand?.journalist as
      | EditJournalistDto
      | undefined)?.functionId;

    if (functionId === undefined) {
      setFunctionality(undefined);
      return;
    }

    void axios
      .get<never, AxiosResponse<FunctionalityDto>>(`/function/${functionId}`)
      .then(({ data }) => setFunctionality(data));
  }, [tile.demand]);

  return !objectIsEmpty(badge) ? (
    <Badge
      badgeId={badge.badgeId}
      titleFr={titleFr}
      titleAr={titleAr}
      isPrinting
      leftImageName={leftImageName}
      rightImageName={rightImageName}
      leftCountry={leftCountry}
      rightCountry={rightCountry}
      personImageName={journalist.photo}
      subtitle={subtitle}
      personFirstNameFr={journalist.firstNameFr}
      personLastNameFr={journalist.lastNameFr}
      personFirstNameAr={journalist.firstNameAr}
      personLastNameAr={journalist.lastNameAr}
      category={functionality?.category ?? journalist?.function?.category}
      functionality={functionality ?? journalist?.function}
      variantColor={journalist.function?.category?.color}
      alpha2={journalist.nationality}
      categoryTextColor={getContrastYIQ(
        journalist.function?.category?.color || '#ffffff',
      )}
      zones={
        Array.from(badge?.zones?.map((v: any) => parseInt(v)) || []) as number[]
      }
      location={location?.nameFr}
      created={new Date()}
      eventDate={eventDate ? new Date(eventDate) : undefined}
      om={om}
      template={template}
    />
  ) : (
    <BadgeBanner />
  );
}
