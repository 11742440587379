import { useAutoTrans } from 'components/auto-translation/AutoTranslation';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { NamedProps } from 'react-select';
import { AsyncPaginate as ReactAsyncPaginate } from 'react-select-async-paginate';
import ReactAsyncSelect from 'react-select/async';
import ReactCreatableSelect from 'react-select/creatable';
import { languagesOptions, OptionType } from 'utils/utils';
import variables from 'utils/_vars.module.scss';
import './Select.css';

export interface ValidationProps {
  form?: string;
  required?: boolean;
}

export default function Select({
  className = '',
  styles,
  form,
  defaultValue,
  value,
  onChange,
  required,
  ...rest
}: NamedProps & ValidationProps) {
  const [option, setOption] = useState(defaultValue || value);
  const [invalid, setInvalid] = useState<boolean>(false);
  styles = {
    menuPortal: (styles) => ({ ...styles, zIndex: 6 }),
    option: (optionStyles, { isSelected, isFocused }) => {
      return {
        ...optionStyles,
        backgroundColor: isSelected
          ? variables['primary-color']
          : isFocused
          ? variables['hover-grey-color']
          : undefined,
      };
    },
    ...styles,
  };
  const onInvalid = () => {
    if (invalid) return;

    setInvalid(true);
    setTimeout(() => setInvalid(false), 1100);
  };

  useEffect(() => setOption(value), [value]);

  return (
    <>
      <ReactSelect
        styles={styles}
        className={`app-select ${className} ${invalid ? 'invalid-select' : ''}`}
        defaultValue={defaultValue}
        value={option}
        onChange={(value, action) => {
          onChange?.(value, action);
          setOption(value);
        }}
        {...rest}
      />
      {required ? (
        <input
          className="d-none"
          form={form}
          required
          onChange={() => {}}
          onInvalid={onInvalid}
          value={(option as OptionType)?.value || ''}
        />
      ) : null}
    </>
  );
}

export function CreatableSelect({
  className = '',
  styles,
  form,
  defaultValue,
  value,
  onChange,
  required,
  ...rest
}: React.ComponentProps<typeof ReactCreatableSelect> & ValidationProps) {
  const [option, setOption] = useState(defaultValue || value);
  const [invalid, setInvalid] = useState<boolean>(false);
  styles = {
    menuPortal: (styles) => ({ ...styles, zIndex: 6 }),
    option: (optionStyles, { isSelected, isFocused }) => {
      return {
        ...optionStyles,
        backgroundColor: isSelected
          ? variables['primary-color']
          : isFocused
          ? variables['hover-grey-color']
          : undefined,
      };
    },
    ...styles,
  };
  const onInvalid = () => {
    if (invalid) return;

    setInvalid(true);
    setTimeout(() => setInvalid(false), 1100);
  };

  useEffect(() => setOption(value), [value]);

  return (
    <>
      <ReactCreatableSelect
        styles={styles}
        className={`app-creatable ${className} ${
          invalid ? 'invalid-select' : ''
        }`}
        defaultValue={defaultValue}
        value={option}
        onChange={(value, action) => {
          onChange?.(value, action);
          setOption(value);
        }}
        {...rest}
      />
      {required ? (
        <input
          className="d-none"
          form={form}
          required
          onChange={() => {}}
          onInvalid={onInvalid}
          value={(option as OptionType)?.value || ''}
        />
      ) : null}
    </>
  );
}

export function AsyncSelect({
  className = '',
  styles,
  form,
  defaultValue,
  value,
  onChange,
  required,
  ...rest
}: React.ComponentProps<typeof ReactAsyncSelect> & ValidationProps) {
  const [option, setOption] = useState(defaultValue);
  const [invalid, setInvalid] = useState<boolean>(false);
  styles = {
    menuPortal: (styles) => ({ ...styles, zIndex: 6 }),
    option: (optionStyles, { isSelected, isFocused }) => {
      return {
        ...optionStyles,
        backgroundColor: isSelected
          ? variables['primary-color']
          : isFocused
          ? variables['hover-grey-color']
          : undefined,
      };
    },
    ...styles,
  };
  const onInvalid = () => {
    if (invalid) return;

    setInvalid(true);
    setTimeout(() => setInvalid(false), 1100);
  };

  useEffect(() => setOption(value), [value]);

  return (
    <>
      <ReactAsyncSelect
        styles={styles}
        className={`app-async ${className} ${invalid ? 'invalid-select' : ''}`}
        defaultValue={defaultValue}
        value={option}
        onChange={(value, action) => {
          onChange?.(value, action);
          setOption(value);
        }}
        {...rest}
      />
      {required ? (
        <input
          className="d-none"
          form={form}
          required
          onChange={() => {}}
          onInvalid={onInvalid}
          value={(option as OptionType)?.value || ''}
        />
      ) : null}
    </>
  );
}

export function AsyncPaginate({
  className = '',
  styles,
  form,
  defaultValue,
  value,
  onChange,
  required,
  ...rest
}: React.ComponentProps<typeof ReactAsyncPaginate> & ValidationProps) {
  const [option, setOption] = useState(defaultValue || value);
  const [invalid, setInvalid] = useState<boolean>(false);
  styles = {
    menuPortal: (styles) => ({ ...styles, zIndex: 6 }),
    option: (optionStyles, { isSelected, isFocused }) => {
      return {
        ...optionStyles,
        backgroundColor: isSelected
          ? variables['primary-color']
          : isFocused
          ? variables['hover-grey-color']
          : undefined,
      };
    },
    ...styles,
  };
  const onInvalid = () => {
    if (invalid) return;

    setInvalid(true);
    setTimeout(() => setInvalid(false), 1100);
  };

  useEffect(() => setOption(value), [value]);

  return (
    <>
      <ReactAsyncPaginate
        styles={styles}
        className={`app-async ${className} ${invalid ? 'invalid-select' : ''}`}
        defaultValue={option}
        value={option}
        onChange={(value, action) => {
          onChange?.(value, action);
          setOption(value);
        }}
        {...rest}
      />
      {required ? (
        <input
          className="d-none"
          form={form}
          required
          onChange={() => {}}
          onInvalid={onInvalid}
          value={(option as OptionType)?.value || ''}
        />
      ) : null}
    </>
  );
}

export function FloatingLanguageSelect(
  args: React.ComponentProps<typeof Select>,
) {
  const {
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  return (
    <div className={`language-select-container ${isRtl ? 'rtl' : ''}`}>
      <LanguageSelect {...args} />
    </div>
  );
}

export function LanguageSelect({
  value: _,
  options: __,
  onChange: ___,
  ...rest
}: React.ComponentProps<typeof Select>) {
  const { language, changeLanguage } = useAutoTrans();

  return (
    <Select
      {...rest}
      isSearchable={false}
      value={languagesOptions.find(
        ({ value: optionValue }) => optionValue === language,
      )}
      options={languagesOptions}
      onChange={(option) => changeLanguage(option?.value || 'fr')}
    />
  );
}
