import { generate as generateRandomString } from 'randomstring';
import aesjs from 'aes-js';

// 128-bit encryption key (16 bytes * 8 bits/byte = 128 bits).
// see useEncryption() for more.
const key = generateRandomString({
  length: 16,
  charset: 'numeric',
})
  .split('')
  .map((n) => parseInt(n));

export function useEncryption() {
  function encrypt(plainText: string) {
    // Convert text to bytes
    const textBytes = aesjs.utils.utf8.toBytes(plainText);
    // Encrypt text bytes
    const aesCtr = new aesjs.ModeOfOperation.ctr(key);
    const encryptedBytes = aesCtr.encrypt(textBytes);
    // Convert text bytes to hex string
    const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  }
  function decrypt(encryptedHex: string) {
    // Convert encrypted hex to bytes
    const encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);
    // Decrypt bytes
    const aesCtr = new aesjs.ModeOfOperation.ctr(key);
    const decryptedBytes = aesCtr.decrypt(encryptedBytes);
    // Convert text bytes to string
    const plainText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    return plainText;
  }

  return { encrypt, decrypt };
}
