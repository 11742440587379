import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import frTranslation from './locales/fr/translation.json';
import arTranslation from './locales/ar/translation.json';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'fr',
    lng: localStorage.getItem('lng-pref') || 'fr',
    supportedLngs: ['fr', 'ar'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    fallbackNS: 'translation',
    returnObjects: true,
    resources: {
      fr: { translation: frTranslation },
      ar: { translation: arTranslation },
    },
  });
export default i18n;
