import Badge, { BadgeBanner } from 'components/badge/Badge';
import { getContrastYIQ, objectIsEmpty } from 'utils/utils';
import { BadgeDto } from '../../../../../shared/dtos/badge.dto';
import { CountryDto } from 'utils/countries';
import {
  EditJournalistDto,
  GetJournalistDto,
} from '../../../../../shared/dtos/journalist.dto';
import { useEffect, useState } from 'react';
import { FunctionalityDto } from '../../../../../shared/dtos/function.dto';
import axios, { AxiosResponse } from 'axios';
import { LocationDto } from '../../../../../shared/dtos/location.dto';
import { DemandOmDto } from '../../../../../shared/dtos/om.dto';

export interface DemandSelectedBadgeProps {
  badge: BadgeDto | undefined;
  titleFr?: string;
  titleAr?: string;
  leftImageName?: string;
  rightImageName?: string;
  leftCountry?: CountryDto;
  rightCountry?: CountryDto;
  badgeJournalist?: GetJournalistDto;
  subtitle?: string;
  location?: LocationDto;
  eventDate?: string;
  om?: DemandOmDto;
  templateUrl?: string;
}

export function DemandSelectedBadge({
  badge,
  titleFr,
  titleAr,
  leftImageName,
  rightImageName,
  leftCountry,
  rightCountry,
  badgeJournalist,
  subtitle,
  location,
  eventDate,
  om,
  templateUrl,
}: DemandSelectedBadgeProps) {
  let [functionality, setFunctionality] = useState<FunctionalityDto>();

  useEffect(() => {
    const functionId = (badgeJournalist as EditJournalistDto)?.functionId;

    if (functionId === undefined) {
      setFunctionality(undefined);
      return;
    }

    void axios
      .get<never, AxiosResponse<FunctionalityDto>>(`/function/${functionId}`)
      .then(({ data }) => setFunctionality(data));
  }, [badgeJournalist]);

  return !objectIsEmpty(badge) ? (
    <Badge
      badgeId={badge?.badgeId}
      titleFr={titleFr}
      titleAr={titleAr}
      leftImageName={leftImageName}
      rightImageName={rightImageName}
      leftCountry={leftCountry}
      rightCountry={rightCountry}
      personImageName={badgeJournalist?.photo}
      subtitle={subtitle}
      personFirstNameFr={badgeJournalist?.firstNameFr}
      personLastNameFr={badgeJournalist?.lastNameFr}
      personFirstNameAr={badgeJournalist?.firstNameAr}
      personLastNameAr={badgeJournalist?.lastNameAr}
      category={functionality?.category}
      functionality={functionality}
      variantColor={badgeJournalist?.function?.category?.color}
      alpha2={badgeJournalist?.nationality}
      categoryTextColor={getContrastYIQ(
        badgeJournalist?.function?.category?.color || '#ffffff',
      )}
      om={om}
      zones={Array.from(badge?.zones || []) as number[]}
      location={location?.nameFr}
      created={new Date()}
      eventDate={eventDate ? new Date(eventDate) : undefined}
      templateUrl={templateUrl}
    />
  ) : (
    <BadgeBanner />
  );
}
