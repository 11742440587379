import './PointsGroup.css';
import { HTMLProps, ReactNode } from 'react';

export interface PointsGroupProps extends HTMLProps<HTMLDivElement> {
  count: number;
  selectedPoint?: number | boolean[];
  children?: never;
}

export default function PointsGroup({
  className = '',
  count = 1,
  selectedPoint = -1,
  ...rest
}: PointsGroupProps) {
  if (Array.isArray(selectedPoint)) {
    // change selectedPoint from array to index
    selectedPoint = selectedPoint.indexOf(true);
  }

  let points: ReactNode[] = [];
  for (let index = 0; index < count; index++) {
    points.push(
      <div
        key={index}
        className={`group-point ${
          index === selectedPoint ? 'selected-point' : ''
        }`}
      />,
    );
  }
  return (
    <div className={`points-group ${className}`} {...rest}>
      {points}
    </div>
  );
}
