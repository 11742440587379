import './Modal.css';
import React from 'react';
import ReactModal, { Props as ReactModalProps } from 'react-modal';
import { ReactComponent as SuccessSvg } from 'assets/success.svg';
import PointsGroup, {
  PointsGroupProps,
} from 'components/points-group/PointsGroup';
import { CircularIndicator } from 'components/loading-indicator/LoadingIndicator';

export type ModalRole = 'content' | 'processing' | 'success';

export interface ModalProps extends ReactModalProps {
  modalRole?: ModalRole;
  children?: React.ReactNode;
  bottomButtons?: React.ReactNode;
  topRightButtons?: React.ReactNode;
  successButtons?: React.ReactNode;
  hideOverflow?: boolean;
  direction?: 'ltr' | 'rtl';
  bottomButtonsPosition?: 'start' | 'center' | 'end';
  pointsOptions?: PointsGroupProps & { enable?: boolean };
  successMessage?: React.ReactNode;
}

export function Modal({
  modalRole = 'content',
  pointsOptions,
  children,
  direction = 'ltr',
  bottomButtons,
  successMessage,
  successButtons,
  hideOverflow = false,
  topRightButtons,
  bottomButtonsPosition = 'end',
  className = '',
  ...rest
}: ModalProps) {
  return (
    <ReactModal
      className={`app-modal ${className} ${modalRole} ${direction}`}
      {...rest}
    >
      <div className="modal-page">
        <div
          className={`modal-content-scroll ${
            bottomButtons && modalRole === 'content' ? 'trim-bottom' : ''
          }`}
        >
          <div
            className={`modal-content-center ${
              hideOverflow ? 'overflow-hidden' : ''
            } ${modalRole !== 'content' ? 'offset-bottom' : ''} ${
              modalRole === 'success' ? 'success' : ''
            }`}
          >
            {modalRole === 'success' ? (
              <>
                <SuccessSvg />
                <span className="modal-success-message mt-5 app-heading-3">
                  {successMessage}
                </span>
                <div className="modal-success-actions mt-5">
                  {successButtons}
                </div>
              </>
            ) : modalRole === 'processing' ? (
              <CircularIndicator />
            ) : (
              children
            )}
          </div>
        </div>
        {modalRole === 'content' ? (
          <div className="modal-bottom">
            <div className="modal-tabs">
              {pointsOptions && pointsOptions.enable !== false ? (
                <PointsGroup {...pointsOptions} />
              ) : null}
            </div>
            <div className={`modal-bottom-actions ${bottomButtonsPosition}`}>
              {bottomButtons}
            </div>
          </div>
        ) : null}
      </div>
      {modalRole === 'content' ? (
        <div className="modal-top-right-actions">{topRightButtons}</div>
      ) : null}
    </ReactModal>
  );
}
