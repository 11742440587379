import { PathLocationDto } from 'utils/utils';
import { Redirect, Route, RouteProps, useLocation } from 'react-router';
import { useAuth } from './AuthRouter';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';

export default function LoginRoute({ children, render, ...rest }: RouteProps) {
  const auth = useAuth();
  const location = useLocation<PathLocationDto>();

  render = () => {
    if (auth.status === 'authorized') {
      const { from } = location.state || { from: { pathname: '/home' } };

      return <Redirect to={from} />;
    } else if (auth.status === 'unchecked') {
      return <PulseIndicator fullscreen />;
    } else {
      return children;
    }
  };

  return <Route {...rest} render={render} />;
}
