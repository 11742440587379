import './Picture.css';
import {
  ChangeEventHandler,
  CSSProperties,
  HTMLProps,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getType } from 'mime';
import { ReactComponent as UploadSvg } from 'assets/upload.svg';
import { downloadFile, uploadFile } from 'lib/file-transfer';
import { ReactComponent as ImageNotFoundSvg } from 'assets/image-not-found.svg';
import { CircularIndicator } from 'components/loading-indicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { toBase64 } from 'utils/utils';

export interface PictureProps extends HTMLProps<HTMLDivElement> {
  defaultImageName?: string | 'no-image';
  imageName?: string | 'no-image';
  fallbackImage?: React.ReactNode;
  defaultImageData?: string | 'no-data';
  enableUpload?: boolean;
  localUploadOnly?: boolean;
  imageFit?: 'cover' | 'contain';
  type?: 'circle' | 'rect' | 'soft-rect';
  imgStyle?: CSSProperties;
  imgClassname?: string;
  onNameChange?: (name: string) => any;
  onDataLoad?: (data: string) => any;
}

export default function Picture({
  imgStyle,
  imgClassname,
  type = 'soft-rect',
  defaultImageName = '',
  fallbackImage,
  defaultImageData = '',
  className = '',
  imageName: imgName = '',
  enableUpload = false,
  localUploadOnly = false, // FIXME: this is broken when used with downloaded images
  imageFit = 'cover',
  onNameChange,
  onDataLoad,
  required,
  ...rest
}: PictureProps) {
  const [imageName, setImageName] = useState(imgName || defaultImageName);
  const [imageData, setImageData] = useState(defaultImageData);
  const [invalid, setInvalid] = useState(false);
  const isMounted = useRef(false);
  const { t } = useTranslation();
  const uploadImage: ChangeEventHandler<HTMLInputElement> = async (e) => {
    e.preventDefault();
    const file = e.target.files?.item(0);

    // check user chose a file (did not cancel), and the file is an image.
    if (!file || !file.type.startsWith('image/')) return;

    if (localUploadOnly) {
      try {
        const data64 = (await toBase64(file))?.split(';base64,')[1];

        if (data64) {
          const fileName = 'local-upload-' + file.name;
          setImageName(fileName);
          onNameChange?.(fileName);
          setImageData(data64);
          onDataLoad?.(data64);
        }
      } catch (error) {
        setImageName('');
        onNameChange?.('');
        setImageData('no-data');
        onDataLoad?.('');
      }

      return;
    }

    try {
      const name = await uploadFile(file);
      setImageName(name);
      onNameChange?.(name);
    } catch (error) {
      console.error(error);
    }
  };
  const onInvalid = () => {
    if (invalid) return;

    setInvalid(true);
    setTimeout(() => setInvalid(false), 1100);
  };

  useEffect(() => {
    if (isMounted.current) {
      setImageName(imgName);
    } else {
      isMounted.current = true;
    }
  }, [imgName]);

  useEffect(() => {
    if (!imageName || (localUploadOnly && imageName !== defaultImageName)) {
      return;
    }

    downloadFile(imageName)
      .then((data) => {
        const base64Data = data.toString('base64');
        setImageData(base64Data);
        onDataLoad?.(base64Data);
      })
      .catch((error) => {
        console.error(error);
        setImageName('');
        onNameChange?.('');
        setImageData('no-data');
        onDataLoad?.('');
      });
  }, [imageName, onDataLoad, onNameChange, localUploadOnly, defaultImageName]);

  return (
    <div className={`app-picture ${type} ${imageFit} ${className}`} {...rest}>
      {required ? (
        <input
          className="d-none"
          type="text"
          required
          pattern={/^(?!no-data).*$/.source}
          onInvalid={onInvalid}
          value={imageData}
          onChange={() => {}}
        />
      ) : null}
      {imageName && imageData && imageData !== 'no-data' ? (
        <img src={`data:${getType(imageName)};base64,${imageData}`} alt="" />
      ) : null}
      {(!imageName && !imageData && !enableUpload) ||
      imageData === 'no-data' ? ( // no image name provided or could not get the data by that name.
        fallbackImage ? (
          fallbackImage
        ) : (
          <ImageNotFoundSvg style={imgStyle} className={imgClassname} />
        )
      ) : fallbackImage && !imageData ? (
        fallbackImage
      ) : null}
      {enableUpload ? ( // show upload input
        <label
          className={`image-uploader ${
            imageData || fallbackImage ? 'on-hover' : ''
          }`}
        >
          <input type="file" onChange={uploadImage} accept=".jpeg,.jpg,.png" />
          <UploadSvg style={imgStyle} className={imgClassname} />
          <span className="upload-picture-text">{t('upload-picture')}</span>
        </label>
      ) : null}
      {imageName && !imageData ? ( // image is still loading
        <CircularIndicator
          className={imgClassname}
          svgStyle={{
            width: imgStyle?.width || '90px',
            height: imgStyle?.height || '90px',
            ...imgStyle,
          }}
        />
      ) : null}
      {invalid ? <div className="invalid-component" /> : null}
    </div>
  );
}
