import './404.css';
import { ReactComponent as Icon404Svg } from 'assets/404.svg';
import { HTMLProps } from 'react';
import Button from 'components/button/Button';
import { useHistory } from 'react-router';
import { t } from 'i18next';

export interface Page404Props extends HTMLProps<HTMLDivElement> {
  children?: never;
}

export function Page404({ className = '', ...rest }: Page404Props) {
  const history = useHistory();

  className = `page-404 ${className}`;

  return (
    <div className={className} {...rest}>
      <Icon404Svg />
      <div className="page-404-desc app-heading-3 text-center mt-4">
        {t('error-404.inaccessible-page')}.
      </div>
      <Button
        className="mt-5"
        btnRole="primary"
        btnSize="lg"
        onClick={() => history.push('/home')}
      >
        {t('error-404.go-home-button')}
      </Button>
    </div>
  );
}
