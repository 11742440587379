import { ReactComponent as ArrowLeftSvg } from 'assets/arrow-left.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as PhoneSvg } from 'assets/phone.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import Button, { FileButton, ModalCloseButton } from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import { CountryFlag } from 'components/country/Flag';
import { CountryOption } from 'components/country/Option';
import DatePicker from 'components/date-picker/DatePicker';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput, { DialIconInput } from 'components/icon-input/IconInput';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import Picture from 'components/picture/Picture';
import { useAuth } from 'components/routes/AuthRouter';
import Select, { AsyncPaginate } from 'components/select/Select';
import localAr from 'date-fns/locale/ar-DZ';
import localFr from 'date-fns/locale/fr';
import { loadCountriesOptions } from 'lib/countries-with-icons';
import { useEncryption } from 'lib/encryption';
import moment from 'moment';
import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';
import { ValueType } from 'react-select';
import { OptionsList } from 'react-select-async-paginate';
import { toast } from 'react-toastify';
import { countries } from 'utils/countries';
import {
  OptionType,
  PageModalUrlParams,
  PathLocationDto,
  StandAloneFormModalProps,
  StandAloneViewModalProps,
  alpha2ToOptionType,
  dataArrayToOptions,
  dataToOption,
  formatDzPhoneNumber,
  getPage,
  normalizeToastMessage,
  personToFullname,
  suspensionOptions,
} from 'utils/utils';
import { CategoryDto } from '../../../../shared/dtos/category.dto';
import { GetJournalistDto } from '../../../../shared/dtos/journalist.dto';
import { OmDto } from '../../../../shared/dtos/om.dto';
import { PhoneBookDto } from '../../../../shared/dtos/phone-book.dto';
import {
  Paginate,
  SearchPaginate,
  StatusFilter,
} from '../../../../shared/types';
import './Journalist.css';

export default function Journalist() {
  const [data, setData] = useState<GetJournalistDto[]>();
  const [perPage, setPerPage] = useState<number>(24);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const history = useHistory<PathLocationDto>();
  const auth = useAuth();
  const { encrypt, decrypt } = useEncryption();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<
        never,
        AxiosResponse<SearchPaginate<GetJournalistDto>>
      >(`/journalist/${newPage - 1}/${newPerPage}/${searchValue}`);
      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
      setPage(newPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page container-fluid ${isRtl ? 'rtl' : 'ltr'}`}>
      <Route
        path="/journalist/view/:encryptedId"
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedJournalist = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedJournalist) {
            return null;
          }

          return (
            <ViewJournalist
              onQuickUpdate={() => updateCards(1, perPage)}
              {...selectedJournalist}
            />
          );
        }}
      />
      <Route path="/journalist/add">
        <FormJournalist onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <Route
        path={'/journalist/edit/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedJournalist = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedJournalist) {
            return null;
          }

          return (
            <FormJournalist
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedJournalist}
            />
          );
        }}
      />
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: JournalistLabelDto = {
                sheet: t('sidebar.journalist'),
                title: t('pages-titles.journalist'),
                number: t('forms-labels.number'),
                fullNameFr: t('forms-labels.full-name'),
                fullNameAr: t('forms-labels.full-name'),
                function: {
                  nameAr: t('forms-labels.function'),
                  nameFr: t('forms-labels.function'),
                  category: {
                    nameAr: t('forms-labels.category'),
                    nameFr: t('forms-labels.category'),
                  },
                },
                email: t('forms-labels.email'),
                birthPlace: t('forms-labels.birthplace'),
                birthday: t('forms-labels.birthday'),
                issueDate: t('forms-labels.card-edition-date'),
                nationality: t('forms-labels.nationality'),
                phones: {
                  mobile: t('forms-labels.phone-number'),
                  mobile2: t('forms-labels.phone-number2'),
                  fax: t('forms-labels.fax-number'),
                },
                om: {
                  sheet: t('sidebar.om'),
                  title: t('pages-titles.om'),
                  number: t('forms-labels.number'),
                  nameFr: t('forms-labels.om'),
                  nameAr: t('forms-labels.om'),
                },
                suspended: {},
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'journalist/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          {auth.user?.userType === 'om' ? (
            <Button
              btnRole="primary"
              className={isRtl ? 'mr-3' : 'ml-3'}
              onClick={() => history.push('/journalist/add')}
            >
              {t('journalist.add-new-button')}
            </Button>
          ) : undefined}
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <CardsContainer
            direction={isRtl ? 'rtl' : 'ltr'}
            cardsDirection="horizontal"
          >
            {data.map(
              (
                {
                  id,
                  photo,
                  firstNameFr,
                  lastNameFr,
                  firstNameAr,
                  lastNameAr,
                  suspended,
                  status,
                  function: functionality,
                  om,
                },
                index,
              ) => {
                const viewDetailsBtn = (
                  <Button
                    className={`slidable ${
                      isRtl ? 'slidable-left' : 'slidable-right'
                    } app-small-text d-flex`}
                    onClick={() => {
                      history.push(
                        `/journalist/view/${encrypt(id?.toString() || '')}`,
                      );
                    }}
                  >
                    <span className="view-details-button-text">
                      {t('details-button')}
                    </span>
                    <ArrowRightSvg
                      className={`card-link-icon svg-icon ${
                        isRtl ? 'rtl' : 'ltr'
                      }`}
                    />
                  </Button>
                );
                const fullname = isRtl
                  ? personToFullname(firstNameAr, lastNameAr)
                  : personToFullname(firstNameFr, lastNameFr);

                const card = (
                  <Card
                    key={index}
                    imageName={photo}
                    cardTitle={fullname}
                    subtitle={
                      auth.user?.userType === 'om'
                        ? isRtl
                          ? functionality?.nameAr
                          : functionality?.nameFr
                        : isRtl
                        ? om?.nameAr
                        : om?.nameFr
                    }
                    commentRole={
                      status === 'accepted'
                        ? suspended
                          ? 'danger'
                          : 'info'
                        : status === 'pending'
                        ? 'warning'
                        : 'danger'
                    }
                    comment={
                      status === 'accepted'
                        ? t(`suspension-status.${suspended || false}`)
                        : t(`acceptance-status.${status || 'pending'}`)
                    }
                    detailsToggle={viewDetailsBtn}
                  />
                );
                return card;
              },
            )}
          </CardsContainer>
          <Paginator
            forcePage={page - 1}
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

type ViewJournalistTabs = 'info' | 'contact';

export function ViewJournalist({
  id,
  photo,
  firstNameFr,
  lastNameFr,
  firstNameAr,
  lastNameAr,
  function: functionality,
  birthPlace,
  birthday,
  email,
  phones,
  idCard,
  issueDate,
  om,
  nationality,
  suspended,
  status,
  onQuickUpdate,
  onClose,
  onEditClick,
}: GetJournalistDto & StandAloneViewModalProps & { onQuickUpdate?: Function }) {
  const [tab, setTab] = useState<ViewJournalistTabs>('info');
  const [selectedStatus, setSelectedStatus] = useState<StatusFilter>(
    status as StatusFilter,
  );
  const [hasChanged, setHasChanged] = useState(false);
  const auth = useAuth();
  const history = useHistory<PathLocationDto>();
  const { encryptedId } = useParams<PageModalUrlParams>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const phoneNumber = phones?.find(({ type }) => type === 'mobile')
    ?.phoneNumber;
  const phoneNumber2 = phones?.find(({ type }) => type === 'mobile2')
    ?.phoneNumber;

  const bottomBtns = (
    <>
      {true ? (
        <Button
          btnRole="secondary"
          accent="danger"
          className="ml-4 d-none"
          onClick={async () => {
            await axios.patch(`journalist/dismissal/${id}`);

            if (onClose) {
              onClose();
            } else {
              history.push('/journalist');
            }
            onQuickUpdate?.();
          }}
        >
          <span className="icon mr-2" data-icon="&#x43;" />
          <span>Fire</span>
        </Button>
      ) : undefined}
      <Button
        btnRole="primary"
        className="ml-4"
        onClick={() => {
          if (onEditClick) {
            onEditClick();
          } else {
            history.push(`/journalist/edit/${encryptedId}`);
          }

          if (hasChanged) {
            onQuickUpdate?.();
          }
        }}
      >
        {t('modify-info-button')}
      </Button>
    </>
  );

  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/journalist');
        }

        if (hasChanged) {
          onQuickUpdate?.();
        }
      }}
    />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      bottomButtons={bottomBtns}
      onRequestClose={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/journalist');
        }
        if (hasChanged) {
          onQuickUpdate?.();
        }
      }}
      onAfterClose={() => setTab('info')}
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div
        className={`journalist-info collapsible ${
          tab === 'info' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="journalist-info-left">
          <Picture defaultImageName={photo} />
          <div className="app-title mt-3">
            {isRtl
              ? personToFullname(firstNameAr, lastNameAr)
              : personToFullname(firstNameFr, lastNameFr)}
          </div>
          <div className="app-text mt-3">
            {isRtl ? functionality?.nameAr : functionality?.nameFr}
          </div>
          <div className="mt-2 app-text d-flex align-items-center">
            <CountryFlag alpha2={nationality?.trim()} />
            <div className={isRtl ? 'mr-2' : 'ml-2'}>
              {(isRtl
                ? countries.find(
                    ({ alpha2 }) => alpha2 === nationality?.trim() || '',
                  )?.nameAr
                : countries.find(
                    ({ alpha2 }) => alpha2 === nationality?.trim() || '',
                  )?.nameFr) || t('non-specified')}
              &nbsp;({t('forms-labels.nationality')})
            </div>
          </div>
          <Button
            className={`contact-info mt-2 slidable ${
              isRtl ? 'slidable-left' : 'slidable-right'
            } app-small-text`}
            onClick={() => setTab('contact')}
          >
            <PhoneSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('contact-info')}</span>
          </Button>
        </div>
        <div
          className={`journalist-info-right d-flex flex-column justify-content-center ${
            isRtl ? 'mr-5' : 'ml-5'
          }`}
        >
          <div className="general-info-title app-title mb-3">
            {t('general-info')}
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.last-name')}
            </div>
            <div className="col-7 app-text">
              {isRtl ? lastNameAr : lastNameFr}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.first-name')}
            </div>
            <div className="col-7 app-text">
              {isRtl ? firstNameAr : firstNameFr}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.function')}
            </div>
            <div className="col-7 app-text">
              {(isRtl ? functionality?.nameAr : functionality?.nameFr) ||
                t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">{t('forms-labels.o')}</div>
            <div className="col-7 app-text">
              {(isRtl ? om?.nameAr : om?.nameFr) || t('non-specified')}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.birthday')}
            </div>
            <div className="col-7 app-text">
              {(birthday ? new Date(birthday) : null)?.toLocaleDateString(
                'fr-DZ',
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis">
              {t('forms-labels.birthplace')}
            </div>
            <div className="col-7 app-text">{birthPlace}</div>
          </div>
          <div className="row mt-3 pr-1">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.state')}
            </div>
            <div className="col-7 app-text d-flex align-items-center">
              {auth.user?.userType === 'faf' ? (
                selectedStatus === 'accepted' ? (
                  <div className="primary-txt">
                    {t('acceptance-status.accepted')}
                  </div>
                ) : selectedStatus === 'rejected' ? (
                  <>
                    <div className="d-flex align-items-center danger-txt">
                      {t('acceptance-status.rejected')}
                      <span className="icon" data-icon="&#x4c;" />
                    </div>
                    <Button
                      className={isRtl ? 'mr-3' : 'ml-3'}
                      btnRole="primary"
                      onClick={async () => {
                        try {
                          await axios.put(`/journalist/${id}/handle/accept`);

                          setHasChanged(true);
                          setSelectedStatus('accepted');
                        } catch (error: any) {
                          console.error(error);
                          if (axios.isAxiosError(error)) {
                            const errorData = error.response?.data;
                            const messages =
                              errorData?.message || errorData?.messages;

                            if (messages) {
                              if (Array.isArray(messages)) {
                                toast.error(
                                  messages.reduce(
                                    (prevMsg: string, msg: string) => (
                                      <div>
                                        {prevMsg}-{' '}
                                        {normalizeToastMessage(msg || '')}.
                                      </div>
                                    ),
                                    '',
                                  ),
                                );
                              } else {
                                toast.error(
                                  normalizeToastMessage(
                                    messages?.toString() || '',
                                  ),
                                );
                              }
                            } else {
                              toast.error(
                                normalizeToastMessage(messages?.toString()) ||
                                  '',
                              );
                            }
                          } else {
                            toast.error(
                              normalizeToastMessage(error.message || ''),
                            );
                          }
                        }
                      }}
                    >
                      {t('review-action.accept')}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      accent="danger"
                      btnRole="secondary"
                      onClick={async () => {
                        try {
                          await axios.put(`/journalist/${id}/handle/reject`);

                          setHasChanged(true);
                          setSelectedStatus('rejected');
                        } catch (error: any) {
                          console.error(error);
                          if (axios.isAxiosError(error)) {
                            const errorData = error.response?.data;
                            const messages =
                              errorData?.message || errorData?.messages;

                            if (messages) {
                              if (Array.isArray(messages)) {
                                toast.error(
                                  messages.reduce(
                                    (prevMsg: string, msg: string) => (
                                      <div>
                                        {prevMsg}-{' '}
                                        {normalizeToastMessage(msg || '')}.
                                      </div>
                                    ),
                                    '',
                                  ),
                                );
                              } else {
                                toast.error(
                                  normalizeToastMessage(
                                    messages?.toString() || '',
                                  ),
                                );
                              }
                            } else {
                              toast.error(
                                normalizeToastMessage(messages?.toString()) ||
                                  '',
                              );
                            }
                          } else {
                            toast.error(
                              normalizeToastMessage(error.message || ''),
                            );
                          }
                        }
                      }}
                    >
                      {t('review-action.reject')}
                    </Button>
                    <Button
                      className={isRtl ? 'mr-2' : 'ml-2'}
                      btnRole="primary"
                      onClick={async () => {
                        try {
                          await axios.put(`/journalist/${id}/handle/accept`);

                          setHasChanged(true);
                          setSelectedStatus('accepted');
                        } catch (error: any) {
                          console.error(error);
                          if (axios.isAxiosError(error)) {
                            const errorData = error.response?.data;
                            const messages =
                              errorData?.message || errorData?.messages;

                            if (messages) {
                              if (Array.isArray(messages)) {
                                toast.error(
                                  messages.reduce(
                                    (prevMsg: string, msg: string) => (
                                      <div>
                                        {prevMsg}-{' '}
                                        {normalizeToastMessage(msg || '')}.
                                      </div>
                                    ),
                                    '',
                                  ),
                                );
                              } else {
                                toast.error(
                                  normalizeToastMessage(
                                    messages?.toString() || '',
                                  ),
                                );
                              }
                            } else {
                              toast.error(
                                normalizeToastMessage(messages?.toString()) ||
                                  '',
                              );
                            }
                          } else {
                            toast.error(
                              normalizeToastMessage(error.message || ''),
                            );
                          }
                        }
                      }}
                    >
                      {t('review-action.accept')}
                    </Button>
                  </>
                )
              ) : selectedStatus ? (
                t(`acceptance-status.${selectedStatus}`)
              ) : (
                t('non-specified')
              )}
            </div>
          </div>
          {selectedStatus === 'accepted' ? (
            <div className="row mt-2">
              <div className="col-5 app-emphasis d-flex align-items-center">
                {t('forms-labels.participation')}
              </div>
              <div className={`col-7 app-text ${isRtl ? 'pl-4' : 'pr-4'}`}>
                {auth.user?.userType === 'faf' ? (
                  <Select
                    className="w-100"
                    menuPosition="fixed"
                    value={{
                      label: t(`suspension-status.${suspended}`),
                      value: `${suspended}`,
                    }}
                    options={suspensionOptions.map(({ label, value }) => ({
                      label: t(`suspension-status.${label}`),
                      value,
                    }))}
                    onChange={async (option) => {
                      const { value } = option as OptionType;
                      try {
                        await axios.put(
                          `/journalist/${id}/suspension/${value}`,
                        );

                        setHasChanged(true);
                      } catch (error: any) {
                        console.error(error);
                        if (axios.isAxiosError(error)) {
                          const errorData = error.response?.data;
                          const messages =
                            errorData?.message || errorData?.messages;

                          if (messages) {
                            if (Array.isArray(messages)) {
                              toast.error(
                                messages.reduce(
                                  (prevMsg: string, msg: string) => (
                                    <div>
                                      {prevMsg}-{' '}
                                      {normalizeToastMessage(msg || '')}.
                                    </div>
                                  ),
                                  '',
                                ),
                              );
                            } else {
                              toast.error(
                                normalizeToastMessage(
                                  messages?.toString() || '',
                                ),
                              );
                            }
                          } else {
                            toast.error(
                              normalizeToastMessage(messages?.toString()) || '',
                            );
                          }
                        } else {
                          toast.error(
                            normalizeToastMessage(error.message || ''),
                          );
                        }
                      }
                    }}
                  />
                ) : (
                  <div
                    className={
                      suspended === true
                        ? 'danger-txt'
                        : suspended === false
                        ? 'text-info'
                        : ''
                    }
                  >
                    {suspended !== undefined
                      ? t(`suspension-status.${suspended}`)
                      : t('non-specified')}
                  </div>
                )}
              </div>
            </div>
          ) : undefined}
          <div className="row mt-2">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.id-card')}
            </div>
            <div className="col-7 app-text">
              <FileButton id="id-card" defaultFileName={idCard} />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5 app-emphasis d-flex align-items-center">
              {t('forms-labels.card-edition-date')}
            </div>
            <div className="col-7 app-text">
              {(issueDate ? new Date(issueDate) : null)?.toLocaleDateString(
                'fr-DZ',
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`journalist-contact collapsible mb-5 ${
          tab === 'contact' ? 'visible' : 'collapsed'
        }`}
      >
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="contact-info-title app-title">
            {t('contact-info')}
          </div>
          <Button
            className={`info-back mt-2 slidable ${
              isRtl ? 'slidable-right' : 'slidable-left'
            } app-small-text`}
            onClick={() => setTab('info')}
          >
            <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
            <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('return')}</span>
          </Button>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">{t('forms-labels.email')}</div>
          <div className="col-7 app-text">{email}</div>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">
            {t('forms-labels.phone-number')}
          </div>
          <div className="col-7 ltr-text app-text">
            {formatDzPhoneNumber(phoneNumber || t('non-specified'), true, true)}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-5 app-emphasis">
            {t('forms-labels.phone-number2')}
          </div>
          <div className="col-7 ltr-text app-text">
            {formatDzPhoneNumber(
              phoneNumber2 || t('non-specified'),
              true,
              true,
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

type FormJournalistTabs = 'basics' | 'details&contact' | 'files&access';

export function FormJournalist({
  id,
  photo,
  lastNameFr,
  lastNameAr,
  firstNameFr,
  firstNameAr,
  birthPlace,
  birthday,
  email,
  function: functionality,
  idCard,
  issueDate,
  nationality,
  om,
  phones,
  onAfterSubmission: afterSubmission,
  formType: defaultFormType,
  closePath,
  onClose,
}: GetJournalistDto & StandAloneFormModalProps) {
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const [selectedPhone, setSelectedPhone] = useState(
    phones?.find(({ type }) => type === 'mobile'),
  );
  const [selectedPhone2, setSelectedPhone2] = useState(
    phones?.find(({ type }) => type === 'mobile2'),
  );
  const [selectedCountry, setSelectedCountry] = useState(nationality);
  const [tab, setTab] = useState<FormJournalistTabs>('basics');
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const auth = useAuth();
  const {
    control: basicsControl,
    handleSubmit: submitBasics,
    reset: resetBasics,
    getValues: getBasicsValues,
  } = useForm({
    defaultValues: {
      lastNameFr,
      lastNameAr,
      firstNameFr,
      firstNameAr,
      function: functionality,
    },
  });
  const {
    control: detailsControl,
    handleSubmit: submitDetails,
    reset: resetDetails,
    getValues: getDetailsValues,
  } = useForm({
    defaultValues: {
      om,
      nationality,
      birthday: new Date(birthday ?? '2005-01-01T00:00:00.000Z'),
      birthPlace,
      email,
      phones,
    },
  });
  const {
    control: docsControl,
    handleSubmit: submitDocs,
    reset: resetDocs,
    getValues: getDocsValues,
  } = useForm({
    defaultValues: {
      photo,
      idCard,
      issueDate: new Date(
        issueDate ??
          moment.utc(new Date().toISOString().substring(0, 10)).format(),
      ),
    },
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const formType =
    defaultFormType || pathname.startsWith('/journalist/edit/')
      ? 'edit'
      : 'add';
  const formMethod = formType === 'add' ? 'post' : 'put';
  const formAction =
    formType === 'add' ? '/journalist/submit' : `/journalist/${id}/edit`;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }

    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/journalist');
  };
  const submit = async () => {
    if (tab === 'basics') {
      setTab('details&contact');
      return;
    }
    if (tab === 'details&contact') {
      setTab('files&access');
      return;
    }

    setModalRole('processing');

    const data: GetJournalistDto = {
      ...getBasicsValues(),
      ...getDetailsValues(),
      ...getDocsValues(),
    };

    if (formType === 'add' || auth.user?.userType !== 'faf') {
      data.omId = auth.user?.ownerId;
    }

    data.phones = [selectedPhone!, selectedPhone2!];

    data.phones = data.phones?.filter((phone) => phone);

    // normalize phone number
    data.phones?.forEach(
      (phone) =>
        (phone.phoneNumber = formatDzPhoneNumber(phone?.phoneNumber || '')),
    );

    try {
      await axios[formMethod](formAction || pathname, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error: any) {
      setModalRole('content');
      console.error(error);
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;
        const messages = errorData?.message || errorData?.messages;

        if (messages) {
          if (Array.isArray(messages)) {
            toast.error(
              messages.reduce(
                (prevMsg: string, msg: string) => (
                  <div>
                    {prevMsg}- {normalizeToastMessage(msg || '')}.
                  </div>
                ),
                '',
              ),
            );
          } else {
            toast.error(normalizeToastMessage(messages?.toString() || ''));
          }
        } else {
          toast.error(normalizeToastMessage(messages?.toString()) || '');
        }
      } else {
        toast.error(normalizeToastMessage(error.message || ''));
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/journalist');
      }}
    />
  );
  const formTitle = t(`modals-titles.${tab}`);

  let bottomBtns: ReactNode;
  if (tab === 'basics') {
    bottomBtns = (
      <Button
        btnRole="primary"
        type="submit"
        form={'basics' as FormJournalistTabs}
        style={{ width: '145px' }}
      >
        <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
        <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
      </Button>
    );
  } else if (tab === 'details&contact') {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('basics')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form={'details&contact' as FormJournalistTabs}
          style={{ width: '145px' }}
        >
          <span className={isRtl ? 'ml-2' : 'mr-2'}>{t('next')}</span>
          <ArrowRightSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
        </Button>
      </>
    );
  } else {
    bottomBtns = (
      <>
        <Button
          btnRole="secondary"
          key="button"
          style={{ width: '145px' }}
          onClick={() => setTab('details&contact')}
        >
          <ArrowLeftSvg className={`svg-icon ${isRtl ? 'rtl' : 'ltr'}`} />
          <span className={isRtl ? 'mr-2' : 'ml-2'}>{t('previous')}</span>
        </Button>
        <Button
          className={isRtl ? 'mr-3' : 'ml-3'}
          btnRole="primary"
          type="submit"
          form={'files&access' as FormJournalistTabs}
          style={{ width: '145px' }}
        >
          {t('save-button')}
        </Button>
      </>
    );
  }

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      successMessage={
        formType === 'add'
          ? t('journalist.add-success-message')
          : t('journalist.modify-success-message')
      }
      successButtons={successButtons}
      pointsOptions={{
        count: 3,
        selectedPoint: [
          tab === 'basics',
          tab === 'details&contact',
          tab === 'files&access',
        ],
      }}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={() => {
        resetBasics();
        resetDetails();
        resetDocs();
      }}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="form-title app-heading-3 mb-5">{formTitle}</div>
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form
            id={'basics' as FormJournalistTabs}
            className={`collapsible ${
              tab === 'basics' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitBasics(submit)}
          >
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="lname-fr">
                  {t('forms-labels.last-name')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="lastNameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Nom en français"
                      required
                      id="lname-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={basicsControl}
                  name="lastNameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="اللقب بالعربية"
                      required
                      id="lname-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="fname-fr">
                  {t('forms-labels.first-name')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={basicsControl}
                  name="firstNameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Prénom en français"
                      required
                      id="fname-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={basicsControl}
                  name="firstNameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الإسم بالعربية"
                      required
                      id="fname-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="functionality"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                >
                  {t('forms-labels.function')}
                </label>
              </div>
              <div className="form-group col-md-5">
                <div className="input-group">
                  <Controller
                    control={basicsControl}
                    name="function"
                    render={({ field: { value, onChange } }) => (
                      <AsyncPaginate
                        className="w-100"
                        menuPosition="fixed"
                        placeholder={t('select-field-placeholder')}
                        isSearchable
                        isOptionSelected={(option) =>
                          option.value.id === value?.id
                        }
                        value={value ? dataToOption(value, isRtl) : undefined}
                        required
                        id="functionality"
                        defaultOptions
                        cacheOptions
                        loadOptions={async (searchValue, loadedOptions) => {
                          searchValue = searchValue.trim().toLowerCase();
                          const offset = 50;
                          const page = getPage(loadedOptions.length, offset);
                          const { data } = await axios.get<
                            never,
                            AxiosResponse<Paginate<CategoryDto>>
                          >(`/category/${page}/${offset}/${searchValue}`);

                          const options = dataArrayToOptions(
                            data.results,
                            isRtl,
                            'functionalities',
                          );

                          return {
                            options,
                            hasMore:
                              data.total >
                              options.length + loadedOptions.length,
                          };
                        }}
                        onChange={(option: ValueType<OptionType, false>) =>
                          onChange(option?.value)
                        }
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </form>
          <form
            id={'details&contact' as FormJournalistTabs}
            className={`collapsible ${
              tab === 'details&contact' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitDetails(submit)}
          >
            {auth.user?.userType === 'faf' ? (
              <div className="row">
                <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                  <label
                    htmlFor="om"
                    className={`m-0 app-emphasis required ${
                      isRtl ? 'rtl reversed' : 'ltr reversed'
                    }`}
                    style={{ minWidth: '180px' }}
                  >
                    {t('forms-labels.om')}
                  </label>
                </div>
                <div className="form-group col-md-5">
                  <div className="input-group">
                    <Controller
                      control={detailsControl}
                      name="om"
                      render={({ field: { value, onChange } }) => (
                        <AsyncPaginate
                          className="w-100"
                          menuPosition="fixed"
                          placeholder={t('select-field-placeholder')}
                          required
                          isSearchable
                          id="om"
                          defaultOptions
                          cacheOptions
                          isOptionSelected={(option) =>
                            option.value.id === value?.id
                          }
                          value={value ? dataToOption(value, isRtl) : undefined}
                          loadOptions={async (searchValue, loadedOptions) => {
                            searchValue = searchValue.trim().toLowerCase();
                            const offset = 50;
                            const page = getPage(loadedOptions.length, offset);
                            const { data } = await axios.get<
                              never,
                              AxiosResponse<Paginate<OmDto>>
                            >(`/om/${page}/${offset}/${searchValue}`);

                            const options = dataArrayToOptions(
                              data.results,
                              isRtl,
                            );

                            return {
                              options,
                              hasMore:
                                data.total >
                                options.length + loadedOptions.length,
                            };
                          }}
                          onChange={(option: ValueType<OptionType, false>) =>
                            onChange(option?.value)
                          }
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            ) : undefined}
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="nationality"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '120px' }}
                >
                  {t('forms-labels.nationality')}
                </label>
              </div>
              <div className="form-group col-md-5">
                <div className="input-group">
                  <Controller
                    control={detailsControl}
                    name="nationality"
                    render={({ field: { onChange } }) => (
                      <AsyncPaginate
                        className="w-100"
                        menuPosition="fixed"
                        placeholder={t('select-field-placeholder')}
                        required
                        isSearchable
                        id="nationality"
                        cacheOptions
                        value={alpha2ToOptionType(selectedCountry || '', isRtl)}
                        loadOptions={async (
                          inputValue: string,
                          loadedOptions: OptionsList<OptionType>,
                        ) => {
                          const countriesOptions = await loadCountriesOptions(
                            isRtl,
                          );

                          const filteredList = countriesOptions.filter(
                            ({ label }) =>
                              label.toLowerCase().includes(inputValue),
                          );

                          const offset = loadedOptions.length;

                          const truncatedList = filteredList.slice(
                            offset,
                            offset + 20,
                          );

                          return {
                            options: truncatedList,
                            hasMore: truncatedList.length < filteredList.length,
                          };
                        }}
                        components={{ Option: CountryOption }}
                        onChange={(option) => {
                          const { value } = option as OptionType;

                          setSelectedCountry(value);
                          onChange(option ? value : undefined);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="birthday"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '180px' }}
                >
                  {t('forms-labels.birthday')}
                </label>
              </div>
              <div className="form-group col-md-3">
                <Controller
                  control={detailsControl}
                  name="birthday"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      id="birthday"
                      required
                      locale={isRtl ? localAr : localFr}
                      selected={value}
                      placeholderText={t('date-field-placeholder')}
                      dateFormat="dd/MM/yyyy"
                      onChange={onChange}
                      calendarStartDay={6}
                    />
                  )}
                />
              </div>
              <div className="form-group col-md-5">
                <Controller
                  control={detailsControl}
                  name="birthPlace"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      required
                      id="birth-place"
                      placeholder={t('forms-labels.birthplace')}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                  htmlFor="email"
                >
                  {t('forms-labels.email')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={detailsControl}
                  name="email"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      direction={isRtl ? 'rtl' : 'ltr'}
                      required
                      id="email"
                      type="email"
                      placeholder="example@email.com"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '100px' }}
                  htmlFor="phones-0"
                >
                  {t('forms-labels.phone-number')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={detailsControl}
                  name="phones.0"
                  render={({ field: { onChange } }) => (
                    <DialIconInput
                      required
                      defaultValue={
                        selectedPhone?.phoneNumber
                          ? formatDzPhoneNumber(
                              selectedPhone?.phoneNumber,
                              true,
                            )
                          : ''
                      }
                      id="phones-0"
                      placeholder="06xxxxxxxx/+213xxxxxxx"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newNumber = event.target.value;

                        if (!selectedPhone) {
                          setSelectedPhone({
                            phoneNumber: newNumber,
                            ownerType: 'journalist',
                            type: 'mobile',
                          } as PhoneBookDto);
                        } else {
                          selectedPhone.phoneNumber = newNumber;
                          setSelectedPhone(selectedPhone);
                        }

                        onChange(selectedPhone);
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4 d-flex justify-content-end align-items-center">
                <label
                  className={`m-0 app-emphasis ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '120px' }}
                  htmlFor="phones-1"
                >
                  {t('forms-labels.phone-number2')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={detailsControl}
                  name="phones.1"
                  render={({ field: { onChange } }) => (
                    <DialIconInput
                      id="phones-1"
                      defaultValue={
                        selectedPhone2?.phoneNumber
                          ? formatDzPhoneNumber(
                              selectedPhone2?.phoneNumber,
                              true,
                            )
                          : ''
                      }
                      placeholder="06xxxxxxxx/+213xxxxxxx"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const newNumber = event.target.value;

                        if (!selectedPhone2) {
                          setSelectedPhone2({
                            phoneNumber: newNumber,
                            ownerType: 'journalist',
                            type: 'mobile2',
                          } as PhoneBookDto);
                        } else {
                          selectedPhone2.phoneNumber = newNumber;
                          setSelectedPhone2(selectedPhone2);
                        }

                        onChange(selectedPhone2);
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </form>
          <form
            id={'files&access' as FormJournalistTabs}
            className={`d-flex flex-column collapsible ${
              tab === 'files&access' ? 'visible' : 'collapsed'
            }`}
            onSubmit={submitDocs(submit)}
          >
            <div className="files-access mb-3">
              <Controller
                control={docsControl}
                name="photo"
                render={({ field: { value, onChange } }) => (
                  <Picture
                    required
                    enableUpload
                    defaultImageName={value}
                    onNameChange={onChange}
                  />
                )}
              />
            </div>
            <div className="row">
              <div className="form-group col-md-3 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="id-card"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '160px' }}
                >
                  {t('forms-labels.id-card')}
                </label>
              </div>
              <div className="form-group col-md-9">
                <Controller
                  control={docsControl}
                  name="idCard"
                  render={({ field: { value, onChange } }) => (
                    <FileButton
                      id="id-card"
                      required
                      enableUpload
                      defaultFileName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-3 d-flex justify-content-end align-items-center">
                <label
                  htmlFor="issue-date"
                  className={`m-0 app-emphasis required ${
                    isRtl ? 'rtl reversed' : 'ltr reversed'
                  }`}
                  style={{ minWidth: '180px' }}
                >
                  {t('forms-labels.card-edition-date')}
                </label>
              </div>
              <div className="form-group col-md-auto">
                <Controller
                  control={docsControl}
                  name="issueDate"
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      id="issue-date"
                      required
                      locale={isRtl ? localAr : localFr}
                      selected={value}
                      placeholderText={t('date-field-placeholder')}
                      dateFormat="dd/MM/yyyy"
                      onChange={onChange}
                      maxDate={new Date()}
                      calendarStartDay={6}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
