export class CountryDto {
  alpha2: string;
  continent: string;
  nameAr: string;
  nameFr: string;
  dial: string | null;
  color?: string;
}

export const tinyFlagsDir = '32x32';
export const largeFlagsDir = '128x128';

export const countries: CountryDto[] = [
  {
    alpha2: 'af',
    continent: 'Asia',
    nameFr: 'Afghanistan',
    nameAr: 'أفغانستان',
    dial: '+93',
  },
  {
    alpha2: 'za',
    continent: 'Africa',
    nameFr: 'Afrique du Sud',
    nameAr: 'جنوب أفريقيا',
    dial: '+27',
  },
  {
    alpha2: 'al',
    continent: 'Europe',
    nameFr: 'Albanie',
    nameAr: 'ألبانيا',
    dial: '+355',
  },
  {
    alpha2: 'dz',
    continent: 'Africa',
    nameFr: 'Algérie',
    nameAr: 'الجزائر',
    dial: '+213',
    color: '#018d36',
  },
  {
    alpha2: 'mca',
    continent: 'Africa',
    nameFr: 'MC Alger',
    nameAr: 'مولودية الجزائر',
    dial: '+213',
    color: '#018d36',
  },
  {
    alpha2: 'crb',
    continent: 'Africa',
    nameFr: 'CR Belouizdad',
    nameAr: 'شباب بلوزداد',
    dial: '+213',
  },
  {
    alpha2: 'de',
    continent: 'Europe',
    nameFr: 'Allemagne',
    nameAr: 'ألمانيا',
    dial: '+49',
  },
  {
    alpha2: 'ad',
    continent: 'Europe',
    nameFr: 'Andorre',
    nameAr: 'أندورا',
    dial: '+376',
  },
  {
    alpha2: 'ao',
    continent: 'Africa',
    nameFr: 'Angola',
    nameAr: 'أنغولا',
    dial: '+244',
  },
  {
    alpha2: 'ai',
    continent: 'North America',
    nameFr: 'Anguilla',
    nameAr: 'أنغويلا',
    dial: '+1264',
  },
  {
    alpha2: 'aq',
    continent: 'Antarctica',
    nameFr: 'Antarctique',
    nameAr: 'القارة القطبية الجنوبية',
    dial: '+672',
  },
  {
    alpha2: 'ag',
    continent: 'North America',
    nameFr: 'Antigua-et-Barbuda',
    nameAr: 'أنتيغوا وباربودا',
    dial: '+1268',
  },
  {
    alpha2: 'sa',
    continent: 'Asia',
    nameFr: 'Arabie saoudite',
    nameAr: 'السعودية',
    dial: '+966',
  },
  {
    alpha2: 'ar',
    continent: 'South America',
    nameFr: 'Argentine',
    nameAr: 'الأرجنتين',
    dial: '+54',
  },
  {
    alpha2: 'am',
    continent: 'Europe',
    nameFr: 'Arménie',
    nameAr: 'أرمينيا',
    dial: '+374',
  },
  {
    alpha2: 'aw',
    continent: 'North America',
    nameFr: 'Aruba',
    nameAr: 'أروبا',
    dial: '+297',
  },
  {
    alpha2: 'au',
    continent: 'Oceania',
    nameFr: 'Australie',
    nameAr: 'أستراليا',
    dial: '+61',
  },
  {
    alpha2: 'at',
    continent: 'Europe',
    nameFr: 'Autriche',
    nameAr: 'النمسا',
    dial: '+43',
  },
  {
    alpha2: 'az',
    continent: 'Europe',
    nameFr: 'Azerbaïdjan',
    nameAr: 'أذربيجان',
    dial: '+994',
  },
  {
    alpha2: 'bs',
    continent: 'North America',
    nameFr: 'Bahamas',
    nameAr: 'باهاماس',
    dial: '+1242',
  },
  {
    alpha2: 'bh',
    continent: 'Asia',
    nameFr: 'Bahreïn',
    nameAr: 'البحرين',
    dial: '+973',
  },
  {
    alpha2: 'bd',
    continent: 'Asia',
    nameFr: 'Bangladesh',
    nameAr: 'بنغلاديش',
    dial: '+880',
  },
  {
    alpha2: 'bb',
    continent: 'North America',
    nameFr: 'Barbade',
    nameAr: 'باربادوس',
    dial: '+1246',
  },
  {
    alpha2: 'be',
    continent: 'Europe',
    nameFr: 'Belgique',
    nameAr: 'بلجيكا',
    dial: '+32',
  },
  {
    alpha2: 'bz',
    continent: 'North America',
    nameFr: 'Belize',
    nameAr: 'بليز',
    dial: '+501',
  },
  {
    alpha2: 'bm',
    continent: 'North America',
    nameFr: 'Bermudes',
    nameAr: 'برمودا',
    dial: '+1441',
  },
  {
    alpha2: 'bt',
    continent: 'Asia',
    nameFr: 'Bhoutan',
    nameAr: 'بوتان',
    dial: '+975',
  },
  {
    alpha2: 'mm',
    continent: 'Asia',
    nameFr: 'Birmanie',
    nameAr: 'ميانمار',
    dial: '+95',
  },
  {
    alpha2: 'by',
    continent: 'Europe',
    nameFr: 'Biélorussie',
    nameAr: 'بيلاروس',
    dial: '+375',
  },
  {
    alpha2: 'bo',
    continent: 'South America',
    nameFr: 'Bolivie',
    nameAr: 'بوليفيا',
    dial: '+591',
  },
  {
    alpha2: 'ba',
    continent: 'Europe',
    nameFr: 'Bosnie-Herzégovine',
    nameAr: 'البوسنة والهرسك',
    dial: '+387',
  },
  {
    alpha2: 'bw',
    continent: 'Africa',
    nameFr: 'Botswana',
    nameAr: 'بوتسوانا',
    dial: '+267',
  },
  {
    alpha2: 'bn',
    continent: 'Asia',
    nameFr: 'Brunei',
    nameAr: 'بروناي',
    dial: '+673',
  },
  {
    alpha2: 'br',
    continent: 'South America',
    nameFr: 'Brésil',
    nameAr: 'البرازيل',
    dial: '+55',
  },
  {
    alpha2: 'bg',
    continent: 'Europe',
    nameFr: 'Bulgarie',
    nameAr: 'بلغاريا',
    dial: '+359',
  },
  {
    alpha2: 'bf',
    continent: 'Africa',
    nameFr: 'Burkina Faso',
    nameAr: 'بوركينا فاسو',
    dial: '+226',
  },
  {
    alpha2: 'bi',
    continent: 'Africa',
    nameFr: 'Burundi',
    nameAr: 'بوروندي',
    dial: '+257',
  },
  {
    alpha2: 'bj',
    continent: 'Africa',
    nameFr: 'Bénin',
    nameAr: 'بنين',
    dial: '+229',
  },
  {
    alpha2: 'kh',
    continent: 'Asia',
    nameFr: 'Cambodge',
    nameAr: 'كمبوديا',
    dial: '+855',
  },
  {
    alpha2: 'cm',
    continent: 'Africa',
    nameFr: 'Cameroun',
    nameAr: 'الكاميرون',
    dial: '+237',
  },
  {
    alpha2: 'ca',
    continent: 'North America',
    nameFr: 'Canada',
    nameAr: 'كندا',
    dial: '+1',
  },
  {
    alpha2: 'cv',
    continent: 'Africa',
    nameFr: 'Cap-Vert',
    nameAr: 'الرأس الأخضر',
    dial: '+238',
  },
  {
    alpha2: 'cl',
    continent: 'South America',
    nameFr: 'Chili',
    nameAr: 'تشيلي',
    dial: '+56',
  },
  {
    alpha2: 'cn',
    continent: 'Asia',
    nameFr: 'Chine',
    nameAr: 'الصين',
    dial: '+86',
  },
  {
    alpha2: 'cy',
    continent: 'Europe',
    nameFr: 'Chypre (pays)',
    nameAr: 'قبرص',
    dial: '+357',
  },
  {
    alpha2: 'co',
    continent: 'South America',
    nameFr: 'Colombie',
    nameAr: 'كولومبيا',
    dial: '+57',
  },
  {
    alpha2: 'km',
    continent: 'Africa',
    nameFr: 'Comores (pays)',
    nameAr: 'جزر القمر',
    dial: '+269',
  },
  {
    alpha2: 'kp',
    continent: 'Asia',
    nameFr: 'Corée du Nord',
    nameAr: 'كوريا الشمالية',
    dial: '+850',
  },
  {
    alpha2: 'kr',
    continent: 'Asia',
    nameFr: 'Corée du Sud',
    nameAr: 'كوريا الجنوبية',
    dial: '+82',
  },
  {
    alpha2: 'cr',
    continent: 'North America',
    nameFr: 'Costa Rica',
    nameAr: 'كوستاريكا',
    dial: '+506',
  },
  {
    alpha2: 'hr',
    continent: 'Europe',
    nameFr: 'Croatie',
    nameAr: 'كرواتيا',
    dial: '+385',
  },
  {
    alpha2: 'cu',
    continent: 'North America',
    nameFr: 'Cuba',
    nameAr: 'كوبا',
    dial: '+53',
  },
  {
    alpha2: 'cw',
    continent: 'North America',
    nameFr: 'Curaçao',
    nameAr: 'كوراساو',
    dial: null,
  },
  {
    alpha2: 'ci',
    continent: 'Africa',
    nameFr: "Côte d'Ivoire",
    nameAr: 'ساحل العاج',
    dial: '+225',
  },
  {
    alpha2: 'dk',
    continent: 'Europe',
    nameFr: 'Danemark',
    nameAr: 'الدنمارك',
    dial: '+45',
  },
  {
    alpha2: 'dj',
    continent: 'Africa',
    nameFr: 'Djibouti',
    nameAr: 'جيبوتي',
    dial: '+253',
  },
  {
    alpha2: 'dm',
    continent: 'North America',
    nameFr: 'Dominique',
    nameAr: 'دومينيكا',
    dial: '+1767',
  },
  {
    alpha2: 'es',
    continent: 'Europe',
    nameFr: 'Espagne',
    nameAr: 'إسبانيا',
    dial: '+34',
  },
  {
    alpha2: 'ee',
    continent: 'Europe',
    nameFr: 'Estonie',
    nameAr: 'إستونيا',
    dial: '+372',
  },
  {
    alpha2: 'sz',
    continent: 'Africa',
    nameFr: 'Eswatini',
    nameAr: 'إسواتيني',
    dial: '+268',
  },
  {
    alpha2: 'fj',
    continent: 'Oceania',
    nameFr: 'Fidji',
    nameAr: 'فيجي',
    dial: '+679',
  },
  {
    alpha2: 'fi',
    continent: 'Europe',
    nameFr: 'Finlande',
    nameAr: 'فنلندا',
    dial: '+358',
  },
  {
    alpha2: 'fr',
    continent: 'Europe',
    nameFr: 'France',
    nameAr: 'فرنسا',
    dial: '+33',
  },
  {
    alpha2: 'ga',
    continent: 'Africa',
    nameFr: 'Gabon',
    nameAr: 'الغابون',
    dial: '+241',
  },
  {
    alpha2: 'gm',
    continent: 'Africa',
    nameFr: 'Gambie',
    nameAr: 'غامبيا',
    dial: '+220',
  },
  {
    alpha2: 'gh',
    continent: 'Africa',
    nameFr: 'Ghana',
    nameAr: 'غانا',
    dial: '+233',
  },
  {
    alpha2: 'gi',
    continent: 'Europe',
    nameFr: 'Gibraltar',
    nameAr: 'جبل طارق',
    dial: '+350',
  },
  {
    alpha2: 'gd',
    continent: 'North America',
    nameFr: 'Grenade (pays)',
    nameAr: 'غرينادا',
    dial: '+1473',
  },
  {
    alpha2: 'gl',
    continent: 'North America',
    nameFr: 'Groenland',
    nameAr: 'جرينلاند',
    dial: '+299',
  },
  {
    alpha2: 'gr',
    continent: 'Europe',
    nameFr: 'Grèce',
    nameAr: 'اليونان',
    dial: '+30',
  },
  {
    alpha2: 'gp',
    continent: 'North America',
    nameFr: 'Guadeloupe',
    nameAr: 'غوادلوب',
    dial: '+590',
  },
  {
    alpha2: 'gu',
    continent: 'Oceania',
    nameFr: 'Guam',
    nameAr: 'غوام',
    dial: '+1671',
  },
  {
    alpha2: 'gt',
    continent: 'North America',
    nameFr: 'Guatemala',
    nameAr: 'غواتيمالا',
    dial: '+502',
  },
  {
    alpha2: 'gg',
    continent: 'Europe',
    nameFr: 'Guernesey',
    nameAr: 'غيرنزي',
    dial: '+44',
  },
  {
    alpha2: 'gn',
    continent: 'Africa',
    nameFr: 'Guinée',
    nameAr: 'غينيا',
    dial: '+224',
  },
  {
    alpha2: 'gq',
    continent: 'Africa',
    nameFr: 'Guinée équatoriale',
    nameAr: 'غينيا الاستوائية',
    dial: '+240',
  },
  {
    alpha2: 'gw',
    continent: 'Africa',
    nameFr: 'Guinée-Bissau',
    nameAr: 'غينيا بيساو',
    dial: '+245',
  },
  {
    alpha2: 'gy',
    continent: 'South America',
    nameFr: 'Guyana',
    nameAr: 'غيانا',
    dial: '+592',
  },
  {
    alpha2: 'gf',
    continent: 'South America',
    nameFr: 'Guyane',
    nameAr: 'غويانا الفرنسية',
    dial: '+594',
  },
  {
    alpha2: 'ge',
    continent: 'Europe',
    nameFr: 'Géorgie (pays)',
    nameAr: 'جورجيا',
    dial: '+995',
  },
  {
    alpha2: 'gs',
    continent: 'Antarctica',
    nameFr: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    nameAr: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    dial: '+500',
  },
  {
    alpha2: 'ht',
    continent: 'North America',
    nameFr: 'Haïti',
    nameAr: 'هايتي',
    dial: '+509',
  },
  {
    alpha2: 'hn',
    continent: 'North America',
    nameFr: 'Honduras',
    nameAr: 'هندوراس',
    dial: '+504',
  },
  {
    alpha2: 'hk',
    continent: 'Asia',
    nameFr: 'Hong Kong',
    nameAr: 'هونغ كونغ',
    dial: '+852',
  },
  {
    alpha2: 'hu',
    continent: 'Europe',
    nameFr: 'Hongrie',
    nameAr: 'المجر',
    dial: '+36',
  },
  {
    alpha2: 'in',
    continent: 'Asia',
    nameFr: 'Inde',
    nameAr: 'الهند',
    dial: '+91',
  },
  {
    alpha2: 'id',
    continent: 'Asia',
    nameFr: 'Indonésie',
    nameAr: 'إندونيسيا',
    dial: '+62',
  },
  {
    alpha2: 'iq',
    continent: 'Asia',
    nameFr: 'Irak',
    nameAr: 'العراق',
    dial: '+964',
  },
  {
    alpha2: 'ir',
    continent: 'Asia',
    nameFr: 'Iran',
    nameAr: 'إيران',
    dial: '+98',
  },
  {
    alpha2: 'ie',
    continent: 'Europe',
    nameFr: 'Irlande (pays)',
    nameAr: 'أيرلندا',
    dial: '+353',
  },
  {
    alpha2: 'is',
    continent: 'Europe',
    nameFr: 'Islande',
    nameAr: 'آيسلندا',
    dial: '+354',
  },
  {
    alpha2: 'it',
    continent: 'Europe',
    nameFr: 'Italie',
    nameAr: 'إيطاليا',
    dial: '+39',
  },
  {
    alpha2: 'jm',
    continent: 'North America',
    nameFr: 'Jamaïque',
    nameAr: 'جامايكا',
    dial: '+1876',
  },
  {
    alpha2: 'jp',
    continent: 'Asia',
    nameFr: 'Japon',
    nameAr: 'اليابان',
    dial: '+81',
  },
  {
    alpha2: 'je',
    continent: 'Europe',
    nameFr: 'Jersey',
    nameAr: 'جيرزي',
    dial: '+44',
  },
  {
    alpha2: 'jo',
    continent: 'Asia',
    nameFr: 'Jordanie',
    nameAr: 'الأردن',
    dial: '+962',
  },
  {
    alpha2: 'kz',
    continent: 'Europe',
    nameFr: 'Kazakhstan',
    nameAr: 'كازاخستان',
    dial: '+77',
  },
  {
    alpha2: 'ke',
    continent: 'Africa',
    nameFr: 'Kenya',
    nameAr: 'كينيا',
    dial: '+254',
  },
  {
    alpha2: 'kg',
    continent: 'Asia',
    nameFr: 'Kirghizistan',
    nameAr: 'قيرغيزستان',
    dial: '+996',
  },
  {
    alpha2: 'ki',
    continent: 'Oceania',
    nameFr: 'Kiribati',
    nameAr: 'كيريباتي',
    dial: '+686',
  },
  {
    alpha2: 'kw',
    continent: 'Asia',
    nameFr: 'Koweït',
    nameAr: 'الكويت',
    dial: '+965',
  },
  {
    alpha2: 're',
    continent: 'Africa',
    nameFr: 'La Réunion',
    nameAr: 'لا ريونيون',
    dial: '+262',
  },
  {
    alpha2: 'la',
    continent: 'Asia',
    nameFr: 'Laos',
    nameAr: 'لاوس',
    dial: '+856',
  },
  {
    alpha2: 'ls',
    continent: 'Africa',
    nameFr: 'Lesotho',
    nameAr: 'ليسوتو',
    dial: '+266',
  },
  {
    alpha2: 'lv',
    continent: 'Europe',
    nameFr: 'Lettonie',
    nameAr: 'لاتفيا',
    dial: '+371',
  },
  {
    alpha2: 'lb',
    continent: 'Asia',
    nameFr: 'Liban',
    nameAr: 'لبنان',
    dial: '+961',
  },
  {
    alpha2: 'lr',
    continent: 'Africa',
    nameFr: 'Liberia',
    nameAr: 'ليبيريا',
    dial: '+231',
  },
  {
    alpha2: 'ly',
    continent: 'Africa',
    nameFr: 'Libye',
    nameAr: 'ليبيا',
    dial: '+218',
  },
  {
    alpha2: 'li',
    continent: 'Europe',
    nameFr: 'Liechtenstein',
    nameAr: 'ليختنشتاين',
    dial: '+423',
  },
  {
    alpha2: 'lt',
    continent: 'Europe',
    nameFr: 'Lituanie',
    nameAr: 'ليتوانيا',
    dial: '+370',
  },
  {
    alpha2: 'lu',
    continent: 'Europe',
    nameFr: 'Luxembourg (pays)',
    nameAr: 'لوكسمبورغ',
    dial: '+352',
  },
  {
    alpha2: 'mo',
    continent: 'Asia',
    nameFr: 'Macao',
    nameAr: 'ماكاو',
    dial: '+853',
  },
  {
    alpha2: 'mk',
    continent: 'Europe',
    nameFr: 'Macédoine du Nord',
    nameAr: 'مقدونيا',
    dial: '+389',
  },
  {
    alpha2: 'mg',
    continent: 'Africa',
    nameFr: 'Madagascar',
    nameAr: 'مدغشقر',
    dial: '+261',
  },
  {
    alpha2: 'my',
    continent: 'Asia',
    nameFr: 'Malaisie',
    nameAr: 'ماليزيا',
    dial: '+60',
  },
  {
    alpha2: 'mw',
    continent: 'Africa',
    nameFr: 'Malawi',
    nameAr: 'مالاوي',
    dial: '+265',
  },
  {
    alpha2: 'mv',
    continent: 'Asia',
    nameFr: 'Maldives',
    nameAr: 'جزر المالديف',
    dial: '+960',
  },
  {
    alpha2: 'ml',
    continent: 'Africa',
    nameFr: 'Mali',
    nameAr: 'مالي',
    dial: '+223',
  },
  {
    alpha2: 'fk',
    continent: 'South America',
    nameFr: 'Malouines',
    nameAr: 'جزر فوكلاند',
    dial: '+500',
  },
  {
    alpha2: 'mt',
    continent: 'Europe',
    nameFr: 'Malte',
    nameAr: 'مالطا',
    dial: '+356',
  },
  {
    alpha2: 'ma',
    continent: 'Africa',
    nameFr: 'Maroc',
    nameAr: 'المغرب',
    dial: '+212',
  },
  {
    alpha2: 'mq',
    continent: 'North America',
    nameFr: 'Martinique',
    nameAr: 'مارتينيك',
    dial: '+596',
  },
  {
    alpha2: 'mu',
    continent: 'Africa',
    nameFr: 'Maurice (pays)',
    nameAr: 'موريشيوس',
    dial: '+230',
  },
  {
    alpha2: 'mr',
    continent: 'Africa',
    nameFr: 'Mauritanie',
    nameAr: 'موريتانيا',
    dial: '+222',
  },
  {
    alpha2: 'yt',
    continent: 'Africa',
    nameFr: 'Mayotte',
    nameAr: 'مايوت',
    dial: '+262',
  },
  {
    alpha2: 'mx',
    continent: 'North America',
    nameFr: 'Mexique',
    nameAr: 'المكسيك',
    dial: '+52',
  },
  {
    alpha2: 'md',
    continent: 'Europe',
    nameFr: 'Moldavie',
    nameAr: 'مولدوفا',
    dial: '+373',
  },
  {
    alpha2: 'mc',
    continent: 'Europe',
    nameFr: 'Monaco',
    nameAr: 'موناكو',
    dial: '+377',
  },
  {
    alpha2: 'mn',
    continent: 'Asia',
    nameFr: 'Mongolie',
    nameAr: 'منغوليا',
    dial: '+976',
  },
  {
    alpha2: 'ms',
    continent: 'North America',
    nameFr: 'Montserrat',
    nameAr: 'مونتسرات',
    dial: '+1664',
  },
  {
    alpha2: 'me',
    continent: 'Europe',
    nameFr: 'Monténégro',
    nameAr: 'الجبل الأسود',
    dial: '+382',
  },
  {
    alpha2: 'mz',
    continent: 'Africa',
    nameFr: 'Mozambique',
    nameAr: 'موزمبيق',
    dial: '+258',
  },
  {
    alpha2: 'na',
    continent: 'Africa',
    nameFr: 'Namibie',
    nameAr: 'ناميبيا',
    dial: '+264',
  },
  {
    alpha2: 'nr',
    continent: 'Oceania',
    nameFr: 'Nauru',
    nameAr: 'ناورو',
    dial: '+674',
  },
  {
    alpha2: 'ni',
    continent: 'North America',
    nameFr: 'Nicaragua',
    nameAr: 'نيكاراغوا',
    dial: '+505',
  },
  {
    alpha2: 'ne',
    continent: 'Africa',
    nameFr: 'Niger',
    nameAr: 'النيجر',
    dial: '+227',
  },
  {
    alpha2: 'ng',
    continent: 'Africa',
    nameFr: 'Nigeria',
    nameAr: 'نيجيريا',
    dial: '+234',
  },
  {
    alpha2: 'nu',
    continent: 'Oceania',
    nameFr: 'Niue',
    nameAr: 'نييوي',
    dial: '+683',
  },
  {
    alpha2: 'no',
    continent: 'Europe',
    nameFr: 'Norvège',
    nameAr: 'النرويج',
    dial: '+47',
  },
  {
    alpha2: 'nc',
    continent: 'Oceania',
    nameFr: 'Nouvelle-Calédonie',
    nameAr: 'كاليدونيا الجديدة',
    dial: '+687',
  },
  {
    alpha2: 'nz',
    continent: 'Oceania',
    nameFr: 'Nouvelle-Zélande',
    nameAr: 'نيوزيلندا',
    dial: '+64',
  },
  {
    alpha2: 'np',
    continent: 'Asia',
    nameFr: 'Népal',
    nameAr: 'نيبال',
    dial: '+977',
  },
  {
    alpha2: 'om',
    continent: 'Asia',
    nameFr: 'Oman',
    nameAr: 'عمان',
    dial: '+968',
  },
  {
    alpha2: 'ug',
    continent: 'Africa',
    nameFr: 'Ouganda',
    nameAr: 'أوغندا',
    dial: '+256',
  },
  {
    alpha2: 'uz',
    continent: 'Asia',
    nameFr: 'Ouzbékistan',
    nameAr: 'أوزبكستان',
    dial: '+998',
  },
  {
    alpha2: 'pk',
    continent: 'Asia',
    nameFr: 'Pakistan',
    nameAr: 'باكستان',
    dial: '+92',
  },
  {
    alpha2: 'pw',
    continent: 'Oceania',
    nameFr: 'Palaos',
    nameAr: 'بالاو',
    dial: '+680',
  },
  {
    alpha2: 'ps',
    continent: 'Asia',
    nameFr: 'Palestine',
    nameAr: 'فلسطين',
    dial: '+970',
  },
  {
    alpha2: 'pa',
    continent: 'North America',
    nameFr: 'Panama',
    nameAr: 'بنما',
    dial: '+507',
  },
  {
    alpha2: 'pg',
    continent: 'Oceania',
    nameFr: 'Papouasie-Nouvelle-Guinée',
    nameAr: 'بابوا غينيا الجديدة',
    dial: '+675',
  },
  {
    alpha2: 'py',
    continent: 'South America',
    nameFr: 'Paraguay',
    nameAr: 'باراغواي',
    dial: '+595',
  },
  {
    alpha2: 'nl',
    continent: 'Europe',
    nameFr: 'Pays-Bas',
    nameAr: 'هولندا',
    dial: '+31',
  },
  {
    alpha2: 'bq',
    continent: 'North America',
    nameFr: 'Pays-Bas caribéens',
    nameAr: 'الجزر الكاريبية الهولندية',
    dial: null,
  },
  {
    alpha2: 'ph',
    continent: 'Asia',
    nameFr: 'Philippines',
    nameAr: 'الفلبين',
    dial: '+63',
  },
  {
    alpha2: 'pl',
    continent: 'Europe',
    nameFr: 'Pologne',
    nameAr: 'بولندا',
    dial: '+48',
  },
  {
    alpha2: 'pf',
    continent: 'Oceania',
    nameFr: 'Polynésie française',
    nameAr: 'بولينزيا الفرنسية',
    dial: '+689',
  },
  {
    alpha2: 'pr',
    continent: 'North America',
    nameFr: 'Porto Rico',
    nameAr: 'بورتوريكو',
    dial: '+1939',
  },
  {
    alpha2: 'pt',
    continent: 'Europe',
    nameFr: 'Portugal',
    nameAr: 'البرتغال',
    dial: '+351',
  },
  {
    alpha2: 'pe',
    continent: 'South America',
    nameFr: 'Pérou',
    nameAr: 'بيرو',
    dial: '+51',
  },
  {
    alpha2: 'qa',
    continent: 'Asia',
    nameFr: 'Qatar',
    nameAr: 'قطر',
    dial: '+974',
  },
  {
    alpha2: 'ro',
    continent: 'Europe',
    nameFr: 'Roumanie',
    nameAr: 'رومانيا',
    dial: '+40',
  },
  {
    alpha2: 'gb',
    continent: 'Europe',
    nameFr: 'Royaume-Uni',
    nameAr: 'المملكة المتحدة',
    dial: '+44',
  },
  {
    alpha2: 'ru',
    continent: 'Europe',
    nameFr: 'Russie',
    nameAr: 'روسيا',
    dial: '+7',
  },
  {
    alpha2: 'rw',
    continent: 'Africa',
    nameFr: 'Rwanda',
    nameAr: 'رواندا',
    dial: '+250',
  },
  {
    alpha2: 'eh',
    continent: 'Africa',
    nameFr: 'République arabe sahraouie démocratique',
    nameAr: 'الصحراء الغربية',
    dial: null,
  },
  {
    alpha2: 'cf',
    continent: 'Africa',
    nameFr: 'République centrafricaine',
    nameAr: 'جمهورية أفريقيا الوسطى',
    dial: '+236',
  },
  {
    alpha2: 'do',
    continent: 'North America',
    nameFr: 'République dominicaine',
    nameAr: 'جمهورية الدومينيكان',
    dial: '+1849',
  },
  {
    alpha2: 'cg',
    continent: 'Africa',
    nameFr: 'République du Congo',
    nameAr: 'جمهورية الكونغو',
    dial: '+242',
  },
  {
    alpha2: 'cd',
    continent: 'Africa',
    nameFr: 'République démocratique du Congo',
    nameAr: 'جمهورية الكونغو الديمقراطية',
    dial: '+243',
  },
  {
    alpha2: 'bl',
    continent: 'North America',
    nameFr: 'Saint-Barthélemy',
    nameAr: 'سان بارتيلمي',
    dial: '+590',
  },
  {
    alpha2: 'kn',
    continent: 'North America',
    nameFr: 'Saint-Christophe-et-Niévès',
    nameAr: 'سانت كيتس ونيفيس',
    dial: '+1869',
  },
  {
    alpha2: 'sm',
    continent: 'Europe',
    nameFr: 'Saint-Marin',
    nameAr: 'سان مارينو',
    dial: '+378',
  },
  {
    alpha2: 'sx',
    continent: 'North America',
    nameFr: 'Saint-Martin',
    nameAr: 'سينت مارتن',
    dial: '+1721',
  },
  {
    alpha2: 'mf',
    continent: 'North America',
    nameFr: 'Saint-Martin',
    nameAr: 'تجمع سان مارتين',
    dial: '+590',
  },
  {
    alpha2: 'pm',
    continent: 'North America',
    nameFr: 'Saint-Pierre-et-Miquelon',
    nameAr: 'سان بيير وميكلون',
    dial: '+508',
  },
  {
    alpha2: 'va',
    continent: 'Europe',
    nameFr: 'Saint-Siège (État de la Cité du Vatican)',
    nameAr: 'الفاتيكان',
    dial: '+379',
  },
  {
    alpha2: 'vc',
    continent: 'North America',
    nameFr: 'Saint-Vincent-et-les-Grenadines',
    nameAr: 'سانت فينسنت والغرينادين',
    dial: '+1784',
  },
  {
    alpha2: 'sh',
    continent: 'Africa',
    nameFr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    nameAr: 'سانت هيلانة وأسينشين وتريستان دا كونا',
    dial: '+290',
  },
  {
    alpha2: 'lc',
    continent: 'North America',
    nameFr: 'Sainte-Lucie',
    nameAr: 'سانت لوسيا',
    dial: '+1758',
  },
  {
    alpha2: 'sv',
    continent: 'North America',
    nameFr: 'Salvador',
    nameAr: 'السلفادور',
    dial: '+503',
  },
  {
    alpha2: 'ws',
    continent: 'Oceania',
    nameFr: 'Samoa',
    nameAr: 'ساموا',
    dial: '+685',
  },
  {
    alpha2: 'as',
    continent: 'Oceania',
    nameFr: 'Samoa américaines',
    nameAr: 'ساموا الأمريكية',
    dial: '+1684',
  },
  {
    alpha2: 'st',
    continent: 'Africa',
    nameFr: 'Sao Tomé-et-Principe',
    nameAr: 'ساو تومي وبرينسيب',
    dial: '+239',
  },
  {
    alpha2: 'rs',
    continent: 'Europe',
    nameFr: 'Serbie',
    nameAr: 'صربيا',
    dial: '+381',
  },
  {
    alpha2: 'sc',
    continent: 'Africa',
    nameFr: 'Seychelles',
    nameAr: 'سيشل',
    dial: '+248',
  },
  {
    alpha2: 'sl',
    continent: 'Africa',
    nameFr: 'Sierra Leone',
    nameAr: 'سيراليون',
    dial: '+232',
  },
  {
    alpha2: 'sg',
    continent: 'Asia',
    nameFr: 'Singapour',
    nameAr: 'سنغافورة',
    dial: '+65',
  },
  {
    alpha2: 'sk',
    continent: 'Europe',
    nameFr: 'Slovaquie',
    nameAr: 'سلوفاكيا',
    dial: '+421',
  },
  {
    alpha2: 'si',
    continent: 'Europe',
    nameFr: 'Slovénie',
    nameAr: 'سلوفينيا',
    dial: '+386',
  },
  {
    alpha2: 'so',
    continent: 'Africa',
    nameFr: 'Somalie',
    nameAr: 'الصومال',
    dial: '+252',
  },
  {
    alpha2: 'sd',
    continent: 'Africa',
    nameFr: 'Soudan',
    nameAr: 'السودان',
    dial: '+249',
  },
  {
    alpha2: 'ss',
    continent: 'Africa',
    nameFr: 'Soudan du Sud',
    nameAr: 'جنوب السودان',
    dial: '+211',
  },
  {
    alpha2: 'lk',
    continent: 'Asia',
    nameFr: 'Sri Lanka',
    nameAr: 'سريلانكا',
    dial: '+94',
  },
  {
    alpha2: 'ch',
    continent: 'Europe',
    nameFr: 'Suisse',
    nameAr: 'سويسرا',
    dial: '+41',
  },
  {
    alpha2: 'sr',
    continent: 'South America',
    nameFr: 'Suriname',
    nameAr: 'سورينام',
    dial: '+597',
  },
  {
    alpha2: 'se',
    continent: 'Europe',
    nameFr: 'Suède',
    nameAr: 'السويد',
    dial: '+46',
  },
  {
    alpha2: 'sj',
    continent: 'Europe',
    nameFr: 'Svalbard et ile Jan Mayen',
    nameAr: 'سفالبارد ويان ماين',
    dial: '+47',
  },
  {
    alpha2: 'sy',
    continent: 'Asia',
    nameFr: 'Syrie',
    nameAr: 'سوريا',
    dial: '+963',
  },
  {
    alpha2: 'sn',
    continent: 'Africa',
    nameFr: 'Sénégal',
    nameAr: 'السنغال',
    dial: '+221',
  },
  {
    alpha2: 'tj',
    continent: 'Asia',
    nameFr: 'Tadjikistan',
    nameAr: 'طاجيكستان',
    dial: '+992',
  },
  {
    alpha2: 'tz',
    continent: 'Africa',
    nameFr: 'Tanzanie',
    nameAr: 'تنزانيا',
    dial: '+255',
  },
  {
    alpha2: 'tw',
    continent: 'Asia',
    nameFr: 'Taïwan / (République de Chine (Taïwan))',
    nameAr: 'تايوان',
    dial: '+886',
  },
  {
    alpha2: 'td',
    continent: 'Africa',
    nameFr: 'Tchad',
    nameAr: 'تشاد',
    dial: '+235',
  },
  {
    alpha2: 'cz',
    continent: 'Europe',
    nameFr: 'Tchéquie',
    nameAr: 'جمهورية التشيك',
    dial: '+420',
  },
  {
    alpha2: 'tf',
    continent: 'Antarctica',
    nameFr: 'Terres australes et antarctiques françaises',
    nameAr: 'أراض فرنسية جنوبية وأنتارتيكية',
    dial: null,
  },
  {
    alpha2: 'io',
    continent: 'Asia',
    nameFr: "Territoire britannique de l'océan Indien",
    nameAr: 'إقليم المحيط الهندي البريطاني',
    dial: '+246',
  },
  {
    alpha2: 'th',
    continent: 'Asia',
    nameFr: 'Thaïlande',
    nameAr: 'تايلاند',
    dial: '+66',
  },
  {
    alpha2: 'tl',
    continent: 'Asia',
    nameFr: 'Timor oriental',
    nameAr: 'تيمور الشرقية',
    dial: '+670',
  },
  {
    alpha2: 'tg',
    continent: 'Africa',
    nameFr: 'Togo',
    nameAr: 'توغو',
    dial: '+228',
  },
  {
    alpha2: 'tk',
    continent: 'Oceania',
    nameFr: 'Tokelau',
    nameAr: 'توكيلاو',
    dial: '+690',
  },
  {
    alpha2: 'to',
    continent: 'Oceania',
    nameFr: 'Tonga',
    nameAr: 'تونغا',
    dial: '+676',
  },
  {
    alpha2: 'tt',
    continent: 'North America',
    nameFr: 'Trinité-et-Tobago',
    nameAr: 'ترينيداد وتوباغو',
    dial: '+1868',
  },
  {
    alpha2: 'tn',
    continent: 'Africa',
    nameFr: 'Tunisie',
    nameAr: 'تونس',
    dial: '+216',
  },
  {
    alpha2: 'tm',
    continent: 'Asia',
    nameFr: 'Turkménistan',
    nameAr: 'تركمانستان',
    dial: '+993',
  },
  {
    alpha2: 'tr',
    continent: 'Europe',
    nameFr: 'Turquie',
    nameAr: 'تركيا',
    dial: '+90',
  },
  {
    alpha2: 'tv',
    continent: 'Oceania',
    nameFr: 'Tuvalu',
    nameAr: 'توفالو',
    dial: '+688',
  },
  {
    alpha2: 'ua',
    continent: 'Europe',
    nameFr: 'Ukraine',
    nameAr: 'أوكرانيا',
    dial: '+380',
  },
  {
    alpha2: 'uy',
    continent: 'South America',
    nameFr: 'Uruguay',
    nameAr: 'الأوروغواي',
    dial: '+598',
  },
  {
    alpha2: 'vu',
    continent: 'Oceania',
    nameFr: 'Vanuatu',
    nameAr: 'فانواتو',
    dial: '+678',
  },
  {
    alpha2: 've',
    continent: 'South America',
    nameFr: 'Venezuela',
    nameAr: 'فنزويلا',
    dial: '+58',
  },
  {
    alpha2: 'vn',
    continent: 'Asia',
    nameFr: 'Viêt Nam',
    nameAr: 'فيتنام',
    dial: '+84',
  },
  {
    alpha2: 'wf',
    continent: 'Oceania',
    nameFr: 'Wallis-et-Futuna',
    nameAr: 'واليس وفوتونا',
    dial: '+681',
  },
  {
    alpha2: 'ye',
    continent: 'Asia',
    nameFr: 'Yémen',
    nameAr: 'اليمن',
    dial: '+967',
  },
  {
    alpha2: 'zm',
    continent: 'Africa',
    nameFr: 'Zambie',
    nameAr: 'زامبيا',
    dial: '+260',
  },
  {
    alpha2: 'zw',
    continent: 'Africa',
    nameFr: 'Zimbabwe',
    nameAr: 'زيمبابوي',
    dial: '+263',
  },
  {
    alpha2: 'eg',
    continent: 'Africa',
    nameFr: 'Égypte',
    nameAr: 'مصر',
    dial: '+20',
  },
  {
    alpha2: 'ae',
    continent: 'Asia',
    nameFr: 'Émirats arabes unis',
    nameAr: 'الإمارات العربية المتحدة',
    dial: '+971',
  },
  {
    alpha2: 'ec',
    continent: 'South America',
    nameFr: 'Équateur (pays)',
    nameAr: 'الإكوادور',
    dial: '+593',
  },
  {
    alpha2: 'er',
    continent: 'Africa',
    nameFr: 'Érythrée',
    nameAr: 'إريتريا',
    dial: '+291',
  },
  {
    alpha2: 'fm',
    continent: 'Oceania',
    nameFr: 'États fédérés de Micronésie (pays)',
    nameAr: 'ولايات ميكرونيسيا المتحدة',
    dial: '+691',
  },
  {
    alpha2: 'us',
    continent: 'North America',
    nameFr: 'États-Unis',
    nameAr: 'الولايات المتحدة',
    dial: '+1',
  },
  {
    alpha2: 'et',
    continent: 'Africa',
    nameFr: 'Éthiopie',
    nameAr: 'إثيوبيا',
    dial: '+251',
  },
  {
    alpha2: 'bv',
    continent: 'Antarctica',
    nameFr: 'Île Bouvet',
    nameAr: 'جزيرة بوفيه',
    dial: null,
  },
  {
    alpha2: 'cx',
    continent: 'Asia',
    nameFr: 'Île Christmas',
    nameAr: 'جزيرة كريسماس',
    dial: '+61',
  },
  {
    alpha2: 'nf',
    continent: 'Oceania',
    nameFr: 'Île Norfolk',
    nameAr: 'جزيرة نورفولك',
    dial: '+672',
  },
  {
    alpha2: 'im',
    continent: 'Europe',
    nameFr: 'Île de Man',
    nameAr: 'جزيرة مان',
    dial: '+44',
  },
  {
    alpha2: 'ky',
    continent: 'North America',
    nameFr: 'Îles Caïmans',
    nameAr: 'جزر كايمان',
    dial: '+1345',
  },
  {
    alpha2: 'cc',
    continent: 'Asia',
    nameFr: 'Îles Cocos',
    nameAr: 'جزر كوكوس',
    dial: '+61',
  },
  {
    alpha2: 'ck',
    continent: 'Oceania',
    nameFr: 'Îles Cook',
    nameAr: 'جزر كوك',
    dial: '+682',
  },
  {
    alpha2: 'fo',
    continent: 'Europe',
    nameFr: 'Îles Féroé',
    nameAr: 'جزر فارو',
    dial: '+298',
  },
  {
    alpha2: 'hm',
    continent: 'Antarctica',
    nameFr: 'Îles Heard-et-MacDonald',
    nameAr: 'جزيرة هيرد وجزر ماكدونالد',
    dial: null,
  },
  {
    alpha2: 'mp',
    continent: 'Oceania',
    nameFr: 'Îles Mariannes du Nord',
    nameAr: 'جزر ماريانا الشمالية',
    dial: '+1670',
  },
  {
    alpha2: 'mh',
    continent: 'Oceania',
    nameFr: 'Îles Marshall (pays)',
    nameAr: 'جزر مارشال',
    dial: '+692',
  },
  {
    alpha2: 'pn',
    continent: 'Oceania',
    nameFr: 'Îles Pitcairn',
    nameAr: 'جزر بيتكيرن',
    dial: '+872',
  },
  {
    alpha2: 'sb',
    continent: 'Oceania',
    nameFr: 'Îles Salomon',
    nameAr: 'جزر سليمان',
    dial: '+677',
  },
  {
    alpha2: 'tc',
    continent: 'North America',
    nameFr: 'Îles Turques-et-Caïques',
    nameAr: 'جزر توركس وكايكوس',
    dial: '+1649',
  },
  {
    alpha2: 'vg',
    continent: 'North America',
    nameFr: 'Îles Vierges britanniques',
    nameAr: 'جزر العذراء البريطانية',
    dial: '+1284',
  },
  {
    alpha2: 'vi',
    continent: 'North America',
    nameFr: 'Îles Vierges des États-Unis',
    nameAr: 'جزر العذراء الأمريكية',
    dial: '+1340',
  },
  {
    alpha2: 'um',
    continent: 'Oceania',
    nameFr: 'Îles mineures éloignées des États-Unis',
    nameAr: 'جزر الولايات المتحدة الصغيرة النائية',
    dial: null,
  },
  {
    alpha2: 'ax',
    continent: 'Europe',
    nameFr: 'Îles Åland',
    nameAr: 'جزر أولاند',
    dial: '+358',
  },
];
