import { ReactComponent as AbstractUser } from 'assets/abstract-user.svg';
import { ReactComponent as CloseSvg } from 'assets/close.svg';
import { ReactComponent as CupSvg } from 'assets/cup.svg';
import { ReactComponent as CurveDownSvg } from 'assets/curve-down.svg';
import { ReactComponent as CurveUpSvg } from 'assets/curve-up.svg';
import { ReactComponent as DemandsSvg } from 'assets/demands.svg';
import { ReactComponent as EventsSvg } from 'assets/events.svg';
import { ReactComponent as HomeSvg } from 'assets/home.svg';
import { ReactComponent as HumbergerSvg } from 'assets/humberger.svg';
import { ReactComponent as JournalSvg } from 'assets/journal.svg';
import { ReactComponent as JournalistSvg } from 'assets/journalist.svg';
import { ReactComponent as LocationsSvg } from 'assets/locations.svg';
import { ReactComponent as SettingsSvg } from 'assets/settings.svg';
import { ReactComponent as ProfileSvg } from 'assets/profile.svg';
import axios, { AxiosResponse } from 'axios';
import Button from 'components/button/Button';
import Picture from 'components/picture/Picture';
import { useAuth } from 'components/routes/AuthRouter';
import PrivateRoute from 'components/routes/PrivateRoute';
import { FormOm, ViewOm } from 'pages/om/Om';
import { FormPerson, PersonBadge, ViewPerson } from 'pages/person/Person';
import { HTMLProps, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PathLocationDto } from 'utils/utils';
import { OmDto } from '../../../../shared/dtos/om.dto';
import { GetPersonDto } from '../../../../shared/dtos/person.dto';
import './Sidebar.css';

export interface SidebarElementProps extends HTMLProps<HTMLElement> {
  icon: React.ReactNode;
  text: string;
  selected?: boolean;
}

export function SidebarElement({ icon, text, selected }: SidebarElementProps) {
  return (
    <div
      className={`sidebar-element d-flex align-items-center ${
        selected ? 'selected' : ''
      }`}
    >
      <div className={'sidebar-element-pad align-self-stretch'}></div>
      <div className="sidebar-element-icon">{icon}</div>
      <div className="sidebar-element-text flex-grow-1">{text}</div>
    </div>
  );
}

export interface SidebarProps extends HTMLProps<HTMLDivElement> {
  imageName?: string;
  onProfileClick?: MouseEventHandler<HTMLDivElement> | undefined;
}

export default function Sidebar({
  imageName,
  className = '',
  children,
  onProfileClick,
  ...rest
}: SidebarProps) {
  const [collapsed, setCollapsed] = useState<boolean>(
    !!localStorage.getItem('sidebar-collapsed'),
  );
  const toggleCollapsed = () => {
    if (collapsed) {
      localStorage.removeItem('sidebar-collapsed');
    } else {
      localStorage.setItem('sidebar-collapsed', 'not-collapsed');
    }
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`sidebar ${
        collapsed ? 'collapsed' : ''
      } d-flex justify-content-stretch ${className}`}
      {...rest}
    >
      <nav className="sidebar-content d-flex flex-column">
        <div className="sidebar-toggle-container d-flex justify-content-center pb-2">
          <Button id="sidebar-toggle" onClick={toggleCollapsed}>
            {collapsed ? <HumbergerSvg /> : <CloseSvg />}
          </Button>
        </div>
        <div className="profile d-flex justify-content-center pt-1 pb-5">
          <div
            className={`profile-toggle ${
              collapsed ? 'p-1' : 'p-2'
            } rounded-circle`}
            onClick={onProfileClick}
          >
            <Picture
              className="profile-pic"
              fallbackImage={<AbstractUser />}
              imageName={imageName}
              type="circle"
            />
          </div>
        </div>
        {children}
        <div className="mb-5"></div>
      </nav>
      <div className="sidebar-pad d-flex justify-content-between flex-column">
        <CurveUpSvg />
        <CurveDownSvg />
      </div>
    </div>
  );
}

type SidebarModalMode = 'closed' | 'view' | 'edit' | 'badge';

export function PrivateSidebar() {
  const auth = useAuth();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [profileData, setProfileData] = useState<GetPersonDto | OmDto>();
  const location = useLocation<PathLocationDto>();
  const [profileState, setProfileState] = useState<SidebarModalMode>('closed');
  const getProfileData = async () => {
    try {
      if (auth.user?.userType === 'faf') {
        const { data } = await axios.get<never, AxiosResponse<GetPersonDto>>(
          `/person/${auth.user?.ownerId}`,
        );
        setProfileData(data);
      } else {
        const { data } = await axios.get<never, AxiosResponse<OmDto>>(
          `/om/${auth.user?.ownerId}`,
        );
        setProfileData(data);
      }

      setProfileState('view');
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };

  if (auth.status !== 'authorized') {
    return null;
  }

  return (
    <Sidebar
      imageName={auth.user?.profilePhoto || ''}
      onProfileClick={getProfileData}
      className={isRtl ? 'rtl' : 'ltr'}
    >
      {profileState === 'view' ? (
        auth.user?.userType === 'faf' ? (
          <ViewPerson
            {...profileData}
            onClose={() => setProfileState('closed')}
            onEditClick={async () => {
              await getProfileData();
              setProfileState('edit');
            }}
            onGenerateBadgeClick={() => setProfileState('badge')}
          />
        ) : (
          <ViewOm
            {...profileData}
            onClose={() => setProfileState('closed')}
            onEditClick={async () => {
              await getProfileData();
              setProfileState('edit');
            }}
          />
        )
      ) : profileState === 'edit' ? (
        auth.user?.userType === 'faf' ? (
          <FormPerson
            {...profileData}
            formType="edit"
            closePath={location.pathname || '/home'}
            onClose={() => setProfileState('closed')}
          />
        ) : (
          <FormOm
            {...profileData}
            formType="edit"
            closePath={location.pathname || '/home'}
            onClose={() => setProfileState('closed')}
          />
        )
      ) : profileState === 'badge' ? (
        <PersonBadge
          {...profileData}
          onClose={() => setProfileState('closed')}
        />
      ) : undefined}
      <Link to="/home" title="Accueil">
        <SidebarElement
          icon={<HomeSvg />}
          text={t('sidebar.home')}
          selected={location.pathname === '/home'}
        />
      </Link>
      <Link to="/demand" title="Demandes">
        <SidebarElement
          icon={<DemandsSvg />}
          text={t('sidebar.demand')}
          selected={location.pathname.startsWith('/demand')}
        />
      </Link>
      <Link to="/journalist" title="Journalists">
        <SidebarElement
          icon={<JournalistSvg />}
          text={t('sidebar.journalist')}
          selected={location.pathname.startsWith('/journalist')}
        />
      </Link>
      <PrivateRoute userType="faf">
        <Link to="/person" title="Personnes">
          <SidebarElement
            icon={<ProfileSvg />}
            text={t('sidebar.person')}
            selected={location.pathname.startsWith('/person')}
          />
        </Link>
        <Link to="/om" title="Organes">
          <SidebarElement
            icon={<JournalSvg />}
            text={t('sidebar.om')}
            selected={location.pathname.startsWith('/om')}
          />
        </Link>
      </PrivateRoute>
      <Link to="/event" title="Evénements">
        <SidebarElement
          icon={<EventsSvg />}
          text={t('sidebar.event')}
          selected={location.pathname.startsWith('/event')}
        />
      </Link>
      <PrivateRoute userType="faf">
        <Link to="/competition" title="Compétitions">
          <SidebarElement
            icon={<CupSvg />}
            text={t('sidebar.competition')}
            selected={location.pathname.startsWith('/competition')}
          />
        </Link>
        <Link to="/location" title="Localisations">
          <SidebarElement
            icon={<LocationsSvg />}
            text={t('sidebar.location')}
            selected={location.pathname.startsWith('/location')}
          />
        </Link>
      </PrivateRoute>
      <div className="mt-5" />
      <Link to="/settings" title={t('sidebar.settings')}>
        <SidebarElement
          className="mb-3"
          icon={<SettingsSvg />}
          text={t('sidebar.settings')}
          selected={location.pathname === '/settings'}
        />
      </Link>
    </Sidebar>
  );
}
