import { countries } from 'utils/countries';
import { OptionType } from 'utils/utils';

export interface Icons {
  [key: string]: string;
}
export interface IconOptionType extends OptionType {
  icon?: string;
}

let countriesIcons: Icons;

export async function loadCountriesOptions(chooseAr?: boolean) {
  if (!countriesIcons) {
    countriesIcons = {};
    for (const { alpha2 } of countries) {
      countriesIcons[alpha2] = `/flags/32x32/${alpha2}.png`;
    }
  }

  const countriesOptions: IconOptionType[] = [];
  for (const { alpha2, nameFr, nameAr } of countries) {
    countriesOptions.push({
      icon: countriesIcons[alpha2],
      value: alpha2,
      label: chooseAr ? nameAr : nameFr,
    });
  }

  return countriesOptions;
}
