import { ReactComponent as ArrowRightSvg } from 'assets/arrow-right.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as SearchSvg } from 'assets/search.svg';
import axios, { AxiosResponse } from 'axios';
import Button, { ModalCloseButton } from 'components/button/Button';
import Card, { CardsContainer } from 'components/card/Card';
import EmptyPageHolder from 'components/empty-page-holder/EmptyPageHolder';
import IconInput from 'components/icon-input/IconInput';
import { PulseIndicator } from 'components/loading-indicator/LoadingIndicator';
import { Modal, ModalRole } from 'components/modal/Modal';
import Paginator from 'components/paginator/Paginator';
import Picture from 'components/picture/Picture';
import { useAuth } from 'components/routes/AuthRouter';
import { useEncryption } from 'lib/encryption';
import { ChangeEvent, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation, useParams } from 'react-router';
import { toast } from 'react-toastify';
import {
  PageModalUrlParams,
  StandAloneFormModalProps,
  StandAloneViewModalProps,
} from 'utils/utils';
import { CompetitionDto } from '../../../../shared/dtos/competition.dto';
import { SearchPaginate } from '../../../../shared/types';
import './Competition.css';

export default function Competition() {
  const [data, setData] = useState<CompetitionDto[]>();
  const [perPage, setPerPage] = useState<number>(24);
  const [total, setTotal] = useState<number>(0);
  const auth = useAuth();
  const history = useHistory();
  const { encrypt, decrypt } = useEncryption();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const updateCards = async (
    newPage: number,
    newPerPage: number,
    searchValue = '',
  ) => {
    try {
      const {
        data: { results, total },
      } = await axios.get<never, AxiosResponse<SearchPaginate<CompetitionDto>>>(
        `/event/competition/${newPage - 1}/${24}/${searchValue}`,
      );
      setData(results);
      setTotal(total);
      setPerPage(newPerPage);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updateCards(1, perPage);
  }, [perPage]);

  return (
    <div className={`page container-fluid ${isRtl ? 'rtl' : 'ltr'}`}>
      <Route
        path="/competition/view/:encryptedId"
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedCompetition = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedCompetition) {
            return null;
          }

          return <ViewCompetition {...selectedCompetition} />;
        }}
      />
      <Route path="/competition/add">
        <FormCompetition onAfterSubmission={() => updateCards(1, perPage)} />
      </Route>
      <Route
        path={'/competition/edit/:encryptedId'}
        render={({
          match: {
            params: { encryptedId },
          },
        }) => {
          const decryptedId = decrypt(`${encryptedId}`);
          const selectedCompetition = data?.find(
            ({ id }) => id === parseInt(decryptedId),
          );

          if (!selectedCompetition) {
            return null;
          }

          return (
            <FormCompetition
              onAfterSubmission={() => updateCards(1, perPage)}
              {...selectedCompetition}
            />
          );
        }}
      />
      <div className="navbar page-navbar mt-4 mb-4">
        <div className="flex-grow-1 d-flex justify-content-end">
          {/* <Button
            btnRole="secondary"
            className={isRtl ? 'mr-3' : 'ml-3'}
            onClick={async () => {
              let labels: CompetitionLabelDto = {
                sheet: t('sidebar.competition'),
                title: t('pages-titles.competition'),
                number: t('forms-labels.number'),
                nameFr: t('forms-labels.competition'),
                nameAr: t('forms-labels.competition'),
              };
              let { data } = await axios.post<never, AxiosResponse<string>>(
                'event/competition/xlsx',
                {
                  lang: language,
                  labels,
                  value: (document.querySelector('#search') as HTMLInputElement)
                    .value,
                  header: getSpreadsheetHeader(),
                } as SearchLabel,
              );
              openFile(join('xlsx', data));
            }}
          >
            {t('xlsx-button')}
          </Button> */}
          <IconInput
            id="search"
            className={isRtl ? 'mr-3' : 'ml-3'}
            placeholder={t('search-field-placeholder')}
            width="280px"
            icon={<SearchSvg />}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value.trim().toLowerCase();
              updateCards(1, perPage, value);
            }}
          />
          {auth.user?.userType === 'faf' ? (
            <Button
              btnRole="primary"
              className={isRtl ? 'mr-3' : 'ml-3'}
              onClick={() => history.push('/competition/add')}
            >
              {t('competition.add-new-button')}
            </Button>
          ) : undefined}
        </div>
      </div>
      {data && data.length > 0 ? (
        <>
          <CardsContainer
            direction={isRtl ? 'rtl' : 'ltr'}
            cardsDirection="horizontal"
          >
            {data.map(({ id, nameFr, nameAr, competitionLogo }, index) => {
              const viewDetailsBtn = (
                <Button
                  className={`slidable ${
                    isRtl ? 'slidable-left' : 'slidable-right'
                  } app-small-text d-flex`}
                  onClick={() => {
                    history.push(
                      `/competition/view/${encrypt(id?.toString() || '')}`,
                    );
                  }}
                >
                  <span className="view-details-button-text">
                    {t('details-button')}
                  </span>
                  <ArrowRightSvg
                    className={`card-link-icon svg-icon ${
                      isRtl ? 'rtl' : 'ltr'
                    }`}
                  />
                </Button>
              );
              const card = (
                <Card
                  key={index}
                  imageName={competitionLogo}
                  cardTitle={isRtl ? nameAr : nameFr}
                  detailsToggle={viewDetailsBtn}
                />
              );
              return card;
            })}
          </CardsContainer>
          <Paginator
            pageCount={-Math.floor(-total / perPage)}
            onPageChange={({ selected }) => updateCards(selected + 1, perPage)}
          />
        </>
      ) : data ? (
        <EmptyPageHolder emptyContent={<LogoSvg />} />
      ) : (
        <PulseIndicator className="page-loading" />
      )}
    </div>
  );
}

export function ViewCompetition({
  nameFr,
  nameAr,
  competitionLogo,
  unitedLogo,
  onClose,
  onEditClick,
}: CompetitionDto & StandAloneViewModalProps) {
  const history = useHistory();
  const auth = useAuth();
  const { encryptedId } = useParams<PageModalUrlParams>();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';

  const bottomBtns =
    auth.user?.userType === 'faf' ? (
      <Button
        btnRole="primary"
        className="ml-4"
        onClick={() => {
          if (onEditClick) {
            onEditClick();
          } else {
            history.push(`/competition/edit/${encryptedId}`);
          }
        }}
      >
        {t('modify-info-button')}
      </Button>
    ) : undefined;

  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/competition');
        }
      }}
    />
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      onRequestClose={() => {
        if (onClose) {
          onClose();
        } else {
          history.push('/competition');
        }
      }}
      bottomButtons={bottomBtns}
      bottomButtonsPosition="center"
      topRightButtons={modalCloseButton}
    >
      <div className="competition-info">
        <div className="row">
          <div className="col-2 app-emphasis">{t('forms-labels.name')}</div>
          <div className="col-10 app-text">{isRtl ? nameAr : nameFr}</div>
        </div>
        <div className="row">
          <div className="d-flex col-6 flex-column mt-3">
            <div className="app-emphasis d-flex align-items-center">
              {t('forms-labels.competition-logo')}
            </div>
            <div className="mt-3">
              <Picture imageFit="contain" defaultImageName={competitionLogo} />
            </div>
          </div>
          <div className="d-flex col-6 flex-column mt-3">
            <div className="app-emphasis d-flex align-items-center">
              {t('forms-labels.federation-logo')}
            </div>
            <div className="mt-3">
              <Picture imageFit="contain" defaultImageName={unitedLogo} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export function FormCompetition({
  id,
  nameFr,
  nameAr,
  competitionLogo,
  unitedLogo,
  onAfterSubmission: afterSubmission,
  formType: defaultFormType,
  closePath,
  onClose,
}: CompetitionDto & StandAloneFormModalProps) {
  const [modalRole, setModalRole] = useState<ModalRole>('content');
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      nameFr,
      nameAr,
      competitionLogo,
      unitedLogo,
    },
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const formType = pathname.startsWith('/competition/edit/') ? 'edit' : 'add';
  const formMethod = defaultFormType || formType === 'add' ? 'post' : 'put';
  const formAction =
    formType === 'add'
      ? '/event/competition/add'
      : `event/competition/edit/${id}`;
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isRtl = language === 'ar';
  const [successTimeout, setSuccessTimeout] = useState<NodeJS.Timeout>();
  const onAfterSubmission = () => {
    if (successTimeout) {
      clearTimeout(successTimeout);
      setSuccessTimeout(undefined);
    }
    afterSubmission?.();
    onClose?.();
    history.replace(closePath || '/competition');
  };
  const submit = async (data: CompetitionDto) => {
    setModalRole('processing');

    try {
      await axios[formMethod](formAction || pathname, data);

      setModalRole('success');
      setSuccessTimeout(setTimeout(onAfterSubmission, 1500));
    } catch (error: any) {
      setModalRole('content');
      if (axios.isAxiosError(error)) {
        const messages =
          error.response?.data?.message || error.response?.statusText;
        if (Array.isArray(messages)) {
          toast.error(
            messages.reduce(
              (prevMsg, msg) => (
                <div>
                  {prevMsg}- {msg}.
                </div>
              ),
              '',
            ),
          );
        } else {
          toast.error(messages?.toString());
        }
      } else {
        toast.error(error.message);
      }
    }
  };
  const modalCloseButton = (
    <ModalCloseButton
      onClick={() => {
        onClose?.();
        history.push(closePath || '/competition');
      }}
    />
  );

  const bottomBtns = (
    <Button
      className={isRtl ? 'mr-3' : 'ml-3'}
      btnRole="primary"
      type="submit"
      form="competition-form"
      style={{ width: '145px' }}
    >
      {t('save-button')}
    </Button>
  );

  const successButtons = (
    <Button
      btnRole="primary"
      btnSize="lg"
      className="mt-5"
      style={{ width: '160px' }}
      onClick={onAfterSubmission}
    >
      {t('close-button')}
    </Button>
  );

  return (
    <Modal
      isOpen
      direction={isRtl ? 'rtl' : 'ltr'}
      hideOverflow
      successMessage={
        formType === 'add'
          ? t('competition.add-success-message')
          : t('competition.modify-success-message')
      }
      successButtons={successButtons}
      modalRole={modalRole}
      bottomButtons={bottomBtns}
      onRequestClose={modalRole === 'success' ? onAfterSubmission : undefined}
      onAfterClose={reset}
      topRightButtons={modalCloseButton}
    >
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center" style={{ height: '320px' }}>
          <form id="competition-form" onSubmit={handleSubmit(submit)}>
            <div className="row justify-content-end mb-4">
              <div className="d-flex col-5 flex-column">
                <label className="app-emphasis required mb-3" htmlFor="name-fr">
                  {t('forms-labels.competition-logo')}
                </label>
                <Controller
                  control={control}
                  name="competitionLogo"
                  render={({ field: { value, onChange } }) => (
                    <Picture
                      required
                      enableUpload
                      imageFit="contain"
                      defaultImageName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="d-flex col-5 flex-column">
                <label className="app-emphasis required mb-3" htmlFor="name-fr">
                  {t('forms-labels.federation-logo')}
                </label>
                <Controller
                  control={control}
                  name="unitedLogo"
                  render={({ field: { value, onChange } }) => (
                    <Picture
                      required
                      enableUpload
                      imageFit="contain"
                      defaultImageName={value}
                      onNameChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-2 d-flex justify-content-end align-items-center">
                <label className="m-0 app-emphasis required" htmlFor="name-fr">
                  {t('forms-labels.name')}
                </label>
              </div>
              <div className="form-group ltr col-md-5">
                <Controller
                  control={control}
                  name="nameFr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="Nom en français"
                      required
                      id="name-fr"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className="form-group rtl col-md-5">
                <Controller
                  control={control}
                  name="nameAr"
                  render={({ field: { value, onChange } }) => (
                    <IconInput
                      value={value}
                      placeholder="الإسم بالعربية"
                      required
                      id="name-ar"
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
