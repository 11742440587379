import { ReactComponent as LockSvg } from 'assets/lock.svg';
import { ReactComponent as LogoSvg } from 'assets/logo.svg';
import { ReactComponent as ProfileSvg } from 'assets/profile.svg';
import Button from 'components/button/Button';
import IconInput from 'components/icon-input/IconInput';
import { useAuth } from 'components/routes/AuthRouter';
import { FloatingLanguageSelect } from 'components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { PathLocationDto } from 'utils/utils';
import './Login.css';

export default function Login() {
  const auth = useAuth();
  const { control, handleSubmit } = useForm();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation<PathLocationDto>();
  const history = useHistory<PathLocationDto>();
  const isRtl = language === 'ar';

  const checkSubmission = async (data: { [x: string]: any }) => {
    const status = await auth.login(data);

    if (status === 'unauthorized') {
      toast.error(t('errors.login-incorrect-creds'));
    } else if (status === 'authorized') {
      const { from } = location.state || { from: { pathname: '/home' } };
      history.replace(from);
    }
  };

  return (
    <div className="fluid-container login d-flex justify-content-center align-items-center">
      <FloatingLanguageSelect />
      <div className={`card d-block login-card ${isRtl ? 'rtl' : ''}`}>
        <form className="card-body" onSubmit={handleSubmit(checkSubmission)}>
          <div className="row justify-content-center mt-4 mb-4 ltr">
            <LogoSvg />
            {/* <h3 className="d-flex align-items-center pl-3 font-weight-bold primary-txt">
              ACCRED.FAF.DZ
            </h3> */}
          </div>
          <div className="mb-4">
            <h2 className="text-center font-weight-bold mb-3">
              {t('login.welcome-banner')[0]}
              <br />
              {t('login.welcome-banner')[1]}
            </h2>
          </div>
          <div className="form-group">
            <label htmlFor="username">{t('forms-labels.username')}</label>
            <Controller
              control={control}
              name="username"
              render={({ field: { value, onChange } }) => (
                <IconInput
                  id="username"
                  icon={<ProfileSvg />}
                  direction="ltr"
                  value={value}
                  iconPosition="start"
                  required
                  onChange={onChange}
                />
              )}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('forms-labels.password')}</label>
            <Controller
              control={control}
              name="password"
              render={({ field: { value, onChange } }) => (
                <IconInput
                  id="password"
                  icon={<LockSvg />}
                  direction="ltr"
                  iconPosition="start"
                  required
                  type="password"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </div>
          <Button
            btnSize="lg"
            btnRole="primary"
            className="btn-block mt-5 mb-4"
            type="submit"
          >
            {t('login.connect')}
          </Button>
        </form>
      </div>
    </div>
  );
}
